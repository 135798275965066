function setLocalStorageItem( key, value ) {
    localStorage.setItem( key, value );
}

function getLocalStorageItem( key ) {
    // .getItem will return null if the key doesn't exist
    return localStorage.getItem( key ) || '';
}

function removeLocalStorageItem( key ) {
    localStorage.removeItem( key );
}

const USERNAME_KEY = 'hickoryUsername';
const PASSWORD_KEY = 'hickoryPassword';

export default {
    username: {
        /** @returns { String } */
        get() {
            return getLocalStorageItem( USERNAME_KEY );
        },
        /** @param { String } value */
        set( value ) {
            setLocalStorageItem( USERNAME_KEY, value );
        },
        remove() {
            removeLocalStorageItem( USERNAME_KEY );
        },
        /** @returns { Boolean } */
        exists() {
            return this.get() !== '';
        },
    },
    password: {
        /** @returns { String } */
        get() {
            return getLocalStorageItem( PASSWORD_KEY );
        },
        /** @param { String } value */
        set( value ) {
            setLocalStorageItem( PASSWORD_KEY, value );
        },
        remove() {
            removeLocalStorageItem( PASSWORD_KEY );
        },
        /** @returns { Boolean } */
        exists() {
            return this.get() !== '';
        },
    },
    removeLocalCredentials() {
        this.username.remove();
        this.password.remove();
    },
    /** @returns { Boolean } */
    hasLocalLoginCredentials() {
        return this.username.exists()
            && this.password.exists();
    },
};
