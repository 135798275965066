// Api
import GroupApi from '@/api/GroupApi';

// Utils
import FormatUtil from '@/utils/FormatUtil';
import ObjectUtil from '@/utils/ObjectUtil';
import StoreUtil from '@/utils/StoreUtil';

const actionTypes = {
    resetResultsAndRelatedData: 'resetResultsAndRelatedData',
    getListByIsbn: 'getListByIsbn',
    dissolveGroupByGroupId: 'dissolveGroupByGroupId',
    addTitleToGroupById: 'addTitleToGroupById',
    removeTitleListFromGroupById: 'removeTitleListFromGroupById',
    reset: 'reset',
    createGroup: 'createGroup',
    getGroupStatusOptions: 'getGroupStatusOptions',
    updateGroupStatus: 'updateGroupStatus',
    refreshTitleGroupStatus: 'refreshTitleGroupStatus',
};

const mutationTypes = {
    SET_LIST: 'SET_LIST',
    SET_GROUP_ID: 'SET_GROUP_ID',
    SET_IN_FLIGHT: 'SET_IN_FLIGHT',
    SET_RESULTS_SEARCHED_STRING: 'SET_RESULTS_SEARCHED_STRING',
    SET_CHECKED_ITEMS: 'SET_CHECKED_ITEMS',
    SET_SEARCH_STRING: 'SET_SEARCH_STRING',
    SET_SHOW_CREATE_GROUP_MODAL: 'SET_SHOW_CREATE_GROUP_MODAL',
    SET_GROUP_STATUS_OPTIONS: 'SET_GROUP_STATUS_OPTIONS',
    SET_STATUS_MODIFIED: 'SET_STATUS_MODIFIED',
    SET_SELECTED_STATUS: 'SET_SELECTED_STATUS',
};

export default {
    namespaced: true,
    state: {
        /** @type { Object } */
        results: {
            /** @type { Number | Null } */
            // groupId: 123,
            groupId: null,
            /** @type { Object } */
            list: {},
            /** @type { Object } */
            metadata: {
                /** @type { Boolean } */
                inFlight: false,
                /** @type { String } */
                searchedString: '',
            },
        },
        /** @type { Array } */
        checkedItemList: [],
        /** @type { String } */
        searchString: '',
        /** @type { Boolean } */
        showCreateGroupModal: false,
        /** @type { Array } */
        statusOptions: [],
        /** @type { Boolean } */
        statusModified: false,
        /** @type { string } */
        selectedStatus: '',
    },
    getters: {
        /** @returns { Array } */
        resultList: ( state ) => Object.values( state.results.list ),
        /** @returns { Number } */
        resultCount: ( state, getters ) => getters.resultList.length,
        /** @returns { Boolean } */
        hasCheckedItems: ( state ) => state.checkedItemList && state.checkedItemList.length && state.checkedItemList.length > 0,
    },
    mutations: {
        [ mutationTypes.SET_LIST ]( state, { list } ) {
            state.results.list = list;
        },
        [ mutationTypes.SET_GROUP_ID ]( state, { groupId } ) {
            state.results.groupId = groupId;
        },
        [ mutationTypes.SET_IN_FLIGHT ]( state, { boolean } ) {
            state.results.metadata.inFlight = boolean;
        },
        [ mutationTypes.SET_RESULTS_SEARCHED_STRING ]( state, { string } ) {
            state.results.metadata.searchedString = string;
        },
        [ mutationTypes.SET_CHECKED_ITEMS ]( state, { list } ) {
            state.checkedItemList = list;
        },
        [ mutationTypes.SET_SEARCH_STRING ]( state, { string } ) {
            state.searchString = string;
        },
        [ mutationTypes.SET_SHOW_CREATE_GROUP_MODAL ]( state, { boolean } ) {
            state.showCreateGroupModal = boolean;
        },
        [ mutationTypes.SET_GROUP_STATUS_OPTIONS ]( state, { statusOptions } ) {
            state.statusOptions = statusOptions;
        },
        [ mutationTypes.SET_STATUS_MODIFIED ]( state, statusModified ) {
            state.statusModified = statusModified;
        },
        [ mutationTypes.SET_SELECTED_STATUS ]( state, selectedStatus ) {
            state.selectedStatus = selectedStatus;
        },
    },
    actions: {
        [ actionTypes.reset ]( { commit } ) {
            commit( mutationTypes.SET_LIST, { list: {} } );
            commit( mutationTypes.SET_GROUP_ID, { groupId: null } );
            commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            commit( mutationTypes.SET_RESULTS_SEARCHED_STRING, { string: '' } );
            commit( mutationTypes.SET_CHECKED_ITEMS, { list: [] } );
            commit( mutationTypes.SET_SEARCH_STRING, { string: '' } );
            commit( mutationTypes.SET_GROUP_STATUS_OPTIONS, { statusOptions: [] } );
            commit( mutationTypes.SET_STATUS_MODIFIED, false );
        },
        [ actionTypes.resetResultsAndRelatedData ]( context ) {
            context.commit( mutationTypes.SET_GROUP_ID, { groupId: null } );
            context.commit( mutationTypes.SET_LIST, { list: {} } );
            context.commit( mutationTypes.SET_RESULTS_SEARCHED_STRING, { string: '' } );
        },

        async [ actionTypes.getListByIsbn ]( context, { isbn } ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                const { data } = await GroupApi.getRelatedTitlesInGroup( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    isbn,
                } );

                // Update our results.metadata.searchedString
                // so we can later reference what was searched to 'refresh' our current result.list if needed
                context.commit( mutationTypes.SET_RESULTS_SEARCHED_STRING, { string: isbn } );

                // Add a few attributes for display purposes
                const mutatedTitleList = ( data.titleDTOList && data.titleDTOList.length && data.titleDTOList.length > 0 )
                    ? data.titleDTOList.map( ( item ) => ( {
                        ...item,
                        descriptionFriendly: FormatUtil.truncateText( item.description, 40 ),
                        titleType: FormatUtil.capitalizeString( item.titleType ),
                    } ) )
                    : [];

                if ( data.titleGroupId && mutatedTitleList.length > 0 ) {
                    context.commit( mutationTypes.SET_GROUP_ID, { groupId: data.titleGroupId } );
                    context.commit( mutationTypes.SET_LIST, {
                        list: ( mutatedTitleList && mutatedTitleList.length && mutatedTitleList.length > 0 )
                            ? ObjectUtil.normalize( mutatedTitleList, 'titleId' )
                            : {},
                    } );
                } else {
                    context.dispatch( 'setErrorNotification', 'Failed to find any Title Groups that contain that EAN', { root: true } );
                }
            } catch ( error ) {
                context.dispatch( actionTypes.resetResultsAndRelatedData );
                if ( StoreUtil.error.entityNotFound( error ) ) {
                    context.commit( mutationTypes.SET_SHOW_CREATE_GROUP_MODAL, { boolean: true } );
                }
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },

        async [ actionTypes.addTitleToGroupById ]( context, { isbn, titleGroupId } ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                await GroupApi.addTitleToGroup( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    isbn,
                    titleGroupId,
                } );

                context.dispatch( 'setSuccessNotification', 'EAN added to Group!', { root: true } );

                // Go fetch the newly updated group
                context.dispatch( actionTypes.getListByIsbn, { isbn } );
            } catch ( error ) {
                console.error( 'ERROR in actionTypes.addTitleToGroupById: ', error );

                const message = `Failed to add EAN to group - ${ error.response.data.message }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },

        async [ actionTypes.removeTitleListFromGroupById ]( context, { titleIdList } ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                await GroupApi.removeFromTitleGroup( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    titleIdList,
                } );

                const titleCount = titleIdList.length;
                // Message is: (x) Title(s) successfully removed from this group. Fetching updated group...
                // eslint-disable-next-line max-len
                const message = `${ titleCount && titleCount > 1 ? `${ titleCount } Titles` : 'Title' } successfully removed from this group. Fetching updated group...`;
                context.dispatch( 'setSuccessNotification', message, { root: true } );

                // Either grab the FIRST item the user is removing,
                // or fallback to what was searched to get the current result set
                const isbnToSearch = context.state.checkedItemList[ 0 ] && context.state.checkedItemList[ 0 ].productCode
                    ? context.state.checkedItemList[ 0 ].productCode
                    : context.state.results.metadata.searchedString;

                context.commit( mutationTypes.SET_CHECKED_ITEMS, { list: [] } );

                // Go update our search results with the updated group
                context.dispatch( actionTypes.getListByIsbn, {
                    isbn: isbnToSearch,
                } );
            } catch ( error ) {
                console.error( 'ERROR in actionTypes.removeTitleListFromGroupById: ', error );

                const message = `Failed to remove titles from group - ${ error.response.data.message }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },

        async [ actionTypes.dissolveGroupByGroupId ]( context ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                await GroupApi.dissolveEntireTitleGroup( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    titleGroupId: context.state.results.groupId,
                } );

                context.dispatch( 'setSuccessNotification', 'Group dissolved!', { root: true } );

                context.dispatch( actionTypes.resetResultsAndRelatedData );
            } catch ( error ) {
                console.error( 'ERROR in actionTypes.dissolveGroupByGroupId: ', error );

                const message = `Failed to dissolve group by id - ${ error.response.data.message }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },
        async [ actionTypes.createGroup ]( context, { titleId } ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );

            try {
                const { data } = await GroupApi.createTitleGroup( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    titleId,
                } );
                context.dispatch( 'setSuccessNotification', 'Title Successfully grouped!', { root: true } );
                return data;
            } catch ( error ) {
                console.error( 'ERROR in actionTypes.createGroup: ', error );
                return false;
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },
        async [ actionTypes.getGroupStatusOptions ]( context ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                const { data } = await GroupApi.getGroupStatusOptions( {
                    authToken: context.rootGetters[ 'User/authString' ],
                } );

                context.commit( mutationTypes.SET_GROUP_STATUS_OPTIONS, { statusOptions: data?.groupingStatusList ?? [] } );
            } catch ( error ) {
                console.error( 'ERROR in actionTypes.getGroupStatusOptions: ', error );

                const message = `Failed to get group status options - ${ error.response.data.message }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },

        async updateGroupStatus( context ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                const { data } = await GroupApi.updateGroupStatus( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    titleId: context.rootState.currentTitle.id,
                    statusName: context.state.selectedStatus,
                } );

                await context.dispatch( 'setSuccessNotification', 'Group status updated!', { root: true } );

                context.dispatch( 'Title/Queue/getList', {}, { root: true } );

                context.commit( mutationTypes.SET_STATUS_MODIFIED, false );
                context.commit( 'UPDATE_CURRENT_TITLE', { key: 'titleGroupingDTO', value: data }, { root: true } );
            } catch ( error ) {
                console.error( 'ERROR in actionTypes.updateGroupStatus: ', error );

                await context.dispatch( 'setErrorNotification', `Failed to update group status - ${ error.response.data.message }`, { root: true } );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },
        async refreshTitleGroupStatus( context ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                const { data } = await GroupApi.getTitleGroupStatusByTitleId( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    titleId: context.rootState.currentTitle.id,
                } );

                context.commit( 'UPDATE_CURRENT_TITLE', { key: 'titleGroupingDTO', value: data }, { root: true } );
            } catch ( error ) {
                console.error( 'ERROR in actionTypes.refreshTitleGroupStatus: ', error );

                await context.dispatch( 'setErrorNotification', `Failed to get title group status - ${ error.response.data.message }`, { root: true } );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },
    },
};
