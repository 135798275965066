// The order of these attributes matter, it determines the order
// in which they are displayed on the Purchase Order Search Page
export default {
    Draft: 'DRAFT',
    Printed: 'PRINTED',
    Submitted: 'SUBMITTED',
    Canceled: 'CANCELED',
    Received: 'RECEIVED',
    Reconcile: 'RECONCILE',
    Complete: 'COMPLETE',
    Open: 'OPEN',
    Closed: 'CLOSED',
};

export const STATUS_LIST_COLUMNS = {
    Draft: 0,
    Printed: 0,
    Submitted: 0,
    Canceled: 0,

    Received: 1,
    Reconcile: 1,
    Complete: 1,
    Open: 1,
    Closed: 1,
};
