// Api
import InvoiceApi from '@/api/InvoiceApi';
import PurchaseOrderApi from '@/api/PurchaseOrderApi';

// Utils
import ObjectUtil from '@/utils/ObjectUtil';
import StoreUtil from '@/utils/StoreUtil';

export const fullNameSpace = 'Invoice/Detail/';

export const actionTypes = {
    reset: 'reset',
    get: 'get',
    setInitialDetailInfo: 'setInitialDetailInfo',
    update: 'update',
    delete: 'delete',
    searchForPurchaseOrders: 'searchForPurchaseOrders',
    searchForSinglePurchaseOrder: 'searchForSinglePurchaseOrder',
    getAssociatedPurchaseOrdersByInvoiceId: 'getAssociatedPurchaseOrdersByInvoiceId',
    getPurchaseOrdersByInvoiceId: 'getPurchaseOrdersByInvoiceId',
    getInvoicesBySupplierAndDocumentNumber: 'getInvoicesBySupplierAndDocumentNumber',
    completeSelectedAssociatedPurchaseOrders: 'completeSelectedAssociatedPurchaseOrders',
};

export const mutationTypes = {
    SET_IN_FLIGHT: 'SET_IN_FLIGHT',
    SET_ASSOCIATED_PURCHASE_ORDERS_IN_FLIGHT: 'SET_ASSOCIATED_PURCHASE_ORDERS_IN_FLIGHT',
    SET_INITIAL_DETAIL_INFO: 'SET_INITIAL_DETAIL_INFO',
    SET_SUPPLIER_DOCUMENT_NUMBER: 'SET_SUPPLIER_DOCUMENT_NUMBER',
    SET_DATE: 'SET_DATE',
    SET_TOTAL: 'SET_TOTAL',
    SET_TERMS_DISCOUNT: 'SET_TERMS_DISCOUNT',
    SET_TERMS_DAYS: 'SET_TERMS_DAYS',
    SET_NOTES: 'SET_NOTES',
    SET_RECONCILE_STATUS: 'SET_RECONCILE_STATUS',
    SET_ADJUSTMENT_FEES: 'SET_ADJUSTMENT_FEES',
    SET_ADJUSTMENT_FREIGHT: 'SET_ADJUSTMENT_FREIGHT',
    SET_ADJUSTMENT_TAX: 'SET_ADJUSTMENT_TAX',
    SET_ADJUSTMENT_CREDIT: 'SET_ADJUSTMENT_CREDIT',
    SET_PO_ITEMS: 'SET_PO_ITEMS',
    SET_PO_ITEMS_INVOICE_ITEM_QTY: 'SET_PO_ITEMS_INVOICE_ITEM_QTY',
    SET_PO_ITEMS_INVOICE_ITEM_PRICE: 'SET_PO_ITEMS_INVOICE_ITEM_PRICE',
    REMOVE_PO_ITEM_FROM_PO_ITEMS: 'REMOVE_PO_ITEM_FROM_PO_ITEMS',
};

/** @returns { Object } - { import('@/typedefs/invoiceDetailAdjustment').invoiceDetailAdjustment[] } normalized by 'type' */
export const getDefaultAdjustments = () => ( {
    FEES: {
        amount: 0,
        type: 'FEES',
    },
    FREIGHT: {
        amount: 0,
        type: 'FREIGHT',
    },
    TAX: {
        amount: 0,
        type: 'TAX',
    },
    CREDIT: {
        amount: 0,
        type: 'CREDIT',
    },
} );

export const acceptablePurchaseOrderStatuses = [
    'SUBMITTED',
    'RECEIVED',
    'RECONCILE',
];

export default {
    namespaced: true,
    state: {
        /** @type { Boolean } */
        inFlight: false,
        /** @type { Boolean } */
        associatedPurchaseOrdersInFlight: false,
        /** @type { import('@/typedefs/invoiceDetail').invoiceDetail } */
        info: {
            /** @type { Number } */
            id: 0,
            /** @type { String } */
            createdAt: '',
            /** @type { Number } */
            dueDate: 0,
            /** @type { Number } */
            returnExpirationDate: 0,
            /** @type { String } */
            updatedAt: '',
            /** @type { String } */
            internalDocumentNumber: '',
            /** @type { String } */
            date: '',
            /** @type { String } */
            notes: '',
            /** @type { String } */
            location: '',
            /** @type { import('@/typedefs/invoiceSupplier').invoiceSupplier } */
            supplier: {},
            /** @type { import('@/typedefs/invoicePurchaseOrderItem').invoicePurchaseOrderItem[] } */
            poItems: [],
            /** @type { String } */
            supplierDocumentNumber: '',
            /** @type { Number } - not actually used ATM */
            lineDiscount: 0,
            /** @type { Object } - { import('@/typedefs/invoiceDetailAdjustment').invoiceDetailAdjustment[] } normalized by 'type' */
            adjustments: getDefaultAdjustments(),
            /** @type { String } */
            reconcileStatus: '',
            /** @type { Number } */
            total: 0,
            /** @type { String | Null } */
            termsDiscount: '',
            /** @type { Number } */
            termsDays: 0,
            /** @type { Array } */
            associatedPurchaseOrders: [],
        },
    },
    getters: {
        /**
         * Sum of invoiced lines
         * @returns { Number }
         */
        totalsCalculatedLineTotal: ( state ) => (
            state.info.poItems
                .flatMap( ( poItem ) => poItem.invoiceItems )
                .map( ( invoiceItem ) => invoiceItem.price * invoiceItem.qty )
                .reduce( ( acc, curr ) => Number( acc ) + Number( curr ), 0 ) // initial value of 0 so we're not reducing on an empty array before we have data
        ),
        /**
         * ( Sum of total adjusted debt ) - ( adusted credit )
         * @returns { Number }
         */
        totalsTotalAdjustments: ( state ) => (
            Object.values( state.info.adjustments )
                .filter( ( adjustment ) => adjustment.type !== 'CREDIT' )
                .map( ( adjustment ) => adjustment.amount || 0 ) // coerce amount to 0 if it is null
                .reduce( ( acc, curr ) => Number( acc ) + Number( curr ), 0 ) // initial value of 0 so we're not reducing on an empty array before we have data
            - ( state.info.adjustments.CREDIT ? Number( state.info.adjustments.CREDIT.amount ) : 0 )
        ),
        /**
         * ( Calculated line total ) + ( total adjustments )
         * @returns { Number }
         */
        totalsCalculatedTotal: ( state, getters ) => (
            Number( getters.totalsCalculatedLineTotal ) + Number( getters.totalsTotalAdjustments )
        ),
        /**
         * ( Calculated total ) - ( invoice total )
         * @returns { Number }
         */
        totalsDifference: ( state, getters ) => (
            getters.totalsCalculatedTotal - state.info.total
        ),
    },
    actions: {
        async [ actionTypes.reset ]( { commit } ) {
            commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            commit( mutationTypes.SET_INITIAL_DETAIL_INFO, {
                id: 0,
                createdAt: '',
                updatedAt: '',
                internalDocumentNumber: '',
                date: '',
                notes: '',
                location: '',
                supplier: {},
                poItems: [],
                associatedPurchaseOrders: [],
                supplierDocumentNumber: '',
                lineDiscount: 0,
                adjustments: getDefaultAdjustments(),
                reconcileStatus: '',
                total: 0,
                termsDiscount: '',
                termsDays: 0,
                dueDate: '',
                returnExpirationDate: '',
            } );
        },
        [ actionTypes.setInitialDetailInfo ]( context, { info } ) {
            context.commit( mutationTypes.SET_INITIAL_DETAIL_INFO, {
                ...info,
                adjustments: ( info.adjustments && info.adjustments.length && info.adjustments.length > 0 )
                    ? ObjectUtil.normalize(
                        /** @type { import('@/typedefs/invoiceDetailAdjustment').invoiceDetailAdjustment[] } */
                        info.adjustments.map( ( adjustment ) => ( {
                            ...adjustment,
                            amount: adjustment.amount || 0, // use the amount if defined, set to 0 if falsy (null)
                        } ) ),
                        'type',
                    )
                    : getDefaultAdjustments(),
                // reconcileStatus is originally ALL_CAPS, so we Title Case it here. 'COMPLETE' => 'Complete'
                reconcileStatus: `${ info.reconcileStatus.charAt( 0 ).toUpperCase() }${ info.reconcileStatus.slice( 1 ).toLowerCase() }`,
                termsDays: info.termsDays || 0, // use the value if defined, set to 0 if falsy (null)
                termsDiscount: info.termsDiscount || 0, // use the value if defined, set to 0 if falsy (null)
                lineDiscount: info.lineDiscount || 0, // use the value if defined, set to 0 if falsy (null)
            } );
        },
        async [ actionTypes.get ]( context, { invoiceId } ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                const { data } = await InvoiceApi.getInvoiceDetails( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    invoiceId,
                } );
                await context.dispatch( actionTypes.setInitialDetailInfo, { info: data } );
            } catch ( error ) {
                throw new Error( error.message );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },
        async [ actionTypes.update ]( context ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                const { data } = await InvoiceApi.updateInvoiceDetail( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    invoice: {
                        id: context.state.info.id,
                        status: context.state.info.reconcileStatus,
                        supplierDocumentNumber: context.state.info.supplierDocumentNumber,
                        date: context.state.info.date,
                        total: context.state.info.total,
                        notes: context.state.info.notes,
                        fees: context.state.info.adjustments.FEES.amount,
                        freight: context.state.info.adjustments.FREIGHT.amount,
                        tax: context.state.info.adjustments.TAX.amount,
                        credit: context.state.info.adjustments.CREDIT.amount,
                        items: context.state.info.poItems
                            .flatMap( ( poItem ) => (
                                poItem.invoiceItems.map( ( invoiceItem ) => ( {
                                    id: invoiceItem.invoiceItemId,
                                    poItemId: poItem.poItemId,
                                    price: invoiceItem.price,
                                    quantity: invoiceItem.qty,
                                } ) )
                            ) ),
                    },
                } );
                return data;
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },
        async [ actionTypes.delete ]( context ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                await InvoiceApi.deleteInvoiceDetail( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    invoiceId: context.state.info.id,
                } );
            } catch ( error ) {
                await context.dispatch( 'setErrorNotification', `Error deleting invoice - ${ error.message }`, { root: true } );
                throw new Error( error );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },
        async [ actionTypes.searchForPurchaseOrders ]( context, { purchaseOrderNumber } ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                const { data } = await PurchaseOrderApi.postFiltersToGetPurchaseOrderList( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    purchaseOrderNumber,
                    // Invoices are only created from these three status
                    sourcingDocStatusList: acceptablePurchaseOrderStatuses,
                } );
                /** @type { import('@/typedefs/purchaseOrder').purchaseOrder[] } */
                return data.purchaseOrderDetailDTOList;
            } catch ( error ) {
                throw new Error( error.message );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },
        async [ actionTypes.searchForSinglePurchaseOrder ]( context, { purchaseOrderNumber } ) {
            try {
                const purchaseOrderList = await context.dispatch( actionTypes.searchForPurchaseOrders, { purchaseOrderNumber } );
                /** @type { import('@/typedefs/purchaseOrder').purchaseOrder } */
                return purchaseOrderList[ 0 ];
            } catch ( error ) {
                await context.dispatch( 'setErrorNotification', `Failed to find a Purchase Order - ${ error.response.data.message }`, { root: true } );
                throw error;
            }
        },
        async [ actionTypes.getPurchaseOrdersByInvoiceId ]( context ) {
            try {
                const { data } = await InvoiceApi.getAssociatedPurchaseOrdersByInvoiceId( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    invoiceId: context.state.info.id,
                } );
                /** @type { import('@/typedefs/purchaseOrder').purchaseOrder[] } */
                return data.purchaseOrderDetailDTOList;
            } catch ( error ) {
                throw new Error( error.message );
            }
        },
        async [ actionTypes.getInvoicesBySupplierAndDocumentNumber ]( context, payload ) {
            try {
                const { data } = await InvoiceApi.getInvoicesBySupplierAndDocumentNumber( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    locationId: payload.locationId,
                    supplierId: payload.supplierId,
                    supplierDocumentNumber: payload.supplierDocumentNumber,
                } );
                return data.invoiceSearchDTOs;
            } catch ( error ) {
                const message = `Error occurred when searching for Invoices - ${ StoreUtil.error.getMessage( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                throw new Error( error.message );
            }
        },
        async [ actionTypes.getAssociatedPurchaseOrdersByInvoiceId ]( context ) {
            context.commit( mutationTypes.SET_ASSOCIATED_PURCHASE_ORDERS_IN_FLIGHT, { boolean: true } );
            try {
                // API Call
                const { data } = await InvoiceApi.getAssociatedPurchaseOrdersByInvoiceId( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    invoiceId: context.state.info.id,
                } );

                // We only care about Purchase Orders that are not completed so we can complete them
                const completablePurchaseOrders = data.purchaseOrderDetailDTOList
                    .filter( ( purchaseOrder ) => purchaseOrder.status.toLowerCase() !== 'complete' );

                // Add the Number of ISBNs and Number of Units to the payload for display purposes and return
                return completablePurchaseOrders.map( ( purchaseOrder ) => {
                    // These two calculations are borrowed from the summary view of Purchase Orders
                    const numberOfISBNs = purchaseOrder.purchaseOrderItemDTOList.length > 0
                        ? new Set( purchaseOrder.purchaseOrderItemDTOList
                            .map( ( item ) => item.productCode ) ).size
                        : 0;
                    const numberOfUnits = purchaseOrder.purchaseOrderItemDTOList.length > 0
                        ? purchaseOrder.purchaseOrderItemDTOList
                            .map( ( item ) => item.requestedQuantity )
                            .reduce( ( accumulator, currentValue ) => accumulator + currentValue )
                        : 0;
                    return { ...purchaseOrder, numberOfISBNs, numberOfUnits };
                } );
            } catch ( error ) {
                throw new Error( error.message );
            } finally {
                context.commit( mutationTypes.SET_ASSOCIATED_PURCHASE_ORDERS_IN_FLIGHT, { boolean: false } );
            }
        },
        async [ actionTypes.completeSelectedAssociatedPurchaseOrders ]( context, { selectedAssociatedPurchaseOrders } ) {
            context.commit( mutationTypes.SET_ASSOCIATED_PURCHASE_ORDERS_IN_FLIGHT, { boolean: true } );

            // We only need the numbers to mark them complete.
            const poNumbers = selectedAssociatedPurchaseOrders.map( ( purchaseOrder ) => purchaseOrder.number );
            try {
                await InvoiceApi.completeAssociatedPurchaseOrders( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    invoiceId: context.state.info.id,
                    poNumbers,
                } );
            } catch ( error ) {
                const message = `Failed to get Invoice Status list - ${ StoreUtil.error.getMessage( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                throw new Error( error.message );
            } finally {
                context.commit( mutationTypes.SET_ASSOCIATED_PURCHASE_ORDERS_IN_FLIGHT, { boolean: false } );
            }
        },
    },
    mutations: {
        [ mutationTypes.SET_IN_FLIGHT ]( state, { boolean } ) {
            state.inFlight = boolean;
        },
        [ mutationTypes.SET_ASSOCIATED_PURCHASE_ORDERS_IN_FLIGHT ]( state, { boolean } ) {
            state.associatedPurchaseOrdersInFlight = boolean;
        },
        [ mutationTypes.SET_INITIAL_DETAIL_INFO ]( state, {
            id,
            createdAt,
            updatedAt,
            internalDocumentNumber,
            date,
            dueDate,
            returnExpirationDate,
            notes,
            location,
            supplier,
            poItems,
            associatedPurchaseOrders,
            supplierDocumentNumber,
            lineDiscount,
            adjustments,
            reconcileStatus,
            total,
            termsDiscount,
            termsDays,
        } ) {
            state.info.id = id;
            state.info.createdAt = createdAt;
            state.info.updatedAt = updatedAt;
            state.info.internalDocumentNumber = internalDocumentNumber;
            state.info.date = date;
            state.info.notes = notes;
            state.info.location = location;
            state.info.supplier = supplier;
            state.info.poItems = poItems;
            state.info.associatedPurchaseOrders = associatedPurchaseOrders;
            state.info.supplierDocumentNumber = supplierDocumentNumber;
            state.info.lineDiscount = lineDiscount;
            state.info.adjustments = adjustments;
            state.info.reconcileStatus = reconcileStatus;
            state.info.total = total;
            state.info.termsDiscount = termsDiscount;
            state.info.termsDays = termsDays;
            state.info.dueDate = dueDate;
            state.info.returnExpirationDate = returnExpirationDate;
        },
        [ mutationTypes.SET_ADJUSTMENT_FEES ]( state, { value } ) {
            state.info.adjustments.FEES.amount = Number( value );
        },
        [ mutationTypes.SET_ADJUSTMENT_FREIGHT ]( state, { value } ) {
            state.info.adjustments.FREIGHT.amount = Number( value );
        },
        [ mutationTypes.SET_ADJUSTMENT_TAX ]( state, { value } ) {
            state.info.adjustments.TAX.amount = Number( value );
        },
        [ mutationTypes.SET_ADJUSTMENT_CREDIT ]( state, { value } ) {
            state.info.adjustments.CREDIT.amount = Number( value );
        },
        [ mutationTypes.SET_SUPPLIER_DOCUMENT_NUMBER ]( state, { value } ) {
            state.info.supplierDocumentNumber = value;
        },
        [ mutationTypes.SET_DATE ]( state, { value } ) {
            state.info.date = value;
        },
        [ mutationTypes.SET_TOTAL ]( state, { value } ) {
            state.info.total = Number( value );
        },
        [ mutationTypes.SET_TERMS_DISCOUNT ]( state, { value } ) {
            state.info.termsDiscount = Number( value );
        },
        [ mutationTypes.SET_TERMS_DAYS ]( state, { value } ) {
            state.info.termsDays = Number( value );
        },
        [ mutationTypes.SET_NOTES ]( state, { value } ) {
            state.info.notes = value;
        },
        [ mutationTypes.SET_RECONCILE_STATUS ]( state, { value } ) {
            state.info.reconcileStatus = value;
        },
        [ mutationTypes.SET_PO_ITEMS ]( state, { value } ) {
            state.info.poItems = value;
        },
        [ mutationTypes.SET_PO_ITEMS_INVOICE_ITEM_QTY ]( state, { itemId, invoiceIndex, qty } ) {
            const item = state.info.poItems.find( ( poItem ) => poItem.poItemId === itemId );
            item.invoiceItems[ invoiceIndex ].qty = qty;
        },
        [ mutationTypes.SET_PO_ITEMS_INVOICE_ITEM_PRICE ]( state, { itemId, invoiceIndex, price } ) {
            const item = state.info.poItems.find( ( poItem ) => poItem.poItemId === itemId );
            item.invoiceItems[ invoiceIndex ].price = price;
        },
        [ mutationTypes.REMOVE_PO_ITEM_FROM_PO_ITEMS ]( state, { itemId } ) {
            state.info.poItems = state.info.poItems.filter( ( poItem ) => poItem.poItemId !== itemId );
        },
    },
};
