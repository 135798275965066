import axios from './config/axios';
import api from './config/api';

export default {
    /**
     * Add an ISBN to a subscription library
     * Docs: https://apps04.dev.slingshotedu.tech/swagger/#!/Title_Research_RWS_V1/addIsbnToSubscriptionLibrary
     * @POST
     */
    addTitle(authToken, libraryName, isbn) {
        return axios({
            url: api.cmaUrl('titleResearch/subscription/addIsbnToSubscriptionLibrary'),
            method: 'POST',
            headers: api.authHeaders(authToken),
            params: {
                subscriptionLibraryName: libraryName,
                isbn,
            },
        });
    },

    /**
     * Remove an ISBN from a subscription library
     * Docs: https://apps04.dev.slingshotedu.tech/swagger/#!/Title_Research_RWS_V1/deactivateSubscriptionTitle
     * @POST
     */
    removeTitle(authToken, libraryName, isbn) {
        return axios({
            url: api.cmaUrl('titleResearch/subscription/deactivateSubscriptionTitle'),
            method: 'POST',
            headers: api.authHeaders(authToken),
            params: {
                subscriptionLibraryName: libraryName,
                isbn,
            },
        });
    },
};
