export default {
    path: '/subscriptions',
    name: 'SubscriptionsManagement',
    meta: {
        title: 'Subscriptions Management',
        requiresAuth: true,
    },
    props: ( route ) => ( { query: route.query.q } ),
    component: () => import( /* webpackChunkName: "Subscriptions" */ '@/views/Subscriptions/Subscriptions' ),
};
