import Vue from 'vue';
import Router from 'vue-router';

// Store
import store from '@/store';

// Api
import LocalAuthApi from '@/api/LocalAuthApi';

// Feature Flags
// import featureFlags from '../../featureFlags';

// Routes
import LoginRoute from './routes/Login';
import LogoutRoute from './routes/Logout';
// Title Research
import TitleAssignmentRoute from './routes/TitleAssignment';
import TitleResearchQueueRoute from './routes/TitleResearchQueue';
import TitleResearchCatalogListingsRoute from './routes/TitleResearchCatalogListings';
// Purchase Order Management
import PurchaseOrders from './routes/PurchaseOrders';
// Adoptions
import AdoptionRoute from './routes/Adoption';
// Title Grouping
import TitleGroupRoute from './routes/TitleGroup';
// Invoice Reconciliation
import InvoiceReconciliationRoute from './routes/InvoiceReconciliation';
// Credit Memo Reconciliation
import CreditMemoReconciliationRoute from './routes/CreditMemoReconciliation';
// RRA
import RRARoute from './routes/RRA';
// Lookup
import LookupRoute from './routes/Lookup';
import Subscriptions from './routes/Subscription';

Vue.use( Router );

// Routes
const routes = [
    {
        path: '/',
        redirect: { name: TitleResearchQueueRoute.name }, // https://router.vuejs.org/en/essentials/redirect-and-alias.html
    },
    LoginRoute,
    LogoutRoute,
    TitleAssignmentRoute,
    TitleResearchQueueRoute,
    TitleResearchCatalogListingsRoute,
    // Legacy Purchase Order route redirects
    {
        path: '/search/:id',
        redirect: ( to ) => ( {
            path: '/purchase-orders',
            query: { docNum: to.params.id },
        } ),
    },
    {
        path: '/search',
        redirect: '/purchase-orders',
    },
    {
        path: '/create',
        redirect: '/purchase-orders/create',
    },
    {
        path: '/auto-gen',
        redirect: '/purchase-orders/auto-gen',
    },
    PurchaseOrders,
    AdoptionRoute,
    TitleGroupRoute,
    InvoiceReconciliationRoute,
    CreditMemoReconciliationRoute,
    RRARoute,
    LookupRoute,
    Subscriptions,
];

// if ( featureFlags.rra ) {
//     [
//         RRARoute,
//     ].forEach( ( route ) => {
//         routes.push( route );
//     } );
// }

// Router
const router = new Router( {
    // https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
} );

async function loginUser( next ) {
    try {
        await store.dispatch( 'User/login', {
            username: LocalAuthApi.username.get(),
            password: LocalAuthApi.password.get(),
        } );
    } catch ( error ) {
        console.error( 'Router: Failed to login user. Redirecting to Login. Error -', error );
        LocalAuthApi.removeLocalCredentials();
        next( { path: LoginRoute.path } );
        throw error;
    }
}

/**
 * Check to see if 'User/actionsForCurrentLocation' includes an action that grants permission to a given route, specified by that route's meta.allowedActions
 * @returns { Boolean }
 */
// eslint-disable-next-line no-unused-vars
function isAuthorizedToGoToRoute( targetRoute ) {
    return true;
    // if ( !targetRoute.meta.allowedActions ) return true;
    // return store.getters[ 'User/actionsForCurrentLocation' ]
    //     .filter( ( action ) => new Set( targetRoute.meta.allowedActions ).has( action ) )
    //     .length > 0;
}
function goToNextIfAuthorizedAndExists( to, from, next ) {
    if ( ( to.matched.length > 0 ) && isAuthorizedToGoToRoute( to ) ) {
        next();
    } else {
        // Go 'home' if the last page was login and an unauthorized user is attempting to go to a guarded route
        next( {
            path: ( from.name === 'Login' )
                ? '/'
                : from.path,
        } );
    }
}

// Route Auth guard
// Inspired by: https://router.vuejs.org/en/advanced/meta.html
router.beforeEach( ( to, from, next ) => {
    // Clear error and success messages upon route change
    store.dispatch( 'clearNotifications' );

    // Variable to make it easier to toggle console logs when debugging
    const showRouteGuardWarnings = false;
    async function doRouting() {
        if ( showRouteGuardWarnings ) console.table( { to, from, next } );
        if ( to.matched.some( ( route ) => route.meta && route.meta.requiresAuth ) ) {
            if ( store.getters[ 'User/isLoggedIn' ] ) {
                if ( showRouteGuardWarnings ) console.warn( 'Route Guard ( Requires Auth ) - Already Logged In' );
                goToNextIfAuthorizedAndExists( to, from, next );
            } else if ( LocalAuthApi.hasLocalLoginCredentials() ) {
                if ( showRouteGuardWarnings ) console.warn( 'Route Guard ( Requires Auth ) - Attempting Auto Login' );
                try {
                    await ( async () => {
                        if ( showRouteGuardWarnings ) console.warn( 'Route Guard ( Requires Auth ) - Auto Login' );
                        await loginUser( next );
                        goToNextIfAuthorizedAndExists( to, from, next );
                    } )();
                } catch ( error ) {
                    if ( showRouteGuardWarnings ) console.warn( 'Route Guard ( Requires Auth ) - Auto Login Failed', error );
                    next( { path: LoginRoute.path } );
                }
            } else {
                if ( showRouteGuardWarnings ) console.warn( 'Route Guard ( Requires Auth ) - Redirecting to Login Page' );
                next( { path: LoginRoute.path, query: { next: to.fullPath } } );
            }
        } else if ( to.matched.some( ( route ) => route.name === LoginRoute.name ) && LocalAuthApi.hasLocalLoginCredentials() ) {
            if ( showRouteGuardWarnings ) console.warn( 'Route Guard ( Login page as logged in User ) - Already Logged in, no login page for you' );
            next( { path: '/' } );
        } else if ( to.matched.length > 0 ) {
            if ( showRouteGuardWarnings ) console.warn( 'Route Guard ( No Auth Required ) - Not logged in, route exists, go next' );
            next();
        } else {
            if ( showRouteGuardWarnings ) console.warn( 'Route Guard ( No Auth Required ) - Not logged in, route doesnt exist, go to Login' );
            next( { path: LoginRoute.path } );
        }
    }

    ( async () => {
        await doRouting();
    } )();
} );

// Export the router
export default router;
