import Vue from 'vue';

// Api
import CatalogListingApi from '@/api/CatalogListingApi';

// Utils
import FormatUtil from '@/utils/FormatUtil';
import LoggingUtil from '@/utils/LoggingUtil';

export default {
    namespaced: true,
    state: {
        titleModifications: {},

        // Metadata
        openToBuy: {
            list: [],
            inFlight: false,
        },
        additionalCatalogListings: {
            list: [],
            inFlight: false,
        },
    },
    getters: {
        /** @returns { Boolean } */
        hasTitleModifications: (state) => (
            Object.keys(state.titleModifications).includes('productCode')
            && Object.keys(state.titleModifications).length > 1
        ),
    },
    mutations: {
        SET_TITLE_MODIFICATIONS(state, titleModifications) {
            Vue.set(state, 'titleModifications', titleModifications);
        },
        SET_OPEN_TO_BUY_LIST(state, openToBuy) {
            state.openToBuy.list = openToBuy;
        },
        SET_OPEN_TO_BUY_LIST_IN_FLIGHT(state, inFlight) {
            state.openToBuy.inFlight = inFlight;
        },
        SET_ADDITIONAL_CATALOG_LISTINGS_LIST(state, additionalCatalogListings) {
            state.additionalCatalogListings.list = additionalCatalogListings;
        },
        SET_ADDITIONAL_CATALOG_LISTINGS_LIST_IN_FLIGHT(state, inFlight) {
            state.additionalCatalogListings.inFlight = inFlight;
        },
    },
    actions: {
        initializeTitleModifications({ commit }, { productCode }) {
            commit('SET_TITLE_MODIFICATIONS', { productCode });
        },
        updateTitleModifications({ commit, state }, { key, value }) {
            commit('SET_TITLE_MODIFICATIONS', { ...state.titleModifications, [key]: value });
        },
        removeTitleModifications({ commit, state }, { key }) {
            if (key in state.titleModifications) {
                const { [key]: removed, ...rest } = state.titleModifications;
                commit('SET_TITLE_MODIFICATIONS', rest);
            }
        },

        getCatalogListingMetaData({ dispatch, state }) {
            if (state.titleModifications.productCode) {
                dispatch('getOpenToBuyInfo');
                dispatch('getAdditionalCatalogListings');
            }
        },
        async getOpenToBuyInfo({ commit, dispatch, state }) {
            commit('SET_OPEN_TO_BUY_LIST', []);
            commit('SET_OPEN_TO_BUY_LIST_IN_FLIGHT', true);
            try {
                const data = await dispatch('PurchaseOrder/getOpenToBuyInfo', {
                    productCode: state.titleModifications.productCode,
                    allowSubscription: false,
                }, { root: true });
                commit('SET_OPEN_TO_BUY_LIST', data);
            } catch (error) {
                LoggingUtil.error(error);
            } finally {
                commit('SET_OPEN_TO_BUY_LIST_IN_FLIGHT', false);
            }
        },
        async getAdditionalCatalogListings({ commit, rootGetters, state }) {
            commit('SET_ADDITIONAL_CATALOG_LISTINGS_LIST', []);
            commit('SET_ADDITIONAL_CATALOG_LISTINGS_LIST_IN_FLIGHT', true);
            try {
                const { data } = await CatalogListingApi.searchByFilters({
                    authToken: rootGetters['User/authString'],
                    isbn: FormatUtil.sanitizeIsbn(state.titleModifications.productCode),
                });
                const additionalListingsList = (data?.titleResearchListDTOList?.sort((a, b) => {
                    const first = a.catalogListing?.section?.startDate?.toLowerCase() ?? '';
                    const second = b.catalogListing?.section?.startDate?.toLowerCase() ?? '';
                    return first < second ? 1 : -1;
                }) ?? []).map((titleResearchDto) => ({
                    ...titleResearchDto,
                    catalogListing: {
                        ...titleResearchDto.catalogListing,
                        effectiveCatalogDesignators: titleResearchDto.catalogListing.effectiveDesignatorDtos?.filter(
                            (material) => material.catalogListingEnabled,
                        ) ?? [],
                    },
                }));
                commit('SET_ADDITIONAL_CATALOG_LISTINGS_LIST', additionalListingsList);
            } catch (error) {
                LoggingUtil.error(error);
            } finally {
                commit('SET_ADDITIONAL_CATALOG_LISTINGS_LIST_IN_FLIGHT', false);
            }
        },
    },
};
