import getRouterView from '@/router/helpers';

export default {
    path: '/invoice',
    meta: {
        requiresAuth: true,
        title: 'Search',
    },
    component: getRouterView(),
    children: [
        {
            path: '',
            name: 'InvoiceReconciliationSearch',
            meta: {
                title: 'Invoices',
            },
            component: () => import( /* webpackChunkName: "InvoiceReconciliation" */ '@/views/Invoice/Search' ),
        },
        {
            path: 'create',
            name: 'InvoiceReconciliationCreate',
            meta: {
                title: 'Invoice Create',
            },
            component: () => import( /* webpackChunkName: "InvoiceReconciliation" */ '@/views/Invoice/Create' ),
        },
        {
            path: ':id',
            name: 'InvoiceReconciliationDetail',
            meta: {
                title: 'Invoice Reconciliation Detail',
            },
            component: () => import( /* webpackChunkName: "InvoiceReconciliation" */ '@/views/Invoice/Detail' ),
        },
    ],
};
