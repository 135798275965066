export default {
    Pending: 'PENDING',
    Problem: 'PROBLEM',
    Discrepancy: 'DISCREPANCY',
    Complete: 'COMPLETE',
};

export const CREDIT_MEMO_STATUS_LIST_COLUMN = {
    Pending: 0,
    Problem: 0,
    Discrepancy: 1,
    Complete: 1,
};
