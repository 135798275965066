// Api
import AdoptionApi from '@/api/AdoptionApi';
import CatalogApi from '@/api/CatalogApi';
import CatalogListingApi from '@/api/CatalogListingApi';
import CatalogPricingApi from '@/api/CatalogPricingApi';

// Utils
import ArrayUtil from '@/utils/ArrayUtil';
import FormatUtil from '@/utils/FormatUtil';
import LoggingUtil from '@/utils/LoggingUtil';
import StoreUtil from '@/utils/StoreUtil';

// Constants
import STATUS_LIST from '@/constants/catalogListing/statusList';

// Modules
import Detail from './Detail';

const mutationTypes = {
    SET_LIST: 'SET_LIST',
    CLEAR_LIST: 'CLEAR_LIST',
    SET_IN_FLIGHT: 'SET_IN_FLIGHT',
    SET_SORT_FIELD: 'SET_SORT_FIELD',
    SET_SORT_DIRECTION: 'SET_SORT_DIRECTION',
    UPDATE_SINGLE_CATALOG_LISTING: 'UPDATE_SINGLE_CATALOG_LISTING',
    UPDATE_MULTIPLE_TITLES: 'UPDATE_MULTIPLE_TITLES',

    SET_DIVISION_LIST: 'SET_DIVISION_LIST',
    SET_TERM_LIST: 'SET_TERM_LIST',
    SET_DEPARTMENT_LIST: 'SET_DEPARTMENT_LIST',
    SET_COURSE_LIST: 'SET_COURSE_LIST',
    SET_SECTION_LIST: 'SET_SECTION_LIST',

    SET_FILTER_CATALOG_LISTING_STATUSES: 'SET_FILTER_CATALOG_LISTING_STATUSES',
    SET_FILTER_CATALOGS: 'SET_FILTER_CATALOGS',
    SET_FILTER_ISBN: 'SET_FILTER_ISBN',
    SET_FILTER_SECTION_DATE_START: 'SET_FILTER_SECTION_DATE_START',
    SET_FILTER_SECTION_DATE_END: 'SET_FILTER_SECTION_DATE_END',
    SET_FILTER_SHELF_DATE_START: 'SET_FILTER_SHELF_DATE_START',
    SET_FILTER_SHELF_DATE_END: 'SET_FILTER_SHELF_DATE_END',
    SET_FILTER_PRIMARY_SUPPLIER: 'SET_FILTER_PRIMARY_SUPPLIER',
    SET_FILTER_LIMIT: 'SET_FILTER_LIMIT',
    SET_FILTER_TERM_OTB: 'SET_FILTER_TERM_OTB',
    SET_FILTER_IA_ONLY: 'SET_FILTER_IA_ONLY',
    SET_FILTER_IA_ONLY_CB: 'SET_FILTER_IA_ONLY_CB',
    SET_FILTER_IA_EXCLUDE_CB: 'SET_FILTER_IA_EXCLUDE_CB',
    SET_FILTER_TITLE_RESEARCH_COMPLETE: 'SET_FILTER_TITLE_RESEARCH_COMPLETE',

    SET_FILTER_TITLE_DESIGNATORS_INCLUDE: 'SET_FILTER_TITLE_DESIGNATORS_INCLUDE',
    SET_FILTER_TITLE_DESIGNATORS_EXCLUDE: 'SET_FILTER_TITLE_DESIGNATORS_EXCLUDE',
    SET_FILTER_CATALOG_LISTING_DESIGNATORS_INCLUDE: 'SET_FILTER_CATALOG_LISTING_DESIGNATORS_INCLUDE',
    SET_FILTER_CATALOG_LISTING_DESIGNATORS_EXCLUDE: 'SET_FILTER_CATALOG_LISTING_DESIGNATORS_EXCLUDE',
    SET_FILTER_ADOPTION_QUESTIONS_INCLUDE: 'SET_FILTER_ADOPTION_QUESTIONS_INCLUDE',
    SET_FILTER_ADOPTION_QUESTIONS_EXCLUDE: 'SET_FILTER_ADOPTION_QUESTIONS_EXCLUDE',
};

const actionTypes = {
    setList: 'setList',
    getByFilters: 'getByFilters',
    getById: 'getById',
    modify: 'modify',
    modifyList: 'modifyList',
    autocompleteList: 'autocompleteList',
    modifyListingTitle: 'modifyListingTitle',
    refreshItemInListWithCurrentCatalogListing: 'refreshItemInListWithCurrentCatalogListing',
    getByAdoptionMaterialId: 'getByAdoptionMaterialId',
    updateMultipleCatalogListings: 'updateMultipleCatalogListings',
    persistSortedResultsHack: 'persistSortedResultsHack',
    reset: 'reset',
    getInclusiveAccessPricing: 'getInclusiveAccessPricing',

    setLocationList: 'setLocationList',
    getLocationList: 'getLocationList',
    setDivisionList: 'setDivisionList',
    getDivisionList: 'getDivisionList',
    setTermList: 'setTermList',
    getTermList: 'getTermList',
    setDepartmentList: 'setDepartmentList',
    getDepartmentList: 'getDepartmentList',
    setCourseList: 'setCourseList',
    getCourseList: 'getCourseList',
    getCourseListByDepartmentAndDivision: 'getCourseListByDepartmentAndDivision',
    setSectionList: 'setSectionList',
    getSectionList: 'getSectionList',

    // Filter Catalog Actions
    initializeFilterCatalogListingStatusList: 'initializeFilterCatalogListingStatusList',
    addEmptyCatalog: 'addEmptyCatalog',
    removeCatalogFromFilterCatalogs: 'removeCatalogFromFilterCatalogs',
    updateCatalogByUid: 'updateCatalogByUid',

    clearFilters: 'clearFilters',
    setFilterCatalogListingStatuses: 'setFilterCatalogListingStatuses',
    setFilterCatalogs: 'setFilterCatalogs',
    setFilterIsbn: 'setFilterIsbn',
    setFilterSectionDateStart: 'setFilterSectionDateStart',
    setFilterSectionDateEnd: 'setFilterSectionDateEnd',
    setFilterShelfDateStart: 'setFilterShelfDateStart',
    setFilterShelfDateEnd: 'setFilterShelfDateEnd',
    setFilterPrimarySupplier: 'setFilterPrimarySupplier',
    setFilterLimit: 'setFilterLimit',
    setFilterTermOTB: 'setFilterTermOTB',
    setFilterIaOnly: 'setFilterIaOnly',
    setFilterIaOnlyCb: 'setFilterIaOnlyCb',
    setFilterIaExcludeCb: 'setFilterIaExcludeCb',
    setFilterTitleResearchComplete: 'setFilterTitleResearchComplete',

    setFilterTitleDesignators: 'setFilterTitleDesignators',
    setFilterTitleDesignatorsInclude: 'setFilterTitleDesignatorsInclude',
    setFilterTitleDesignatorsExclude: 'setFilterTitleDesignatorsExclude',
    setFilterCatalogListingDesignators: 'setFilterCatalogListingDesignators',
    setFilterCatalogListingDesignatorsInclude: 'setFilterCatalogListingDesignatorsInclude',
    setFilterCatalogListingDesignatorsExclude: 'setFilterCatalogListingDesignatorsExclude',
    setFilterAdoptionQuestions: 'setFilterAdoptionQuestions',
    setFilterAdoptionQuestionsInclude: 'setFilterAdoptionQuestionsInclude',
    setFilterAdoptionQuestionsExclude: 'setFilterAdoptionQuestionsExclude',
};

/**
 * Format a list of catalogListings to be shown on the Search page's table
 * @returns { Array } of formattedCatalogListings
 */
function formatCatalogListings( listingList ) {
    return ( listingList && listingList.length && listingList.length > 0 )
        ? [ ...listingList ].map( ( listing ) => ( {
            id: listing.catalogListing.id,
            division: listing.catalogListing.divisionName || listing.catalogListing.divisionId,
            term: listing.catalogListing.termName || listing.catalogListing.termId,
            dept: listing.catalogListing.department.code || listing.catalogListing.department.id,
            course: listing.catalogListing.course.code || listing.catalogListing.course.id,
            section: listing.catalogListing.section.code,
            productCode: listing.productCode,
            description: FormatUtil.truncateText( listing.description, 20 ),
            primarySupplier: listing.primarySupplier
                ? listing.primarySupplier.name
                : 'Not set',
            shelfDate: typeof listing.shelfDate === 'number'
                ? FormatUtil.epochToDate( listing.shelfDate )
                : listing.shelfDate,
            sourcingEndDate: typeof listing.sourcingEndDate === 'number'
                ? FormatUtil.epochToDate( listing.sourcingEndDate )
                : listing.sourcingEndDate,
            listPrice: listing.listPrice,
            status: listing.catalogListing.status,
            lastResearchedDate: FormatUtil.dateTimeToSimpleString( listing.lastResearchedDate ),
            researcherName: listing.researcherName,
        } ) )
        : [];
}

/**
* This allows us to persist our sorted Catalog Listings via the Buefy table into state
* The sortBy() and getValueByPath() methods are also directly lifted from Buefy
* @returns { Object } with methods: emulate, asyncEmulate
*/
const BackendSortingEmulator = {
    emulate( resultList, key, isAsc ) {
        // sort formatted results
        const sortedFormattedResultList = ArrayUtil.sortBy( formatCatalogListings( resultList ), key, null, isAsc );

        // reorder unformatted results to match order of formatted results
        const sortedUnformattedResultList = [];
        sortedFormattedResultList.forEach( ( item ) => {
            sortedUnformattedResultList.push( resultList.find( ( originalItem ) => originalItem.catalogListing.id === item.id ) );
        } );

        // return sorted unformatted results
        return sortedUnformattedResultList;
    },
    asyncEmulate( resultList, key, isAsc, timeout = 1 ) {
        const sortedResultList = this.emulate( resultList, key, isAsc );
        return new Promise( ( resolve ) => setTimeout( () => resolve( sortedResultList ), timeout ) );
    },
};

const getDepartmentListFromApi = async ( context, { term, division } ) => {
    if ( term ) {
        const { data } = await CatalogApi.getDepartmentListByTerm( {
            authToken: context.rootGetters[ 'User/authString' ],
            term,
        } );
        return data.departments;
    }
    if ( division ) {
        const { data } = await CatalogApi.getDepartmentListByDivision( {
            authToken: context.rootGetters[ 'User/authString' ],
            divisionId: division.id,
        } );
        return data.codes;
    }
    // Either term or division must be provided
    throw new Error( 'Term or Division expected' );
};

export default {
    namespaced: true,
    modules: {
        Detail,
    },
    state: {
        /** @type { title[] } */
        list: [],
        /** @type { Boolean } */
        inFlight: false,
        /** @type { Object } */
        metadata: {
            /** @type { Number } */
            // currentPage: 1,
            /** @type { Number } */
            // lastPage: 1,
            /** @type { Boolean } */
            // shouldRequestFirstPage: false,
            /** @type { Number } */
            // perPage: 20,
            /** @type { String } */
            sortField: 'shelfDate',
            /** @type { String } */
            sortDirection: 'asc', // asc or desc
            /** @type { Number } */
            // totalResults: 0,
        },
        catalogListingStatusList: Object.entries( STATUS_LIST ).map( ( status ) => ( {
            key: status[ 0 ],
            value: status[ 1 ],
        } ) ),
        catalogFilterLists: {
            divisionList: [],
            termList: [],
            departmentList: [],
            courseList: [],
            sectionList: [],
        },
        filters: {
            catalogListingStatuses: StoreUtil.status.initializeList( STATUS_LIST ),
            catalogs: [ {
                uid: Date.now(),
                breadcrumb: {},
            } ],
            isbn: '',
            sectionDateStart: '',
            sectionDateEnd: '',
            shelfDateStart: '',
            shelfDateEnd: '',
            primarySupplier: '',
            limit: 0,
            termOTB: true,
            iaOnly: null,
            iaOnlyCb: false,
            iaExcludeCb: false,
            titleResearchComplete: false,

            titleDesignators: {
                include: [],
                exclude: [],
            },
            catalogListingDesignators: {
                include: [],
                exclude: [],
            },
            adoptionQuestions: {
                include: [],
                exclude: [],
            },
        },
    },
    getters: {
        /** @returns { Number } */
        listingCount: ( state ) => state.list.length,

        /** @returns { Boolean } */
        hasListings: ( state, getters ) => getters.listingCount > 0,

        /** @returns { Array } of catalog listings */
        formattedList: ( state, getters ) => (
            getters.hasListings
                ? formatCatalogListings( state.list )
                : []
        ),
        /** @returns { Boolean } */
        hasCatalogs: ( state ) => (
            state.filters.catalogs.length > 0
        ),
        /** @returns { Boolean } */
        allCatalogsHaveLocation: ( state ) => (
            state.filters.catalogs.every( ( catalog ) => !!catalog.breadcrumb.location?.id )
        ),
        /** @returns { Number } */
        currentCatalogListingIndex: ( state, getters, rootState ) => (
            state.list.findIndex( ( title ) => title?.catalogListing?.id === rootState.currentCatalogListing?.id )
        ),
    },
    mutations: {
        [ mutationTypes.SET_LIST ]( state, { list } ) {
            state.list = list;
        },
        [ mutationTypes.CLEAR_LIST ]( state ) {
            state.list = [];
        },
        [ mutationTypes.SET_IN_FLIGHT ]( state, { boolean } ) {
            state.inFlight = boolean;
        },
        [ mutationTypes.SET_SORT_FIELD ]( state, { field } ) {
            state.metadata.sortField = field || '';
        },
        /** { String } direction - 'asc' || 'desc' */
        [ mutationTypes.SET_SORT_DIRECTION ]( state, { direction } ) {
            state.metadata.sortDirection = ( direction === 'asc' ) || ( direction === 'desc' )
                ? direction
                : 'asc';
        },
        [ mutationTypes.UPDATE_SINGLE_CATALOG_LISTING ]( state, { catalogListing } ) {
            const index = state.list.findIndex( ( listing ) => listing.catalogListing.id === catalogListing.id );
            if ( index !== -1 ) {
                state.list[ index ].catalogListing = catalogListing;
            }
        },
        [ mutationTypes.UPDATE_MULTIPLE_TITLES ]( state, { currentTitleId, currentCatalogListing, titleModifications } ) {
            state.list
                .filter( ( listing ) => listing.id === currentTitleId )
                .forEach( ( listing ) => {
                    if ( titleModifications.listPrice ) {
                        // eslint-disable-next-line no-param-reassign
                        listing.listPrice = titleModifications.listPrice;
                    }
                    if ( titleModifications.primarySupplier ) {
                        // eslint-disable-next-line no-param-reassign
                        listing.primarySupplier = titleModifications.primarySupplier;
                    }
                    if ( titleModifications.notes ) {
                        // eslint-disable-next-line no-param-reassign
                        listing.notes = titleModifications.notes;
                    }
                    if ( titleModifications.titleDesignators ) {
                        // eslint-disable-next-line no-param-reassign
                        listing.titleDesignators = titleModifications.titleDesignators;
                    }
                    if ( titleModifications.lastResearchedDate ) {
                        // eslint-disable-next-line no-param-reassign
                        listing.lastResearchedDate = titleModifications.lastResearchedDate;
                    }
                } );

            const index = state.list.findIndex( ( listing ) => listing.catalogListing.id === currentCatalogListing.id );
            if ( index !== -1 ) {
                state.list[ index ].catalogListing = currentCatalogListing;
            }
        },

        [ mutationTypes.SET_FILTER_CATALOG_LISTING_STATUSES ]( state, { catalogListingStatuses } ) {
            state.filters.catalogListingStatuses = catalogListingStatuses;
        },
        [ mutationTypes.SET_FILTER_CATALOGS ]( state, { catalogs } ) {
            state.filters.catalogs = catalogs;
        },
        [ mutationTypes.SET_FILTER_ISBN ]( state, { isbn } ) {
            state.filters.isbn = isbn;
        },
        [ mutationTypes.SET_FILTER_SECTION_DATE_START ]( state, { sectionDateStart } ) {
            state.filters.sectionDateStart = sectionDateStart;
        },
        [ mutationTypes.SET_FILTER_SECTION_DATE_END ]( state, { sectionDateEnd } ) {
            state.filters.sectionDateEnd = sectionDateEnd;
        },
        [ mutationTypes.SET_FILTER_SHELF_DATE_START ]( state, { shelfDateStart } ) {
            state.filters.shelfDateStart = shelfDateStart;
        },
        [ mutationTypes.SET_FILTER_SHELF_DATE_END ]( state, { shelfDateEnd } ) {
            state.filters.shelfDateEnd = shelfDateEnd;
        },
        [ mutationTypes.SET_FILTER_PRIMARY_SUPPLIER ]( state, { primarySupplier } ) {
            state.filters.primarySupplier = primarySupplier;
        },
        [ mutationTypes.SET_FILTER_LIMIT ]( state, { limit } ) {
            state.filters.limit = limit;
        },
        [ mutationTypes.SET_FILTER_TERM_OTB ]( state, { termOTB } ) {
            state.filters.termOTB = termOTB;
        },
        [ mutationTypes.SET_FILTER_IA_ONLY ]( state, { iaOnly } ) {
            state.filters.iaOnly = iaOnly;
        },
        [ mutationTypes.SET_FILTER_IA_ONLY_CB ]( state, { iaOnlyCb } ) {
            state.filters.iaOnlyCb = iaOnlyCb;
        },
        [ mutationTypes.SET_FILTER_IA_EXCLUDE_CB ]( state, { iaExcludeCb } ) {
            state.filters.iaExcludeCb = iaExcludeCb;
        },
        [ mutationTypes.SET_FILTER_TITLE_RESEARCH_COMPLETE ]( state, { titleResearchComplete } ) {
            state.filters.titleResearchComplete = titleResearchComplete;
        },

        [ mutationTypes.SET_FILTER_TITLE_DESIGNATORS_INCLUDE ]( state, { include } ) {
            state.filters.titleDesignators.include = include;
        },
        [ mutationTypes.SET_FILTER_TITLE_DESIGNATORS_EXCLUDE ]( state, { exclude } ) {
            state.filters.titleDesignators.exclude = exclude;
        },

        [ mutationTypes.SET_FILTER_CATALOG_LISTING_DESIGNATORS_INCLUDE ]( state, { include } ) {
            state.filters.catalogListingDesignators.include = include;
        },
        [ mutationTypes.SET_FILTER_CATALOG_LISTING_DESIGNATORS_EXCLUDE ]( state, { exclude } ) {
            state.filters.catalogListingDesignators.exclude = exclude;
        },

        [ mutationTypes.SET_FILTER_ADOPTION_QUESTIONS_INCLUDE ]( state, { include } ) {
            state.filters.adoptionQuestions.include = include;
        },
        [ mutationTypes.SET_FILTER_ADOPTION_QUESTIONS_EXCLUDE ]( state, { exclude } ) {
            state.filters.adoptionQuestions.exclude = exclude;
        },

        [ mutationTypes.SET_DIVISION_LIST ]( state, { divisionList } ) {
            state.catalogFilterLists.divisionList = divisionList;
        },
        [ mutationTypes.SET_TERM_LIST ]( state, { termList } ) {
            state.catalogFilterLists.termList = termList;
        },
        [ mutationTypes.SET_DEPARTMENT_LIST ]( state, { departmentList } ) {
            state.catalogFilterLists.departmentList = departmentList;
        },
        [ mutationTypes.SET_COURSE_LIST ]( state, { courseList } ) {
            state.catalogFilterLists.courseList = courseList;
        },
        [ mutationTypes.SET_SECTION_LIST ]( state, { sectionList } ) {
            state.catalogFilterLists.sectionList = sectionList;
        },
    },
    actions: {
        [ actionTypes.reset ]( { commit } ) {
            commit( mutationTypes.CLEAR_LIST );
            commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            commit( mutationTypes.SET_SORT_FIELD, { field: 'shelfDate' } );
            commit( mutationTypes.SET_SORT_DIRECTION, { direction: 'asc' } );
        },

        [ actionTypes.initializeFilterCatalogListingStatusList ]( context ) {
            const catalogListingStatuses = {};
            context.state.catalogListingStatusList.forEach( ( status ) => {
                catalogListingStatuses[ status.key ] = false;
            } );
            context.dispatch( actionTypes.setFilterCatalogListingStatuses, { catalogListingStatuses } );
        },

        [ actionTypes.setFilterCatalogListingStatuses ]( context, { catalogListingStatuses } ) {
            context.commit( mutationTypes.SET_FILTER_CATALOG_LISTING_STATUSES, { catalogListingStatuses } );
        },
        // FILTER CATALOG ACTIONS
        [ actionTypes.setFilterCatalogs ]( context, { catalogs } ) {
            context.commit( mutationTypes.SET_FILTER_CATALOGS, { catalogs } );
            if ( catalogs.length === 0 ) {
                context.dispatch( actionTypes.addEmptyCatalog );
            }
        },
        [ actionTypes.addEmptyCatalog ]( context ) {
            const catalogs = [ ...context.state.filters.catalogs ];
            catalogs.push( {
                uid: Date.now(),
                breadcrumb: {},
            } );
            context.dispatch( actionTypes.setFilterCatalogs, { catalogs } );
        },
        [ actionTypes.removeCatalogFromFilterCatalogs ]( context, { catalog } ) {
            const catalogs = [ ...context.state.filters.catalogs.filter( ( filterCatalog ) => filterCatalog.uid !== catalog.uid ) ];
            context.dispatch( actionTypes.setFilterCatalogs, { catalogs } );
        },
        [ actionTypes.updateCatalogByUid ]( context, { uid, breadcrumb } ) {
            const catalogsToUpdate = [ ...context.state.filters.catalogs ];
            const index = catalogsToUpdate.findIndex( ( catalogToUpdate ) => catalogToUpdate.uid === uid );
            if ( index !== -1 ) {
                catalogsToUpdate[ index ] = { ...catalogsToUpdate[ index ], breadcrumb };

                context.dispatch( actionTypes.setFilterCatalogs, { catalogs: catalogsToUpdate } );
            } else {
                const message = 'Unable to modify catalog filter';
                context.dispatch( 'setErrorNotification', message, { root: true } );
                throw new Error( message );
            }
        },

        [ actionTypes.setFilterIsbn ]( context, { isbn } ) {
            context.commit( mutationTypes.SET_FILTER_ISBN, { isbn } );
        },
        [ actionTypes.setFilterSectionDateStart ]( context, { sectionDateStart } ) {
            context.commit( mutationTypes.SET_FILTER_SECTION_DATE_START, { sectionDateStart } );
        },
        [ actionTypes.setFilterSectionDateEnd ]( context, { sectionDateEnd } ) {
            context.commit( mutationTypes.SET_FILTER_SECTION_DATE_END, { sectionDateEnd } );
        },
        [ actionTypes.setFilterShelfDateStart ]( context, { shelfDateStart } ) {
            context.commit( mutationTypes.SET_FILTER_SHELF_DATE_START, { shelfDateStart } );
        },
        [ actionTypes.setFilterShelfDateEnd ]( context, { shelfDateEnd } ) {
            context.commit( mutationTypes.SET_FILTER_SHELF_DATE_END, { shelfDateEnd } );
        },
        [ actionTypes.setFilterPrimarySupplier ]( context, { primarySupplier } ) {
            context.commit( mutationTypes.SET_FILTER_PRIMARY_SUPPLIER, { primarySupplier } );
        },
        [ actionTypes.setFilterLimit ]( context, { limit } ) {
            context.commit( mutationTypes.SET_FILTER_LIMIT, { limit } );
        },
        [ actionTypes.setFilterTermOTB ]( context, { termOTB } ) {
            context.commit( mutationTypes.SET_FILTER_TERM_OTB, { termOTB } );
        },
        [ actionTypes.setFilterIaOnly ]( context, { iaOnly } ) {
            context.commit( mutationTypes.SET_FILTER_IA_ONLY, { iaOnly } );
        },
        [ actionTypes.setFilterIaOnlyCb ]( context, { iaOnlyCb } ) {
            context.commit( mutationTypes.SET_FILTER_IA_ONLY_CB, { iaOnlyCb } );

            if ( iaOnlyCb ) {
                context.dispatch( actionTypes.setFilterIaExcludeCb, { iaExcludeCb: false } );
                context.dispatch( actionTypes.setFilterIaOnly, { iaOnly: true } );
            }
            if ( context.state.iaOnlyCb === false && context.state.iaExcludeCb === false ) {
                context.dispatch( actionTypes.setFilterIaOnly, { iaOnly: null } );
            }
        },
        [ actionTypes.setFilterIaExcludeCb ]( context, { iaExcludeCb } ) {
            context.commit( mutationTypes.SET_FILTER_IA_EXCLUDE_CB, { iaExcludeCb } );

            if ( iaExcludeCb ) {
                context.dispatch( actionTypes.setFilterIaOnlyCb, { iaOnlyCb: false } );
                context.dispatch( actionTypes.setFilterIaOnly, { iaOnly: false } );
            }
            if ( context.state.iaOnlyCb === false && iaExcludeCb === false ) {
                context.dispatch( actionTypes.setFilterIaOnly, { iaOnly: null } );
            }
        },
        [ actionTypes.setFilterTitleResearchComplete ]( context, { titleResearchComplete } ) {
            context.commit( mutationTypes.SET_FILTER_TITLE_RESEARCH_COMPLETE, { titleResearchComplete } );
        },
        [ actionTypes.setFilterTitleDesignators ]( context, { titleDesignators } ) {
            context.dispatch( actionTypes.setFilterTitleDesignatorsInclude, { include: [ ...titleDesignators.include ] } );
            context.dispatch( actionTypes.setFilterTitleDesignatorsExclude, { exclude: [ ...titleDesignators.exclude ] } );
        },
        [ actionTypes.setFilterTitleDesignatorsInclude ]( context, { include } ) {
            context.commit( mutationTypes.SET_FILTER_TITLE_DESIGNATORS_INCLUDE, { include } );
        },
        [ actionTypes.setFilterTitleDesignatorsExclude ]( context, { exclude } ) {
            context.commit( mutationTypes.SET_FILTER_TITLE_DESIGNATORS_EXCLUDE, { exclude } );
        },
        [ actionTypes.setFilterCatalogListingDesignators ]( context, { catalogListingDesignators } ) {
            context.dispatch( actionTypes.setFilterCatalogListingDesignatorsInclude, { include: [ ...catalogListingDesignators.include ] } );
            context.dispatch( actionTypes.setFilterCatalogListingDesignatorsExclude, { exclude: [ ...catalogListingDesignators.exclude ] } );
        },
        [ actionTypes.setFilterCatalogListingDesignatorsInclude ]( context, { include } ) {
            context.commit( mutationTypes.SET_FILTER_CATALOG_LISTING_DESIGNATORS_INCLUDE, { include } );
        },
        [ actionTypes.setFilterCatalogListingDesignatorsExclude ]( context, { exclude } ) {
            context.commit( mutationTypes.SET_FILTER_CATALOG_LISTING_DESIGNATORS_EXCLUDE, { exclude } );
        },
        [ actionTypes.setFilterAdoptionQuestions ]( context, { adoptionQuestions } ) {
            context.dispatch( actionTypes.setFilterAdoptionQuestionsInclude, { include: [ ...adoptionQuestions.include ] } );
            context.dispatch( actionTypes.setFilterAdoptionQuestionsExclude, { exclude: [ ...adoptionQuestions.exclude ] } );
        },
        [ actionTypes.setFilterAdoptionQuestionsInclude ]( context, { include } ) {
            context.commit( mutationTypes.SET_FILTER_ADOPTION_QUESTIONS_INCLUDE, { include } );
        },
        [ actionTypes.setFilterAdoptionQuestionsExclude ]( context, { exclude } ) {
            context.commit( mutationTypes.SET_FILTER_ADOPTION_QUESTIONS_EXCLUDE, { exclude } );
        },

        [ actionTypes.setDivisionList ]( context, { divisionList } ) {
            context.commit( mutationTypes.SET_DIVISION_LIST, { divisionList } );
        },
        [ actionTypes.setTermList ]( context, { termList } ) {
            context.commit( mutationTypes.SET_TERM_LIST, { termList } );
        },
        [ actionTypes.setDepartmentList ]( context, { departmentList } ) {
            context.commit( mutationTypes.SET_DEPARTMENT_LIST, { departmentList } );
        },
        [ actionTypes.setCourseList ]( context, { courseList } ) {
            context.commit( mutationTypes.SET_COURSE_LIST, { courseList } );
        },
        [ actionTypes.setSectionList ]( context, { sectionList } ) {
            context.commit( mutationTypes.SET_SECTION_LIST, { sectionList } );
        },
        async [ actionTypes.getDivisionList ]( context, { location } ) {
            context.dispatch( actionTypes.setDivisionList, { divisionList: [] } );
            try {
                const { data } = await CatalogApi.getDivisionListByLocation( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    location,
                } );
                const divisionList = data.divisions
                    .sort( ( a, b ) => {
                        const first = a.name;
                        const second = b.name;
                        return first > second ? 1 : -1; // asc
                    } );
                context.dispatch( actionTypes.setDivisionList, { divisionList } );
                return divisionList;
            } catch ( error ) {
                const message = `Failed to get Division list: ${ StoreUtil.error.getMessage( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                throw new Error( error );
            }
        },
        async [ actionTypes.getTermList ]( context, { division } ) {
            context.dispatch( actionTypes.setTermList, { termList: [] } );
            try {
                const { data } = await CatalogApi.getTermListByDivision( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    division,
                } );
                const termList = data.terms;
                context.dispatch( actionTypes.setTermList, { termList } );
                return termList;
            } catch ( error ) {
                const message = `Failed to get Term list: ${ StoreUtil.error.getMessage( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                throw new Error( error );
            }
        },
        async [ actionTypes.getDepartmentList ]( context, { term, division } ) {
            context.dispatch( actionTypes.setDepartmentList, { departmentList: [] } );
            try {
                let departmentList = await getDepartmentListFromApi( context, { term, division } );
                if ( !departmentList ) {
                    departmentList = [];
                }
                context.dispatch( actionTypes.setDepartmentList, { departmentList } );
                return departmentList;
            } catch ( error ) {
                const message = `Failed to get Department list: ${ StoreUtil.error.getMessage( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                throw new Error( error );
            }
        },
        async [ actionTypes.getCourseList ]( context, { department } ) {
            context.dispatch( actionTypes.setCourseList, { courseList: [] } );
            try {
                const { data } = await CatalogApi.getCourseListByDepartment( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    department,
                } );
                const courseList = data.courses;
                context.dispatch( actionTypes.setCourseList, { courseList } );
                return courseList;
            } catch ( error ) {
                const message = `Failed to get Course list: ${ StoreUtil.error.getMessage( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                throw new Error( error );
            }
        },
        async [ actionTypes.getCourseListByDepartmentAndDivision ]( context, { department, division } ) {
            context.dispatch( actionTypes.setCourseList, { courseList: [] } );
            try {
                const { data } = await CatalogApi.getCourseListByDivisionAndDepartment( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    departmentCode: department,
                    divisionId: division.id,
                } );
                const courseList = data.codes;
                context.dispatch( actionTypes.setCourseList, { courseList } );
                return courseList;
            } catch ( error ) {
                const message = `Failed to get Course list: ${ StoreUtil.error.getMessage( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                throw new Error( error );
            }
        },
        async [ actionTypes.getSectionList ]( context, { course } ) {
            context.dispatch( actionTypes.setSectionList, { sectionList: [] } );
            try {
                const { data } = await CatalogApi.getSectionListByCourse( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    course,
                } );
                const sectionList = data.sections;
                context.dispatch( actionTypes.setSectionList, { sectionList } );
                return sectionList;
            } catch ( error ) {
                const message = `Failed to get Section list: ${ StoreUtil.error.getMessage( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                throw new Error( error );
            }
        },

        [ actionTypes.updateMultipleCatalogListings ]( context, { catalogListingList } ) {
            // catalogListingList must be a true list of catalog Listings, not a title with catalog listing object
            catalogListingList.forEach( ( catalogListing ) => {
                context.commit( mutationTypes.UPDATE_SINGLE_CATALOG_LISTING, { catalogListing } );
            } );
        },

        async modifyListingTitle( { commit, dispatch, rootState }, { title } ) {
            await dispatch( 'Title/modifyTitle', { title }, { root: true } );
            commit( mutationTypes.UPDATE_MULTIPLE_TITLES, {
                currentTitleId: rootState.currentTitle.id,
                currentCatalogListing: rootState.currentCatalogListing,
                titleModifications: title,
            } );
        },

        refreshItemInListWithCurrentCatalogListing( context ) {
            context.commit( mutationTypes.UPDATE_SINGLE_CATALOG_LISTING, {
                catalogListing: context.rootState.currentCatalogListing,
            } );
        },

        /**
         * Used to set the list when sorting catalog listings
         */
        async [ actionTypes.setList ]( context, { list } ) {
            context.commit( mutationTypes.SET_LIST, { list } );
        },

        /**
         * Get a list of catalogListings by filters
         */
        async [ actionTypes.getByFilters ]( context, payload ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            context.commit( mutationTypes.CLEAR_LIST );
            try {
                const { data } = await CatalogListingApi.searchByFilters( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    ...payload,
                } );

                context.commit( mutationTypes.SET_LIST, {
                    list: BackendSortingEmulator.emulate(
                        data.titleResearchListDTOList,
                        context.state.metadata.sortField,
                        context.state.metadata.sortDirection === 'asc',
                    ),
                } );

                if ( data?.titleResearchListDTOList?.length === 0 ) {
                    context.dispatch( 'setSuccessNotification', 'No Catalog Listings match the applied filters', { root: true } );
                }
            } catch ( error ) {
                const message = `Failed to get Catalog Listings - ${ error.response?.data?.message ?? error.message }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },

        /**
         * Get a catalog listing by id
         */
        async getById( context, { catalogListingId } ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                const { data } = await CatalogListingApi.searchByFilters( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    catalogListingId,
                } );

                // data.titleResearchListDTOList will always exist, even if it is an empty array
                if ( data.titleResearchListDTOList.length === 0 ) {
                    throw Error( `No Catalog Listings found for ID: ${ catalogListingId }` );
                }
                if ( data.titleResearchListDTOList.length > 1 ) {
                    throw Error( `Multiple Catalog Listings found for ID: ${ catalogListingId }` );
                }

                // Set the CurrentCatalogListing and CurrentTitle if we have a single result
                await context.dispatch( 'setCurrentCatalogListing', {
                    catalogListing: data.titleResearchListDTOList[ 0 ]?.catalogListing ?? {},
                }, { root: true } );

                await context.dispatch( 'setCurrentTitle', {
                    title: data.titleResearchListDTOList[ 0 ],
                }, { root: true } );

                if ( !context.getters.hasListings ) {
                    context.commit( mutationTypes.SET_LIST, {
                        list: data.titleResearchListDTOList,
                    } );
                }
            } catch ( error ) {
                const message = `Failed to get Catalog Listing by ID - ${ error.response?.data?.message ?? error.message }`;
                await context.dispatch( 'setErrorNotification', message, { root: true } );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },

        /**
         * Get Catalog Listings by adoptionMaterialId
         */
        async getByAdoptionMaterialId( context, { adoptionMaterialId } ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                const { data } = await AdoptionApi.getRelatedCatalogListingsByAdoptionId( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    adoptionMaterialId,
                } );

                context.commit( mutationTypes.SET_LIST, {
                    list: BackendSortingEmulator.emulate(
                        data.titleResearchListDTOList,
                        context.state.metadata.sortField,
                        context.state.metadata.sortDirection === 'asc',
                    ),
                } );
            } catch ( error ) {
                const message = `Failed get Catalog Listing for a given Adoption - ${ error.response?.data?.message ?? error.message }`;
                await context.dispatch( 'setErrorNotification', message, { root: true } );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },

        /**
         * Modify a single catalogListing
         */
        async modify( context, { catalogListingList } ) {
            try {
                const { data } = await CatalogListingApi.modifyList( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    catalogListingList,
                } );

                if ( data?.catalogListings?.length > 0 ) {
                    await context.dispatch( actionTypes.updateMultipleCatalogListings, {
                        catalogListingList: data.catalogListings,
                    } );
                    await context.dispatch( 'setSuccessNotification', 'Successfully modified this Catalog Listing', { root: true } );
                } else {
                    throw new Error();
                }
            } catch ( error ) {
                await context.dispatch(
                    'setErrorNotification',
                    `Failed to modify a Catalog Listing - ${ error.response?.data?.message ?? error.message }`,
                    { root: true },
                );
            }
        },

        /**
         * Modify a list of catalogListings
         */
        async [ actionTypes.modifyList ]( context, { catalogListingList, bubbleErrors = true } ) {
            try {
                const { data } = await CatalogListingApi.modifyList( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    catalogListingList,
                } );

                if ( data?.catalogListings?.length > 0 ) {
                    await context.dispatch( actionTypes.updateMultipleCatalogListings, {
                        catalogListingList: data.catalogListings,
                    } );
                    const message = catalogListingList.length === 1
                        ? 'Catalog listing modified!'
                        : `${ catalogListingList.length } catalog listings have been modified!`;
                    context.dispatch( 'setSuccessNotification', message, { root: true } );
                } else {
                    throw new Error();
                }
            } catch ( error ) {
                if ( !bubbleErrors ) {
                    const message = `Failed to modify Catalog Listings - ${ error.response?.data?.message ?? error.message }`;
                    context.dispatch( 'setErrorNotification', message, { root: true } );
                }
            }
        },

        /**
         * Autocomplete a list of catalogListings
         */
        async [ actionTypes.autocompleteList ]( context, { catalogListingList } ) {
            try {
                const { data } = await CatalogListingApi.autocompleteList( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    catalogListingList,
                } );

                // catalogListingListDTO is null if none of the autocompleted listings fall within the 'days out' date range
                if ( data?.catalogListingListDTO?.length > 0 ) {
                    context.dispatch( 'setSuccessNotification', 'Successfully Autocompleted the selected listings.', { root: true } );
                    await context.dispatch( actionTypes.updateMultipleCatalogListings, {
                        catalogListingList: data?.catalogListingListDTO?.catalogListings ?? [],
                    } );
                } else {
                    const message = "Selected listings outside the 'days out' settings specified in Juniper did not autocomplete";
                    context.dispatch( 'setSuccessNotification', message, { root: true } );
                }
            } catch ( error ) {
                const message = `Failed to Autocomplete Catalog Listings - ${ error.response?.data?.message ?? error.message }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
            }
        },

        /*
        * This exists exclusively to persist sorted catalog listings into state from my hand-written, totally lifted, Buefy table
        * This should effectively emulate what Buefy needs to let the :backendSorting prop do it's job
        */
        async [ actionTypes.persistSortedResultsHack ]( context ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                const finalSortedList = await BackendSortingEmulator.asyncEmulate(
                    context.state.list,
                    context.state.metadata.sortField,
                    context.state.metadata.sortDirection === 'asc',
                );
                context.commit( mutationTypes.SET_LIST, {
                    list: finalSortedList,
                } );
            } catch ( error ) {
                console.log( 'I\'m just sorting, did I really get an error?', error );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },

        /**
         * Get Inclusive Access Pricing records for a given catalogListingId or titleId
         */
        async [ actionTypes.getInclusiveAccessPricing ]( { rootGetters }, { catalogListingId = null, titleId = null } ) {
            try {
                const { data } = await CatalogPricingApi.search( {
                    authToken: rootGetters[ 'User/authString' ],
                    catalogListingIdFilter: catalogListingId || undefined,
                    titleIdFilter: titleId || undefined,
                } );
                return data.catalogPricingEntries?.sort( ( a, b ) => ( a.active < b.active ? 1 : -1 ) )
                    ?? [];
            } catch ( error ) {
                LoggingUtil.error( error );
                throw error;
            }
        },
    },
};
