import Adoption from './Adoption';
import CatalogListing from './CatalogListing';
import GlobalResources from './GlobalResources';
import PurchaseOrder from './PurchaseOrder';
import RRA from './RRA';
import Invoice from './Invoice';
import Navigation from './Navigation';
import CreditMemo from './CreditMemo';
import Title from './Title';
import TitleGroup from './TitleGroup';
import User from './User';
import Lookup from './Lookup';
import Subscription from './Subscription';

// https://vuex.vuejs.org/guide/modules.html
export default {
    Adoption,
    CatalogListing,
    GlobalResources,
    PurchaseOrder,
    RRA,
    Invoice,
    Navigation,
    CreditMemo,
    Title,
    TitleGroup,
    User,
    Lookup,
    Subscription,
};
