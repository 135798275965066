import _ from 'lodash';

// Mappers
import location from './location';

// Libraries

export default {
    /**
     * @param { RRA[] } rras
     * @returns { JasperReportDto[] }
     */
    getJasperReportSearchDtosFromRRAs( rras ) {
        const rrasGroupedByLocation = _.groupBy( rras, ( rra ) => rra.location.id );
        const jasperReportDtos = [];
        Object.entries( rrasGroupedByLocation ).forEach( ( rraEntry ) => {
            const [ rraGroupKey, rraGroup ] = rraEntry;
            jasperReportDtos.push(
                {
                    locationId: rraGroupKey,
                    rraNumbers: rraGroup.map( ( rra ) => rra.number ),
                },
            );
        } );
        return jasperReportDtos;
    },
    /**
     * @param { JasperReportDto[] } jasperReportDtos
     * @returns { JasperReport[] }
     */
    getJasperReportsFromJasperReportDtos( jasperReportDtos ) {
        return jasperReportDtos.map( ( jasperReportDto ) => this.getJasperReportFromJasperReportDto( jasperReportDto ) );
    },
    /**
     * @param { JasperReportDto } jasperReportDto
     * @returns { JasperReport }
     */
    getJasperReportFromJasperReportDto( jasperReportDto ) {
        return {
            reportUrl: jasperReportDto.reportUrl,
            location: location.getLocationFromLocationDto( jasperReportDto.location ),
            rraNumbers: jasperReportDto.rraNumbers,
        };
    },
};
