export const FILE_TYPES = {
    CSV: {
        delimiter: ',',
        encoding: 'text/csv;charset=utf-8;',
        extension: 'csv',
    },
    TSV: {
        delimiter: '\t',
        encoding: 'text/tab-separated-values;charset=utf-8;',
        extension: 'tsv',
    },
};

export default {
    /**
     * Format data to be put into a csv file
     * @params { String[] } headers
     * @params { <String|Number>[] } data - must line up with headers
     * @params { String } delimiter - defaults to a comma
     * @returns { String } - a comma-delimited string of data, with newlines at the end of each line
     */
    format( {
        headers = [],
        data = [],
        delimiter = FILE_TYPES.CSV.delimiter,
    } ) {
        const characterSeparatedValuesList = [];
        // Add our headers
        characterSeparatedValuesList.push( headers );
        // Add our data
        data.forEach( ( lineData ) => {
            // Push an array of values into our characterSeparatedValuesList array
            characterSeparatedValuesList.push(
                Object
                    .values( lineData )
                    .map( ( item ) => `"${ item ? item.toString().trim() : '' }"` ),
            );
        } );
        return characterSeparatedValuesList
            .map( ( entry ) => entry.join( delimiter ) )
            .join( '\r\n' );
    },

    /**
     * Create and download a csv file
     * Inspired by: https://stackoverflow.com/a/14966131
     * @params { String } filename - may or may not end with '.csv'
     * @params { String[] } headers
     * @params { String[] | Number[] } data - must line up with headers
     * @params { FILE_TYPE } filetype - defaults to csv
     * @throws { Error } - if given an unsupported filetype
     */
    create( {
        filename = 'export',
        headers = [],
        data = [],
        filetype = FILE_TYPES.CSV,
    } ) {
        if ( !Object.values( FILE_TYPES ).includes( filetype ) ) {
            throw new Error( `Unsupported filetype: ${ filetype }` );
        }

        const blob = new Blob(
            [ this.format( {
                headers,
                data,
                delimiter: filetype.delimiter,
            } ) ],
            { type: filetype.encoding },
        );
        const href = URL.createObjectURL( blob );
        const linkElement = Object.assign( document.createElement( 'a' ), {
            href,
            style: 'display: none;',
            download: filename.endsWith( `.${ filetype.extension }` )
                ? filename
                : `${ filename }.${ filetype.extension }`,
        } );

        // Emulate a click to download the file
        linkElement.click();

        // Free up memory
        URL.revokeObjectURL( href );
    },
};
