export default {
    /**
     * @public
     * Select all text in an input field
     * Note: setTimeout is a workaround for a safari bug
     * Doc: http://stackoverflow.com/questions/1269722/selecting-text-on-focus-using-jquery-not-working-in-safari-and-chrome
     * @param event { event }
     */
    selectInputFieldText( event ) {
        if ( event && event.target ) setTimeout( () => event.target.select(), 0 );
    },

    /**
     * @public
     * Tests if the param has a maximum of two digits after a decimal
     * Idea from: https://forums.asp.net/t/1846158.aspx?accept+input+only+number+with+2+decimal+javascript
     * @param testCandidate
     * @returns { Boolean }
     */
    isValidCurrencyAmount( testCandidate ) {
        const decimalRegex = /^\d+(?:\.\d{1,2})?$/;
        return decimalRegex.test( testCandidate );
    },

    /**
     * @public
     * Tests if the param is a whole number
     * @param testNumber { Number }
     * @returns { Boolean }
     */
    isWholeNumber( testNumber ) {
        return Number.isInteger( testNumber );
    },

    /**
     * @public
     * Generates a unique id by concatenating a
     * Purchase Order line item's id and a javascript timestamp
     * @param lineItem { Object }
     * @returns { String }
     */
    generateUidForPurchaseOrderLineItem( { id } ) {
        return `${ id }${ this.generateTimeStampJavaScript() }`;
    },

    /**
     * @public
     * Generate a JavaScript timestamp based on the current date and time
     * This is the number of milliseconds since the unix epoch
     * @returns { Number }
     */
    generateTimeStampJavaScript() {
        return ( new Date() ).getTime();
    },

    /**
     * * @public
     * Conditionally insert an attribute into an object
     * Inspired by: https://twitter.com/rubnvp/status/974397836723879937
     * @returns { Object }
     */
    insertIf( condition, object ) {
        return condition ? object : {};
    },
};
