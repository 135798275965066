import axios from './config/axios';
import api from './config/api';

export default {
    /**
     * @POST
     * Post a location to get a list of divisions belonging to that location
     * Docs: http://apps05.dev.tol.tech/swagger/#!/Title_Research_RWS_V1/getDivisionList
     */
    getDivisionListByLocation( { authToken, location } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/division/list' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( location ),
        } );
    },

    /**
     * @POST
     * Post a division to get a list of terms belonging to that division
     * Docs: http://apps05.dev.tol.tech/swagger/#!/Title_Research_RWS_V1/getTermList
     */
    getTermListByDivision( { authToken, division } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/term/list' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( division ),
        } );
    },

    /**
     * @POST
     * Post a term to get a list of departments belonging to that term
     * Docs: http://apps05.dev.tol.tech/swagger/#!/Title_Research_RWS_V1/getDepartmentList
     */
    getDepartmentListByTerm( { authToken, term } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/department/list' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( term ),
        } );
    },

    /**
     * @POST
     * Post a department to get a list of courses belonging to that department
     * Docs: http://apps05.dev.tol.tech/swagger/#!/Title_Research_RWS_V1/getCourseList
     */
    getCourseListByDepartment( { authToken, department } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/course/list' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( department ),
        } );
    },

    /**
     * @POST
     * Post a course to get a list of sections belonging to that course
     * Docs: http://apps05.dev.tol.tech/swagger/#!/Title_Research_RWS_V1/getSectionList
     */
    async getSectionListByCourse( { authToken, course } ) {
        try {
            return await axios( {
                url: api.cmaUrl( 'titleResearch/section/list' ),
                method: 'post',
                headers: api.authHeaders( authToken ),
                data: JSON.stringify( course ),
            } );
        } catch ( error ) {
            // If result is 404, return equivalent of 200 with empty result
            // Otherwise, pass error down
            if ( error?.response?.status === 404 && error?.response?.data?.message?.toLowerCase() === 'no sections found' ) {
                return {
                    data: {
                        sections: [],
                    },
                    status: 200,
                };
            }
            throw new Error( error );
        }
    },

    /**
     * @GET
     * Send a divisionId to get a list of department codes
     * The point of this is to skip the requirement to select a term in parts of the interface
     */
    getDepartmentListByDivision( { authToken, divisionId } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/departmentcodes/list' ),
            method: 'get',
            headers: api.authHeaders( authToken ),
            params: {
                divisionId,
            },
        } );
    },

    /**
     * @GET
     * Send a divisionId and departmentCode to get a list of courses
     * The point of this is to skip the requirement to select a term in parts of the interface
     */
    getCourseListByDivisionAndDepartment( { authToken, divisionId, departmentCode } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/coursecodes/list' ),
            method: 'get',
            headers: api.authHeaders( authToken ),
            params: {
                divisionId,
                departmentCode,
            },
        } );
    },
};
