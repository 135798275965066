// The order of these attributes matter, it determines the order
// in which they are displayed on the Purchase Order Search Page
export default {
    Draft: 'DRAFT',
    Submitted: 'SUBMITTED',
    Canceled: 'CANCELED',
    Complete: 'COMPLETE',

    // Different term allows for some change in frontend filtering,
    // but backend needs the same result
    Shipped: 'COMPLETE',
};

export const STATUS_LIST_COLUMNS = {
    Draft: 0,
    Submitted: 0,
    Canceled: 1,
    Complete: 1,
    Shipped: 1,
};
