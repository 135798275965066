export default {
    // https://stackoverflow.com/a/2706169
    addMonths( date, months ) {
        const d = date.getDate();
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Unary_plus
        date.setMonth( date.getMonth() + +months );
        // getDate returns the day of the month
        if ( date.getDate() !== d ) {
            date.setDate( 0 );
        }
        return date;
    },
    /**
     * Adds a number of days to a given Date() object
     * @param { Date } date
     * @param { Number } days
     * @returns { Date } - a new Date() object, so as to not modify the original
     */
    addDays( date, days ) {
        const futureTimestamp = date.getTime() + +days * 24 * 60 * 60 * 1000;
        return new Date( futureTimestamp );
    },

    /**
     * Simple Date Sort
     * Sorts Date strings by parsing into date object and subtracting
     * @param { String } a - A string that can be parsed to a date or date/time. e.g. 12/20/2012
     * @param { String } b - A different string that can be parsed to a date or date/time. e.g. 12/20/2012
     * @returns { number } - Positive if A is more recent than B, 0 if equal, Negative if B is more recent than A
     */
    sortDays( a, b ) {
        return Math.sign( new Date( a ) - new Date( b ) );
    },
};
