import Vue from 'vue';

// Api
import RRAApi from '@/api/RRAApi';

// Utils
import StoreUtil from '@/utils/StoreUtil';

export const actionTypes = {
    get: 'get',
    modifyEanItem: 'modifyEanItem',
    getOpenToBuyInfo: 'getOpenToBuyInfo',
    update: 'update',
    reset: 'reset',
};
export const mutationTypes = {
    SET_INFO: 'SET_INFO',
    SET_IN_FLIGHT: 'SET_IN_FLIGHT',
    MODIFY_EAN_ITEM: 'MODIFY_EAN_ITEM',
};
export const getterTypes = {
    id: 'id',
    hasInvalidQuantityToReturn: 'hasInvalidQuantityToReturn',
    indexOfCurrentItem: 'indexOfCurrentItem',
    canGoToNext: 'canGoToNext',
};

export default {
    namespaced: true,
    state: {
        inFlight: false,
        info: {
            /** @type { String } */
            isbn: '',
            /** @type { String } */
            condition: '',
            /** @type { String } */
            title: '',
            /** @type { Number } */
            quantityThisLocation: 0,
            /** @type { Number } */
            quantityAllLocations: 0,
            /** @type { String[] } */
            titleDesignators: [],
            /** @type { Object[] } */
            detailItems: [],
        },
    },
    getters: {
        /** @returns { String } */
        [ getterTypes.id ]: ( state ) => `${ state.info.isbn }${ state.info.condition }`,
        /** @returns { Boolean } */
        [ getterTypes.hasInvalidQuantityToReturn ]: ( state ) => (
            state.info.detailItems.some( ( item ) => item.quantityToReturn > item.quantityRemaining )
        ),
        /** @returns { Number } */
        [ getterTypes.indexOfCurrentItem ]: ( state, getters, rootState, rootGetters ) => (
            rootGetters[ 'RRA/Detail/indexOfInfoItem' ]( getters.id )
        ),
        /** @returns { Boolean } */
        [ getterTypes.canGoToNext ]: ( state, getters, rootState, rootGetters ) => (
            rootGetters[ 'RRA/Detail/includesInfoItemId' ]( getters.id )
            && getters.indexOfCurrentItem < ( rootState.RRA.Detail.tableRRALines.items.length - 1 )
        ),
    },
    actions: {
        [ actionTypes.reset ]( { commit } ) {
            commit( mutationTypes.SET_INFO, {
                info: {
                    isbn: '',
                    condition: '',
                    title: '',
                    quantityThisLocation: 0,
                    quantityAllLocations: 0,
                    titleDesignators: [],
                    detailItems: [],
                },
            } );
            commit( mutationTypes.SET_IN_FLIGHT, {
                inFlight: false,
            } );
        },

        async [ actionTypes.get ]( context, { ean, condition, rraId } ) {
            await context.dispatch( actionTypes.reset );
            context.commit( mutationTypes.SET_IN_FLIGHT, { inFlight: true } );
            try {
                const { data } = await RRAApi.getEanDetails( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    isbn: ean,
                    condition,
                    rraId,
                } );
                context.commit( mutationTypes.SET_INFO, { info: data } );
            } catch ( error ) {
                let errorMessage = StoreUtil.error.getMessage( error );
                if ( errorMessage.includes( 'checksum' ) ) {
                    errorMessage = `Invalid ISBN: ${ ean }`;
                }
                throw new Error( errorMessage );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { inFlight: false } );
            }
        },

        [ actionTypes.modifyEanItem ]( context, { eanDetailItem } ) {
            context.commit( mutationTypes.MODIFY_EAN_ITEM, {
                index: context.state.info.detailItems.findIndex( ( item ) => item.id === eanDetailItem.id ),
                item: eanDetailItem,
            } );
        },

        async [ actionTypes.getOpenToBuyInfo ]( { dispatch }, { productCode, allowSubscription } ) {
            return dispatch( 'PurchaseOrder/getOpenToBuyInfo', {
                productCode,
                allowSubscription,
            }, { root: true } );
        },

        async [ actionTypes.update ]( context ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { inFlight: true } );
            try {
                const { data } = await RRAApi.updateEanDetails( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    eanDetails: context.state.info,
                } );
                context.commit( mutationTypes.SET_INFO, { info: data } );
            } catch ( error ) {
                context.dispatch( 'setErrorNotification', `Failed to save changes: ${ StoreUtil.error.getMessage( error ) }`, { root: true } );
                throw new Error( StoreUtil.error.getMessage( error ) );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { inFlight: false } );
            }
        },
    },
    mutations: {
        [ mutationTypes.SET_INFO ]( state, { info } ) {
            state.info = info;
        },
        [ mutationTypes.SET_IN_FLIGHT ]( state, { inFlight } ) {
            state.inFlight = inFlight;
        },
        [ mutationTypes.MODIFY_EAN_ITEM ]( state, { index, item } ) {
            Vue.set(
                state.info.detailItems,
                index,
                item,
            );
        },
    },
};
