import axios from './config/axios';
import api from './config/api';

export default {
    /**
     * @POST
     * Post a list of catalog listings to 'autocomplete'
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Title_Research_RWS_V1/autoCompleteListings
     * @params payload { Object }
     * @returns { AxiosPromise }
     */
    autocompleteList( { authToken, catalogListingList } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/catalog/autocomplete' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( { catalogListings: catalogListingList } ),
        } );
    },

    /**
     * @POST
     * Post list of filters and get Catalog listings back
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Title_Research_RWS_V1/getTitleAndCatalogInfo
     * @params payload { Object }
     * @returns { AxiosPromise }
     */
    searchByFilters( { authToken, ...payload } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/title-research-info/search' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( payload ),
        } );
    },

    /**
     * @POST
     * Post a catalog listing to modify
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Title_Research_RWS_V1/modifyCatalogListing
     * @param catalogListings { Array }
     * @returns { AxiosPromise }
     */
    modifyList( { authToken, catalogListingList } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/catalog/modify' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( { catalogListings: catalogListingList } ),
        } );
    },
};
