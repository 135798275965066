// Utils
import StoreUtil from '@/utils/StoreUtil';

// Constants
import RRA_STATUS_LIST from '@/constants/rras/statusList';

import supplier from './supplier';
import location from './location';

export default {
    /**
     * Inbound RRA Search endpoint to state transformation
     * @param { RRADto } rraDto
     * @returns { RRA }
     */
    getRRAFromRRADto( rraDto ) {
        return {
            id: rraDto.rraId,
            number: rraDto.rraNumber,
            supplier: supplier.getSupplierFromSupplierDto( rraDto.supplier ),
            location: location.getLocationFromLocationDto( rraDto.location ),
            createdDate: rraDto.rraCreatedDate,
            status: rraDto.rraStatus,
            totalItems: rraDto.rraItemCount,
            totalValue: rraDto.rraTotalDollarValue,
            isTransactionComplete: rraDto.rraTransComplete,
            friendlyStatus: StoreUtil.status.getFriendlyName(
                RRA_STATUS_LIST,
                rraDto.rraStatus,
            ),
        };
    },
    /**
     * @param { RRA } rra
     * @returns { RRADto }
     */
    getRRADtoFromRRA( rra ) {
        return {
            rraNumber: rra.number,
            supplier: supplier.getSupplierDtoFromSupplier( rra.supplier ),
            location: location.getLocationDtoFromLocation( rra.location ),
            rraCreatedDate: rra.createdDate,
            rraStatus: rra.status,
            rraItemCount: rra.totalItems,
            rraTotalDollarValue: rra.totalValue,
            rraTransComplete: rra.isTransactionComplete,
        };
    },
    /**
     * @param { RRASearch } rraSearch
     * @returns { RRASearchDto }
     */
    getRRASearchDtoFromRRASearch( rraSearch ) {
        return {
            rraNumber: rraSearch.number,
            supplierId: rraSearch.supplierId,
            rraLocationId: rraSearch.locationId,
            rraCreatedDateStart: rraSearch.createStartDate,
            rraCreatedDateEnd: rraSearch.createEndDate,
            rraStatusList: rraSearch.statuses,
            isbn: rraSearch.ean,
            rraNotes: rraSearch.notes,
        };
    },
    /**
     * @param { RRADto } rraSearchDto
     * @returns { RRA }
     */
    getRRASearchFromRRASearchDto( rraSearchDto ) {
        return {
            number: rraSearchDto.rraNumber,
            supplierId: rraSearchDto.supplierId,
            locationId: rraSearchDto.rraLocationId,
            createStartDate: rraSearchDto.rraCreatedDateStart,
            createEndDate: rraSearchDto.rraCreatedDateEnd,
            statuses: rraSearchDto.rraStatusList,
            ean: rraSearchDto.isbn,
            notes: rraSearchDto.rraNotes,
        };
    },

    /**
     * @param { RRACreate } rraCreate
     * @returns { RRACreateInboundDto }
     */
    getRRACreateInboundDtofromRRACreate( rraCreate ) {
        return {
            supplierId: rraCreate.supplier?.id,
            locationId: rraCreate.location?.id,
            notes: rraCreate.notes,
        };
    },
    /**
     * @param { RRACreateOutboundDto } rraCreateOutboundDto
     * @returns { RRADetail }
     */
    getRRADetailFromRRACreateOutboundDto( rraCreateOutboundDto ) {
        return {
            id: rraCreateOutboundDto.id,
            number: rraCreateOutboundDto.internalDocumentNumber,
            location: location.getLocationFromLocationDto( rraCreateOutboundDto.location ),
            supplier: supplier.getSupplierFromSupplierDto( rraCreateOutboundDto.supplier ),
            notes: rraCreateOutboundDto.notes,
            createDate: rraCreateOutboundDto.createDate,
            status: rraCreateOutboundDto.status,
            items: rraCreateOutboundDto.items.map( this.getRRADetailLineItemFromRRADetailLineItemDto ),
        };
    },

    /**
     * @param { RRADetailDto } rraDetailDto
     * @returns { RRADetail }
     */
    getRRADetailFromRRADetailDto( rraDetailDto ) {
        return {
            id: rraDetailDto.id,
            number: rraDetailDto.rraNumber,
            location: location.getLocationFromLocationDto( rraDetailDto.location ),
            supplier: supplier.getSupplierFromSupplierDto( rraDetailDto.supplier ),
            notes: rraDetailDto.notes,
            createDate: rraDetailDto.createDate,
            status: rraDetailDto.status,
            items: rraDetailDto.items.map( this.getRRADetailLineItemFromRRADetailLineItemDto ),
            isTransactionComplete: rraDetailDto.transactionComplete,
        };
    },

    /**
     * @param { RRADetail } rraDetail
     * @returns { RRADetailDto }
     */
    getRRADetailDtoFromRRADetail( rraDetail ) {
        return {
            id: rraDetail.id,
            rraNumber: rraDetail.number,
            location: location.getLocationFromLocationDto( rraDetail.location ),
            supplier: supplier.getSupplierFromSupplierDto( rraDetail.supplier ),
            notes: rraDetail.notes,
            createDate: rraDetail.createDate,
            status: rraDetail.status,
            items: rraDetail.items.map( this.getRRADetailLineItemDtoFromRRADetailLineItem ),
            transactionComplete: rraDetail.isTransactionComplete,
        };
    },
    /**
     * @param { RRADetailLineItem } rraDetailLineItem
     * @returns { RRADetailLineItemDto }
     */
    getRRADetailLineItemDtoFromRRADetailLineItem( rraDetailLineItem ) {
        return {
            isbn: rraDetailLineItem.isbn,
            condition: rraDetailLineItem.condition,
            title: rraDetailLineItem.title,
            quantityReturned: rraDetailLineItem.quantityReturned,
            priceReturned: rraDetailLineItem.priceReturned,
            rma: rraDetailLineItem.rma,
            reason: rraDetailLineItem.reason,
            hasTitleDesignators: rraDetailLineItem.hasTitleDesignators,
        };
    },
    /**
     * @param { RRADetailLineItemDto } rraDetailLineItemDto
     * @returns { RRADetailLineItem }
     */
    getRRADetailLineItemFromRRADetailLineItemDto( rraDetailLineItemDto ) {
        return {
            id: `${ rraDetailLineItemDto.isbn }${ rraDetailLineItemDto.condition }`,
            isbn: rraDetailLineItemDto.isbn,
            condition: rraDetailLineItemDto.condition,
            title: rraDetailLineItemDto.title,
            quantityReturned: rraDetailLineItemDto.quantityReturned,
            priceReturned: rraDetailLineItemDto.priceReturned,
            rma: rraDetailLineItemDto.rma,
            reason: rraDetailLineItemDto.reason,
            hasTitleDesignators: rraDetailLineItemDto.hasTitleDesignators,
        };
    },

    /**
     * DO NOT CHANGE THE ORDER OF THESE ATTRIBUTES OR IT WILL BREAK THE EXPORTABLE CSV
     * @param { RRAExportableLineItem } lineItem
     * @returns { RRAExportableLineItem }
     */
    getExportableLineItemsFromDto( lineItem ) {
        return {
            itemId: lineItem.itemId,
            isbn: lineItem.isbn,
            designators: lineItem.designators,
            // this 'mis-match' is intentional, on the invoice detail page we show the supplierDocumentNumber as the 'Invoice Number'
            invoiceNumber: lineItem.supplierDocumentNumber,
            invoiceDate: lineItem.invoiceDate,
            poNumber: lineItem.poNumber,
            poDate: lineItem.poDate,
            returnQuantity: lineItem.returnQuantity,
            condition: lineItem.condition,
            returnReason: lineItem.reason,
            rmaNumber: lineItem.rma,
            cost: lineItem.cost,
            extendedPrice: lineItem.extendedPrice,
        };
    },

    /**
     * Inbound eanDetails endpoint to state transformation
     */
    getRRAEanDetailFromRRAEanDetailInboundDto( rraEanDetailInboundDto ) {
        return {
            /** @type { Number } */
            rraId: rraEanDetailInboundDto.rraId,
            /** @type { String } */
            isbn: rraEanDetailInboundDto.isbn,
            /** @type { String } */
            condition: rraEanDetailInboundDto.condition,
            /** @type { String } */
            title: rraEanDetailInboundDto.title,
            /** @type { Number } */
            quantityThisLocation: rraEanDetailInboundDto.qtyThisLocation || 0,
            /** @type { Number } */
            quantityAllLocations: rraEanDetailInboundDto.qtyAllLocations || 0,
            /** @type { String[] } */
            titleDesignators: rraEanDetailInboundDto.titleDesignators,
            /** @type { Object[] } */
            detailItems: rraEanDetailInboundDto.detailItems.map( this.getRRAEanDetailItemFromRRAEanDetailItemInboundDto ),
        };
    },
    /**
     * Inbound eanDetail's items endpoint to state transformation
     */
    getRRAEanDetailItemFromRRAEanDetailItemInboundDto( rraEanDetailItemsInboundDto, index ) {
        return {
            /**
             * We have to add a 'unique' id b/c there's "nothing unique that can be used as an id" on the backend
             * Can't rely on rraiId b/c it can be null if there's no existing relationship between it and an RRA
             * @type { Number }
             */
            id: index,
            /** @type { Number | Null } */
            rraItemId: rraEanDetailItemsInboundDto.rraiId,
            /** @type { Number | Null } - will be null if no related invoice */
            invoiceId: rraEanDetailItemsInboundDto.invoiceId,
            /** @type { Number | Null } - will be null if invoice is NOT reconciled */
            invoiceItemId: rraEanDetailItemsInboundDto.invoiceItemId,
            /** @type { String } */
            invoiceNumber: rraEanDetailItemsInboundDto.invoiceNumber,
            /** @type { Number } - unix epoch */
            invoiceDate: rraEanDetailItemsInboundDto.invoiceDate,
            /** @type { Number | Null } - will be null if no related purchase order */
            purchaseOrderId: rraEanDetailItemsInboundDto.poId,
            /** @type { String } */
            purchaseOrderNumber: rraEanDetailItemsInboundDto.poNumber,
            /** @type { Number } - unix epoch */
            purchaseOrderDate: rraEanDetailItemsInboundDto.poDate,
            /** @type { Number } - unix epoch */
            returnDeadline: rraEanDetailItemsInboundDto.returnDeadline,
            /** @type { Number } */
            daysRemaining: rraEanDetailItemsInboundDto.daysRemaining || 0,
            /** @type { Number } */
            quantityInvoiced: rraEanDetailItemsInboundDto.quantityInvoiced || 0,
            /** @type { Number } */
            quantityReturned: rraEanDetailItemsInboundDto.quantityReturned || 0,
            /** @type { Number } */
            quantityToReturn: rraEanDetailItemsInboundDto.quantityToReturn || 0,
            /** @type { Number } */
            cost: rraEanDetailItemsInboundDto.cost || 0,
            /** @type { String } */
            reason: rraEanDetailItemsInboundDto.reason || '',
            /** @type { String } */
            rma: rraEanDetailItemsInboundDto.rma || '',
            /** @type { String } */
            supplierDocumentNumber: rraEanDetailItemsInboundDto.supplierDocumentNumber,

            // Values calculated client-side
            /** @type { Number } - fallback to 0 if calculated quantityRemaining is less than 0 */
            quantityRemaining: Math.max( ( +rraEanDetailItemsInboundDto.quantityInvoiced - +rraEanDetailItemsInboundDto.quantityReturned ), 0 ),
            /** @type { Number } */
            extendedCost: ( +rraEanDetailItemsInboundDto.quantityToReturn * +rraEanDetailItemsInboundDto.cost ) || 0,
        };
    },
    /**
     * Outbound eanDetails state to endpoint transformation
     */
    getRRAEanDetailDtoFromOutboundEanDetails( eanDetails ) {
        return {
            /** @type { Number } */
            rraId: eanDetails.rraId,
            /** @type { String } */
            isbn: eanDetails.isbn,
            /** @type { String } */
            condition: eanDetails.condition,
            /** @type { String } */
            title: eanDetails.title,
            /** @type { Number } */
            qtyThisLocation: eanDetails.quantityThisLocation,
            /** @type { Number } */
            qtyAllLocations: eanDetails.quantityAllLocations,
            /** @type { String[] } */
            titleDesignators: eanDetails.titleDesignators,
            /** @type { Object[] } */
            detailItems: eanDetails.detailItems.map( this.getRRAEanDetailItemDtoFromOutboundEanDetailItems ),
        };
    },
    /**
     * Outbound eanDetails state to endpoint transformation
     */
    getRRAEanDetailItemDtoFromOutboundEanDetailItems( eanDetailItem ) {
        return {
            /** @type { Number } */
            rraiId: eanDetailItem.rraItemId,
            /** @type { Number } */
            invoiceItemId: eanDetailItem.invoiceItemId,
            /** @type { String } */
            invoiceNumber: eanDetailItem.invoiceNumber,
            /** @type { String } - YYYY-MM-DD */
            invoiceDate: eanDetailItem.invoiceDate,
            /** @type { String } */
            poNumber: eanDetailItem.purchaseOrderNumber,
            /** @type { String } - YYYY-MM-DD */
            poDate: eanDetailItem.purchaseOrderDate,
            /** @type { String } - YYYY-MM-DD */
            returnDeadline: eanDetailItem.returnDeadline,
            /** @type { Number } */
            daysRemaining: eanDetailItem.daysRemaining,
            /** @type { Number } */
            quantityInvoiced: eanDetailItem.quantityInvoiced,
            /** @type { Number } */
            quantityReturned: eanDetailItem.quantityReturned,
            /** @type { Number } */
            quantityToReturn: eanDetailItem.quantityToReturn,
            /** @type { Number } */
            cost: eanDetailItem.cost,
            /** @type { String } */
            rma: eanDetailItem.rma,
            /** @type { String } */
            reason: eanDetailItem.reason,
        };
    },
};
