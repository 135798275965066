const mutationTypes = {
    SET_DRAWER: 'SET_DRAWER',
};

const actionTypes = {
    toggleDrawer: 'toggleDrawer',
};
export default {
    namespaced: true,
    state: {
        /** @type { Boolean } */
        drawer: false,
    },
    mutations: {
        [ mutationTypes.SET_DRAWER ]( state, { drawer } ) {
            state.drawer = drawer;
        },
    },
    actions: {
        [ actionTypes.toggleDrawer ]( { state, commit } ) {
            commit( mutationTypes.SET_DRAWER, { drawer: !state.drawer } );
        },
    },
};
