import AutoGen from './AutoGen';
import Search from './Search';
import Create from './Create';
import Detail from './Detail';

export default {
    AutoGen,
    Search,
    Create,
    Detail,
};
