// Api
import TitleApi from '@/api/TitleApi';

// Utils
import ValidationUtil from '@/utils/ValidationUtil';

import modules from './modules';

const mutationTypes = {
    SET_IN_FLIGHT: 'SET_IN_FLIGHT',
};

export default {
    namespaced: true,
    modules,
    state: {
        /** @type { Boolean } */
        inFlight: false,
    },
    actions: {
        /**
         * Modify a given Title
         */
        async modifyTitle( context, { title } ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                if ( Object.keys( title ).includes( 'edition' ) && !ValidationUtil.isOnlyDigits( title.edition ) ) {
                    throw new Error( 'Edition must be a number' );
                }

                await TitleApi.postModifyTitle( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    title,
                } );

                await context.dispatch( 'setCurrentTitle', {
                    title: {
                        ...context.rootState.currentTitle,
                        ...title,
                    },
                }, { root: true } );

                await context.dispatch( 'setSuccessNotification', `Successfully modified ${ title.productCode }`, { root: true } );
            } catch ( error ) {
                await context.dispatch(
                    'setErrorNotification',
                    `Failed to modify a title - ${ error?.response?.data?.message ?? error.message }`,
                    { root: true },
                );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },
    },
    mutations: {
        [ mutationTypes.SET_IN_FLIGHT ]( state, { boolean } ) {
            state.inFlight = boolean;
        },
    },
};
