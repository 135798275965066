import VCurrencyField from 'v-currency-field';
import Vue from 'vue';

Vue.use( VCurrencyField, {
    locale: 'en',
    decimalLength: 2,
    autoDecimalMode: true,
    min: null,
    max: null,
    defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true,
} );
