import RRAApi from '@/api/RRAApi';

import StoreUtil from '@/utils/StoreUtil';

export const actionTypes = {
    createRRA: 'createRRA',
};

export const mutationTypes = {
    SET_LOCATION: 'SET_LOCATION',
    SET_SUPPLIER: 'SET_SUPPLIER',
    SET_NOTES: 'SET_NOTES',
    SET_IN_FLIGHT: 'SET_IN_FLIGHT',
    RESET: 'RESET',
};

export default {
    namespaced: true,
    state: {
        inFlight: false,
        pendingRRA: {
            location: {},
            supplier: {},
            notes: '',
        },
    },
    getters: {
        isReadyToSubmit: ( { pendingRRA } ) => !!( pendingRRA.location?.id && pendingRRA.supplier?.id ),
    },
    actions: {
        async [ actionTypes.createRRA ]( {
            state, commit, dispatch, rootGetters,
        } ) {
            try {
                const { data } = await RRAApi.createRRA( {
                    authToken: rootGetters[ 'User/authString' ],
                    ...state.pendingRRA,
                } );
                commit( mutationTypes.RESET );
                return data.rraOutbound;
            } catch ( e ) {
                const message = `Unable to Create RRA: ${ StoreUtil.error.getMessage( e ) }`;
                dispatch( 'setErrorNotification', message, { root: true } );
                return {};
            }
        },
    },
    mutations: {
        [ mutationTypes.SET_LOCATION ]( state, { location } ) {
            state.pendingRRA.location = location;
        },
        [ mutationTypes.SET_SUPPLIER ]( state, { supplier } ) {
            state.pendingRRA.supplier = supplier;
        },
        [ mutationTypes.SET_NOTES ]( state, { notes } ) {
            state.pendingRRA.notes = notes;
        },
        [ mutationTypes.SET_IN_FLIGHT ]( state, { inFlight } ) {
            state.inFlight = inFlight;
        },
        [ mutationTypes.RESET ]( state ) {
            state.pendingRRA = {
                location: {},
                supplier: {},
                notes: '',
            };
        },
    },
};
