// Api Config
import axios from './config/axios';
import api from './config/api';

// Mapper
import PurchaseOrderMapper from './mappers/purchaseOrder';

export default {
    /**
     * @POST
     * Auto generate Purchase Orders
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Sourcing_RWS_V1/generatePurchaseOrders
     * @returns { Promise }
     */
    autoGenPurchaseOrders( { authToken, ...payload } ) {
        return axios( {
            url: api.cmaUrl( 'sourcing/purchaseOrder/autogen' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( {
                /** @type String */
                // shelfDateStart: payload.shelfDateStart,
                /** @type String */
                // shelfDateEnd: payload.shelfDateEnd,
                /** @type String */
                sourcingEndDateStart: payload.sourcingEndDateStart,
                /** @type String */
                sourcingEndDateEnd: payload.sourcingEndDateEnd,
                /** @type Array */
                // catalogFilters: payload.catalogFilters,
                /** @type Boolean */
                previewOnly: payload.previewOnly,
                /** @type String */
                // effectiveDateStart: payload.effectiveStartDate,
                /** @type String */
                // effectiveDateEnd: payload.effectiveEndDate,
                /** @type Array */
                includeSupplierList: payload.includeSupplierList,
                /** @type Array */
                excludeSupplierList: payload.excludeSupplierList,
                /** @type Array */
                titleIncludeDesignatorList: payload.titleIncludeDesignatorList,
                /** @type Array */
                titleExcludeDesignatorList: payload.titleExcludeDesignatorList,
                /** @type Boolean */
                addToDraft: payload.addToDraft,
                /** @type Number */
                openToBuyPercent: payload.openToBuyPercent,
                /** @type String */
                expectedReceiveDate: payload.expectedReceiveDate,
                /** @type String */
                overridePOPrefix: payload.overridePOPrefix,
                /** @type String */
                note: payload.note,
                /** @type String */
                overrideEmail: payload.overrideEmail,
                /** @type Boolean */
                doNotGenerateIfAvailableDigitally: payload.doNotGenerateIfAvailableDigitally,
                /** @type Number */
                daysOutSkipDigitalAvailabilityGeneration: payload.daysOutSkipDigitalAvailabilityGeneration,
                /** @type Number */
                minimumPOQuantity: payload.minimumPOQuantity,
                /** @type Number */
                minimumLineQuantity: payload.minimumLineQuantity,
            } ),
        } );
    },

    /**
     * @POST
     * Create a new Purchase Order
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Sourcing_RWS_V1/createNewPO
     * @returns { Promise }
     */
    createPurchaseOrder( { authToken, ...payload } ) {
        return axios( {
            url: api.cmaUrl( 'sourcing/purchaseOrder/createPO' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( {
                /** @type String */
                number: '',
                /** @type Object */
                supplier: payload.supplier,
                /** @type String */
                description: payload.description,
                /** @type String */
                status: 'DRAFT',
                /** @type String */
                createDate: '',
                /** @type String */
                transmitDate: '',
                /** @type String */
                expectedReceiveDate: payload.expectedReceiveDate || '',
                /** @type String */
                trackingNumbers: '',
                /** @type String */
                supplierNote: '',
                /** @type Number */
                shippingCost: 0,
                /** @type Boolean */
                useCreditCard: false,
                /** @type Number */
                fees: 0,
                /** @type String */
                prefixOverride: payload.prefixOverride || '',
                /** @type Object */
                location: payload.location,
            } ),
        } );
    },

    /**
     * @POST
     * Modify an array of Purchase Orders
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Sourcing_RWS_V1/modifyPurchaseOrderDetail
     * @returns { Promise }
     */
    modifyPurchaseOrder( { authToken, purchaseOrderList } ) {
        return axios( {
            url: api.cmaUrl( 'sourcing/purchaseOrder/modify' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( { modifyPurchaseOrders: purchaseOrderList } ),
        } );
    },

    /**
     * @POST
     * Move Purchase Order line items
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Sourcing_RWS_V1/movePurchaseOrderItems
     * @returns { Promise }
     */
    movePurchaseOrderItems( {
        authToken,
        fromPurchaseOrderNumber,
        toPurchaseOrderNumber,
        itemIDList,
    } ) {
        return axios( {
            url: api.cmaUrl( 'sourcing/purchaseOrder/item/move' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( {
                fromPurchaseOrderNumber,
                toPurchaseOrderNumber,
                itemIDList,
            } ),
        } );
    },

    /**
     * @POST
     * Modify a Purchase Order
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Sourcing_RWS_V1/getPurchaseOrderInfo
     * @returns { Promise }
     */
    postFiltersToGetPurchaseOrderList( { authToken, ...payload } ) {
        return axios( {
            url: api.cmaUrl( 'sourcing/purchaseOrder/searchPOs' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( {
                /** @type String */
                purchaseOrderNumber: payload.purchaseOrderNumber,
                /** @type String */
                expectedReceiveStartDate: payload.expectedReceiveStartDate,
                /** @type String */
                expectedReceiveEndDate: payload.expectedReceiveEndDate,
                /** @type String */
                createStartDate: payload.createStartDate,
                /** @type String */
                createEndDate: payload.createEndDate,
                /** @type String */
                transmitStartDate: payload.transmitStartDate,
                /** @type String */
                transmitEndDate: payload.transmitEndDate,
                /** @type Number */
                locationId: payload.locationId,
                /** @type String */
                productCode: payload.productCode,
                /** @type Number */
                supplierId: payload.supplierId,
                /** @type Array */
                sourcingDocStatusList: payload.sourcingDocStatusList,
                /** @type String */
                description: payload.description,
            } ),
        } );
    },

    /**
     * @POST
     * Print Purchase Orders
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Sourcing_RWS_V1/getPOsToPrint
     * @returns { Promise }
     */
    printPurchaseOrders( { authToken, purchaseOrderList } ) {
        return axios( {
            url: api.cmaUrl( 'sourcing/purchaseOrder/printpos' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( { purchase_order: purchaseOrderList } ),
        } );
    },

    /**
     * @POST
     * Get OTB results
     * Docs: https://apps02.dev.tol.tech/swagger/#!/Sourcing_RWS_V1/hickoryOpenToBuy
     * @param { Object }
     */
    async retrieveOpenToBuyList( {
        authToken,
        productCode,
        allowSubscription = false,
    } ) {
        const response = await axios( {
            url: api.cmaUrl( 'sourcing/otb/hickoryOpenToBuy' ),
            method: 'get',
            headers: api.authHeaders( authToken ),
            params: {
                productCode,
                allowSubscription,
            },
        } );
        return {
            ...response,
            data: response.data.map( PurchaseOrderMapper.openToBuyInboundFormat ),
        };
    },

    /**
     * @GET
     * Get Purchase Order Multi-Create Template
     */
    getMultiCreateTemplate( { authToken } ) {
        return axios( {
            url: api.cmaUrl( 'sourcing/purchaseOrder/template' ),
            method: 'get',
            headers: {
                ...api.authHeaders( authToken ),
                Accept: 'application/octet-stream',
            },
        } );
    },

    /**
     * @POST
     * Process a TSV file from getMultiCreateTemplate
     * @param { Object }
     * @param { String } authToken
     * @param { String } file
     * @param { Boolean } addToDraft
     * @param { Boolean } previewOnly
     */
    uploadMultiCreateTemplate( {
        authToken,
        file,
        addToDraft = false,
        previewOnly = false,
    } ) {
        return axios( {
            url: api.cmaUrl( 'sourcing/purchaseOrder/import' ),
            method: 'post',
            headers: {
                ...api.authHeaders( authToken ),
                'Content-Type': 'text/csv',
            },
            data: file,
            params: {
                addToDraft,
                previewOnly,
            },
        } );
    },
};
