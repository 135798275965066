// Api
import RRAApi from '@/api/RRAApi';

// Utils
import CsvUtil from '@/utils/CsvUtil';
import FormatUtil from '@/utils/FormatUtil';
import StoreUtil from '@/utils/StoreUtil';

// Constants
import RRA_STATUS_LIST from '@/constants/rras/statusList';

// Modules
import modules from './modules';

export const actionTypes = {
    get: 'get',
    update: 'update',

    getExportableLines: 'getExportableLines',
    exportLinesAsCsv: 'exportLinesAsCsv',
    export: 'export',

    setRMADetails: 'setRMADetails',
    populateSearchWithRRA: 'populateSearchWithRRA',

    reset: 'reset',
};
export const mutationTypes = {
    SET_IN_FLIGHT: 'SET_IN_FLIGHT',
    SET_IS_DIRTY: 'SET_IS_DIRTY',
    SET_RRA_INFO: 'SET_RRA_INFO',
    SET_RRA_INFO_ITEMS: 'SET_RRA_INFO_ITEMS',

    SET_NOTES: 'SET_NOTES',
    SET_STATUS: 'SET_STATUS',

    SET_RRA_ITEM: 'SET_RRA_ITEM',

    SET_TABLE_SORT_BY: 'SET_TABLE_SORT_BY',
    SET_TABLE_SORT_DESC: 'SET_TABLE_SORT_DESC',
    SET_TABLE_ITEMS: 'SET_TABLE_ITEMS',
};

export const csvHeaders = [
    'RRA Item Id',
    'ISBN',
    'Designators',
    'Inv Number',
    'Inv Date',
    'PO Number',
    'PO Date',
    'Return Qty',
    'Condition',
    'Return Reason',
    'RMA Number',
    'Unit Cost',
    'Extended Cost',
];

export default {
    namespaced: true,
    modules,
    state: {
        /** @type { Boolean } */
        inFlight: false,
        /** @type { Boolean } */
        isDirty: false,
        /** @type { RRADetail } */
        info: {
            id: 0,
            number: '',
            location: {},
            supplier: {},
            notes: '',
            createDate: '',
            status: 'DRAFT',
            items: [],
            isTransactionComplete: false,
        },
        tableRRALines: {
            sortBy: [ 'isbn' ],
            sortDesc: [ false ],
            items: [],
        },
    },
    getters: {
        /** @returns { Boolean } */
        hasInfo: ( state ) => ( state.info.id !== 0 ),

        /** @returns { Number } */
        totalLines: ( state ) => state.info.items.length,
        /** @returns { Number } */
        totalUnits: ( state, getters ) => (
            getters.totalLines > 0
                ? state.info.items
                    .map( ( item ) => +item.quantityReturned )
                    .reduce( ( accumulator, currentValue ) => accumulator + currentValue )
                : 0
        ),
        /** @returns { Number } */
        totalValue: ( state, getters ) => (
            getters.totalLines > 0
                ? FormatUtil.toCurrency(
                    state.info.items
                        .map( ( item ) => +item.priceReturned )
                        .reduce( ( accumulator, value ) => accumulator + value ),
                )
                : 0
        ),

        /** @returns { Number } */
        indexOfCurrentRRA: ( state, getters, rootState, rootGetters ) => (
            rootGetters[ 'RRA/Search/indexOfId' ]( state.info.id )
        ),
        /** @returns { Number } */
        indexOfCurrentTableRRA: ( state, getters, rootState, rootGetters ) => (
            rootGetters[ 'RRA/Search/indexOfTableId' ]( state.info.id )
        ),
        /** @returns { Boolean } */
        canGoToNext: ( state, getters, rootState, rootGetters ) => (
            rootGetters[ 'RRA/Search/includesTableId' ]( state.info.id )
            && getters.indexOfCurrentTableRRA < ( rootState.RRA.Search.table.rraList.length - 1 )
        ),

        /** @returns { Boolean } */
        isStatusCanceled: ( state ) => ( state.info.status === 'CANCELED' ),
        /** @returns { Boolean } */
        isStatusComplete: ( state ) => ( state.info.status === 'COMPLETE' ),
        /** @returns { Boolean } */
        isStatusCanceledOrComplete: ( state, getters ) => ( getters.isStatusCanceled || getters.isStatusComplete ),
        /** @returns { Boolean } */
        isDisabled: ( state, getters ) => (
            getters.isStatusComplete
            && state.info.isTransactionComplete
        ),

        /** @returns { Boolean } */
        hasRRAItems: ( state ) => state.tableRRALines?.length > 0,
        /** @returns { Function } -> Number */
        indexOfInfoItem: ( state ) => ( itemId ) => (
            state.tableRRALines.items.findIndex( ( item ) => item.id === itemId )
        ),
        /** @returns { Function } -> Boolean */
        includesInfoItemId: ( state ) => ( itemId ) => (
            state.tableRRALines.items
                .map( ( item ) => item.id )
                .includes( itemId )
        ),
    },
    actions: {
        [ actionTypes.reset ]( { commit } ) {
            commit( mutationTypes.SET_RRA_INFO, {
                info: {
                    id: 0,
                    number: '',
                    location: {},
                    supplier: {},
                    notes: '',
                    createDate: '',
                    status: 'DRAFT',
                    items: [],
                    isTransactionComplete: false,
                },
            } );
            commit( mutationTypes.SET_IN_FLIGHT, { inFlight: false } );
            commit( mutationTypes.SET_TABLE_SORT_BY, { sortBy: [ 'isbn' ] } );
            commit( mutationTypes.SET_TABLE_SORT_DESC, { sortDesc: [ false ] } );
            commit( mutationTypes.SET_TABLE_ITEMS, { items: [] } );
        },

        async [ actionTypes.get ]( context, { id } ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { inFlight: true } );
            try {
                const { data } = await RRAApi.getRRA( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    id,
                } );
                context.commit( mutationTypes.SET_RRA_INFO, { info: data.rraDetail } );
                await context.dispatch( actionTypes.populateSearchWithRRA );
            } catch ( error ) {
                throw new Error( error.message );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { inFlight: false } );
            }
        },

        async [ actionTypes.update ]( context, { itemsToBeRemoved = [] } = {} ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { inFlight: true } );
            try {
                const itemsToKeep = context.state.info.items.filter( ( item ) => (
                    !itemsToBeRemoved
                        .map( ( removed ) => removed.id )
                        .includes( item.id )
                ) );
                const { data } = await RRAApi.updateRRA( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    outboundRRA: {
                        ...context.state.info,
                        // filter out items in itemsToBeRemoved
                        items: itemsToKeep,
                    },
                } );

                // Update this module's RRA Info
                context.commit( mutationTypes.SET_RRA_INFO, { info: data.rraDetail } );

                // Update the RRA in the Search result list
                await context.dispatch( 'RRA/Search/updateItemInList', {
                    item: {
                        createdDate: data.rraDetail.createDate,
                        friendlyStatus: StoreUtil.status.getFriendlyName(
                            RRA_STATUS_LIST,
                            data.rraDetail.status,
                        ),
                        id: data.rraDetail.id,
                        isTransactionComplete: data.rraDetail.isTransactionComplete,
                        location: data.rraDetail.location,
                        number: data.rraDetail.number,
                        status: data.rraDetail.status,
                        supplier: data.rraDetail.supplier,
                        totalItems: data.rraDetail.items
                            .map( ( item ) => +item.quantityReturned )
                            .reduce( ( acc, curr ) => +acc + +curr, 0 ),
                        totalValue: FormatUtil.toCurrency(
                            data.rraDetail.items
                                .map( ( item ) => +item.priceReturned )
                                .reduce( ( acc, curr ) => +acc + +curr, 0 ),
                        ),
                    },
                }, { root: true } );

                // We're all clean now that we've saved
                context.commit( 'SET_IS_DIRTY', { isDirty: false } );

                // Sound the trumpets!
                context.dispatch( 'setSuccessNotification', `Successfully updated ${ context.state.info.number }`, { root: true } );
            } catch ( error ) {
                throw new Error( error.message );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { inFlight: false } );
            }
        },

        async [ actionTypes.getExportableLines ]( context ) {
            try {
                const { data } = await RRAApi.getExportableLines( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    rraId: context.state.info.id,
                } );
                return data.rraItemExportItemDTOList;
            } catch ( error ) {
                throw new Error( error.message );
            }
        },
        [ actionTypes.exportLinesAsCsv ]( context, lines ) {
            CsvUtil.create( {
                filename: `${ context.state.info.number }_export`,
                headers: csvHeaders,
                data: lines,
            } );
        },
        async [ actionTypes.export ]( context ) {
            const lines = await context.dispatch( actionTypes.getExportableLines );
            await context.dispatch( actionTypes.exportLinesAsCsv, lines );
        },

        async [ actionTypes.setRMADetails ]( context, { rmaNumber, reason, selected } ) {
            await Promise.all( selected.map( ( item ) => context.commit( mutationTypes.SET_RRA_ITEM, {
                id: item.id,
                rma: rmaNumber,
                reason,
            } ) ) );
        },
        async [ actionTypes.populateSearchWithRRA ]( context ) {
            if ( !context.rootGetters[ 'RRA/Search/includesId' ]( context.state.info.id ) && ( context.rootGetters[ 'RRA/Search/hasFilters' ] === false ) ) {
                await context.dispatch( 'RRA/Search/setFilterNumber', { number: context.state.info.number }, { root: true } );
                await context.dispatch( 'RRA/Search/searchRRAs', {}, { root: true } );
            }
        },
    },
    mutations: {
        [ mutationTypes.SET_IN_FLIGHT ]( state, { inFlight } ) {
            state.inFlight = inFlight;
        },
        [ mutationTypes.SET_IS_DIRTY ]( state, { isDirty } ) {
            state.isDirty = isDirty;
        },
        [ mutationTypes.SET_RRA_INFO ]( state, { info } ) {
            state.info = info;
        },
        [ mutationTypes.SET_NOTES ]( state, { notes } ) {
            state.info.notes = notes;
        },
        [ mutationTypes.SET_STATUS ]( state, { status } ) {
            state.info.status = status;
        },
        [ mutationTypes.SET_RRA_ITEM ]( state, rraItem ) {
            const indexToChange = state.info.items.findIndex( ( item ) => item.id === rraItem.id );
            state.info.items[ indexToChange ] = {
                ...state.info.items[ indexToChange ],
                ...rraItem,
            };
        },
        [ mutationTypes.SET_RRA_INFO_ITEMS ]( state, { items } ) {
            state.info.items = items;
        },
        [ mutationTypes.SET_TABLE_SORT_BY ]( state, { sortBy } ) {
            state.tableRRALines.sortBy = sortBy;
        },
        [ mutationTypes.SET_TABLE_SORT_DESC ]( state, { sortDesc } ) {
            state.tableRRALines.sortDesc = sortDesc;
        },
        [ mutationTypes.SET_TABLE_ITEMS ]( state, { items } ) {
            state.tableRRALines.items = items;
        },
    },
};
