import axios from 'axios';

// Global Axios Config
// axios.defaults.baseURL = process.env.API_URL;

axios.defaults.headers.get = {
    Accept: 'application/json',
};

axios.defaults.headers.post = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

export default axios;
