export default {
    path: '/group',
    name: 'TitleGroupSearch',
    meta: {
        title: 'Search',
        requiresAuth: true,
    },
    props: ( route ) => ( { query: route.query.q } ),
    component: () => import( /* webpackChunkName: "TitleGroupSearch" */ '@/views/TitleGroup/TitleGroupSearch' ),
};
