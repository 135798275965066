import ObjectUtil from './ObjectUtil';

export default {
    /**
     * TODO: Beef this up OR import a library that validates ISBNs
     * Returns true if value is numeric (with or without dashes)
     * @param { String | Number } value
     * @returns { Boolean }
     */
    isValidIsbn: ( value ) => /^[\d|-]+$/.test( value ),

    /**
     * @param { String | Number } value
     * @returns { Boolean }
     */
    isOnlyDigits: ( value ) => /^\d+$/.test( value ),

    /**
     * @param { Any } value
     * @returns { Boolean }
     */
    required: ( value ) => !!value,

    /**
     * @param { Object } value
     * @returns { Boolean }
     */
    requiredObject: ( value ) => ObjectUtil.isNotEmpty( value ),

    /**
     * @param { Array } value
     * @returns { Boolean }
     */
    requiredArray: ( value ) => (
        value != null
        && Array.isArray( value )
        && value.length > 0
    ),

    /**
     * Returns true if the provided value is in one of the following formats:
     * @example test@test.com
     * @example test@test.com;
     * @example test@test.com; test@whatever.co
     * @example test@test.com; test@whatever.co;
     * @param { String } value
     * @returns { Boolean }
     */
    isSemicolonDelimitedEmailList( value ) {
        if ( typeof value !== 'string' ) {
            return false;
        }
        const match = value.match( /^(([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25}(($)|( *;+ *$)|( *;+ *(?=[a-zA-Z0-9_\-.]))))*$/ );
        // match will be null if value doesn't match the regex
        return !!match
            && match.length > 0;
    },
};
