import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { VTextField } from 'vuetify/lib'; // Globally import VTextField

Vue.component( 'v-text-field', VTextField );

Vue.use( Vuetify );

export default new Vuetify( {
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#001E62',
                secondary: '#a4c351',
            },
        },
    },
} );
