import axios from './config/axios';
import api from './config/api';

export default {
    /**
     * @GET
     * Get the list of Messaging Statuses to use to search for an adoption
     */
    getMessagingStatusList( { authToken } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/adoption/messaging_status/list' ),
            method: 'get',
            headers: api.authHeaders( authToken ),
        } );
    },

    /**
     * @POST
     * Get Adoptions
     */
    getAdoptionsByFilters( { authToken, payload } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/adoption/search' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: {
                ...payload,
            },
        } );
    },

    /**
     * @POST
     * Get a list of Catalog Listings affected by this material
     */
    getCatalogListingsByAdoption( { authToken, payload } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/adoption/material/listings' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( {
                adoptionMaterialId: payload.adoptionMaterialId,
                divisionName: payload.divisionName,
                divisionId: payload.divisionId,
                locationId: payload.locationId,
                departmentCode: payload.departmentCode,
                courseCode: payload.courseCode,
                instructor: payload.instructor,
                instructorId: payload.instructorId,
                sectionCode: payload.sectionCode,
                sectionId: payload.sectionId,
                materialLink: payload.materialLink,
            } ),
        } );
    },

    /**
     * @GET
     * Get the Messaging Status for a given catalogListingId
     */
    getCatalogListingMessagingStatus( { authToken, catalogListingId } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/adoption/catalog_listing/messaging_status' ),
            method: 'get',
            headers: api.authHeaders( authToken ),
            params: {
                catalogListingId,
            },
        } );
    },

    /**
     * @POST
     * Get a list of Catalog Listings affected by this material
     */
    getRelatedCatalogListingsByAdoptionId( { authToken, adoptionMaterialId } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/adoption/material/related-listings' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: {
                adoptionMaterialId,
            },
        } );
    },
};
