// Api
import PurchaseOrderApi from '@/api/PurchaseOrderApi';

// Utils
import CsvUtil, { FILE_TYPES } from '@/utils/CsvUtil';

const actionTypes = {
    getTemplate: 'getTemplate',
    uploadTemplate: 'uploadTemplate',
    clearErrors: 'clearErrors',
};
const mutationTypes = {
    SET_LOADING: 'SET_LOADING',
    SET_ERRORS: 'SET_ERRORS',
};

export default {
    namespaced: true,
    state: {
        loading: false,
        errors: [],
    },
    getters: {
        hasErrors: ( state ) => state.errors.length > 0,
    },
    actions: {
        [ actionTypes.clearErrors ]( { commit } ) {
            commit( mutationTypes.SET_ERRORS, [] );
        },

        async [ actionTypes.getTemplate ]( { commit, dispatch, rootGetters } ) {
            commit( mutationTypes.SET_LOADING, true );
            try {
                const { data } = await PurchaseOrderApi.getMultiCreateTemplate( {
                    authToken: rootGetters[ 'User/authString' ],
                } );
                const filename = `Purchase_Order_Multi-Create_Template.${ FILE_TYPES.TSV.extension }`;
                CsvUtil.create( {
                    filename,
                    filetype: FILE_TYPES.TSV,
                    headers: data.split( '\t' ), // Create an array by splitting on tabs
                } );
                dispatch( 'setSuccessNotification', `Downloaded: ${ filename }`, { root: true } );
            } catch ( error ) {
                dispatch( 'setErrorNotification', 'Failed to download template', { root: true } );
            } finally {
                commit( mutationTypes.SET_LOADING, false );
            }
        },

        async [ actionTypes.uploadTemplate ]( { commit, dispatch, rootGetters }, payload ) {
            commit( mutationTypes.SET_LOADING, true );
            await dispatch( actionTypes.clearErrors );
            await dispatch( 'clearNotifications', { root: true } );
            try {
                await PurchaseOrderApi.uploadMultiCreateTemplate( {
                    authToken: rootGetters[ 'User/authString' ],
                    ...payload,
                } );
                dispatch( 'setSuccessNotification', 'File uploaded successfully! Check your email for details!', { root: true } );
            } catch ( error ) {
                let errorMessage = 'Failed to upload file.';

                const errorCodes = [ 'PROCESS_RUNNING', 'INVALID_FILE_FORMAT' ];
                if ( errorCodes.includes( error?.response?.data?.tolCode ) ) {
                    errorMessage = error.response.data.message;
                }

                if ( error?.response?.data?.message.toLowerCase().includes( 'permissions' ) ) {
                    errorMessage = 'You do not have permission to upload a file.';
                }

                if ( error?.response?.data?.lineNumberErrorList?.length > 0 ) {
                    commit( mutationTypes.SET_ERRORS, error.response.data.lineNumberErrorList );
                    errorMessage = 'Failed to create Purchase Orders. Errors found in file. Please review and try again.';
                }

                dispatch( 'setErrorNotification', errorMessage, { root: true } );
            } finally {
                commit( mutationTypes.SET_LOADING, false );
            }
        },
    },
    mutations: {
        [ mutationTypes.SET_LOADING ]( state, loading ) {
            state.loading = loading;
        },

        [ mutationTypes.SET_ERRORS ]( state, errors ) {
            state.errors = errors;
        },
    },
};
