const API_BASE_URL = process.env.VUE_APP_API_URL;

const baseUrl = ( url ) => `${ API_BASE_URL }${ url }`;
const cmaUrl = ( url ) => baseUrl( `cma/v1/${ url }` );
const walnutUrl = ( url ) => baseUrl( `walnut/v1/${ url }` );
const authHeaders = ( authToken ) => ( { Authorization: authToken } );

export default {
    baseUrl,
    cmaUrl,
    walnutUrl,
    authHeaders,
};
