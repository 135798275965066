export default {
    Draft: 'DRAFT',
    Submitted: 'SUBMITTED',
    Canceled: 'CANCELED',
    Complete: 'COMPLETE',
};

export const RRA_STATUS_LIST_COLUMN = {
    Draft: 0,
    Submitted: 0,
    Canceled: 1,
    Completed: 1,
};
