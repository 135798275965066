export default {
    /**
     * @param { SupplierDto } supplierDto
     * @returns { Supplier }
     */
    getSupplierFromSupplierDto( supplierDto ) {
        return {
            id: supplierDto.supplierId || supplierDto.id,
            name: supplierDto.supplierName || supplierDto.name,
            authRequired: supplierDto.authRequired,
            restockingFee: supplierDto.restockingFee,
            notes: supplierDto.notes,
            returnNote: supplierDto.returnNote,
        };
    },
    /**
     * @param { Supplier } supplier
     * @returns { SupplierDto }
     */
    getSupplierDtoFromSupplier( supplier ) {
        return {
            supplierId: supplier.id,
            supplierName: supplier.name,
            authRequired: supplier.authRequired,
            restockingFee: supplier.restockingFee,
            notes: supplier.notes,
            returnNote: supplier.returnNote,
        };
    },
};
