import axios from './config/axios';
import api from './config/api';

export default {
    /**
     * Get pricing for a given list of ISBNs
     * @param {String} authToken
     * @param {String} list
     */
    getIsbnPricing( authToken, isbns ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/pricing/getPricingForIsbns' ),
            method: 'POST',
            headers: api.authHeaders( authToken ),
            data: isbns,
        } );
    },
};
