import axios from './config/axios';
import api from './config/api';

export default {
    /**
     * @POST
     * Assign MANY titles to a given user
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Title_Research_RWS_V1/assignTitlestoUsers
     * @param authToken { String }
     * @param assignee { String } an email address
     * @param titleList { Array } - of objects with a 'productCode'
     * @returns { Promise }
     */
    assignTitleListToResearcher({ authToken, assignee, titleList }) {
        return axios({
            url: api.cmaUrl('titleResearch/title/assign'),
            method: 'POST',
            headers: api.authHeaders(authToken),
            data: JSON.stringify({
                researcherUserName: assignee,
                titles: titleList.map((item) => ({ productCode: item.productCode })),
            }),
        });
    },

    /**
     * @POST
     * Assign a SINGLE title to a given user
     * @param authToken { String }
     * @param assignee { String } an email address
     * @param productCode { String }
     * @returns { Promise }
     */
    assignTitleToResearcher({ authToken, assignee, productCode }) {
        return this.assignTitleListToResearcher({
            authToken,
            assignee,
            titleList: [{ productCode }],
        });
    },

    /**
     * @GET
     * Get a list of divisions missing settings
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Title_Research_RWS_V1/getDivisionsMissingATRSettingsList
     * @returns { AxiosPromise }
     */
    getDivisionsMissingSettings({ authToken }) {
        return axios({
            url: api.cmaUrl('titleResearch/division/missing-settings/list'),
            method: 'GET',
            headers: api.authHeaders(authToken),
        });
    },

    /**
     * @GET
     * Get Title Research Filter Options
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Title_Research_RWS_V1/getSearchFilterOptions
     * @returns { AxiosPromise }
     */
    getFilterOptions({ authToken }) {
        return axios({
            url: api.cmaUrl('titleResearch/filter/options'),
            method: 'GET',
            headers: api.authHeaders(authToken),
        });
    },

    /**
     * Get Master Suppliers
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Title_Research_RWS_V1/getSearchFilterOptions
     * @GET
     * @returns { AxiosPromise }
     */
    getMasterSupplierList({ authToken, allowsReturns = true }) {
        return axios({
            url: api.cmaUrl('titleResearch/masterSuppliers'),
            method: 'GET',
            headers: api.authHeaders(authToken),
            params: {
                filterAllowsReturns: allowsReturns,
            },
        });
    },

    /**
     * @POST
     * Post a productCode and catalogListingId to get prices
     * {
     *     productCode: "string"
     *     catalogListingId: number
     * }
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Title_Research_RWS_V1/titlePricing
     * @params payload { Object }
     * @returns { AxiosPromise }
     */
    getTitlePrices({ authToken, productCode, catalogListingId }) {
        return axios({
            url: api.cmaUrl('titleResearch/title/prices'),
            method: 'POST',
            headers: api.authHeaders(authToken),
            data: JSON.stringify({
                productCode,
                catalogListingId,
            }),
        });
    },

    /**
     * Gets all subscription pricing for a title
     * @params `authToken` - JWT token for the authorization
     * @params `isbn` - The ISBN of the title
     */
    getSubscriptionPricing(authToken, isbn) {
        return axios({
            url: api.cmaUrl('titleResearch/subscription/subscriptionsForIsbnWithPricing'),
            method: 'GET',
            headers: api.authHeaders(authToken),
            params: { isbn },
        });
    },

    /**
     * @GET
     * Get the list of titles to be researched
     * Returns array of Title DTOs:
     * {
     *      author: String,
     *      description: String,
     *      formattedEan13: String,
     *      id: Number,
     *      isbn10: String,
     *      lastResearchedData: String,
     *      official: Boolean,
     *      productCode: String,
     *      researchThresholdOverride: Boolean,
     *      researcherUserId: Number,
     *      researcherUserName: String,
     * }
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Title_Research_RWS_V1/getTitleResearchList
     * @returns { AxiosPromise }
     */
    getTitlesToAssign({ authToken }) {
        return axios({
            url: api.cmaUrl('titleResearch/title/list'),
            method: 'GET',
            headers: api.authHeaders(authToken),
        });
    },

    /**
     * @GET
     * Get the list of titles to be researched by the user
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Title_Research_RWS_V1/getUserTitleResearchList
     * @returns { AxiosPromise }
     */
    getTitlesToResearch({ authToken, username }) {
        return axios({
            url: api.cmaUrl(`titleResearch/${ username }/title/list`),
            method: 'GET',
            headers: api.authHeaders(authToken),
        });
    },

    /**
     * @GET
     * Get the list users by action
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Title_Research_RWS_V1/getUsersByCategory
     * @returns { AxiosPromise }
     */
    getAuthorizedAssignableUsersList({ authToken }) {
        const action = 'ASSIGN_TITLE';
        return axios({
            url: api.cmaUrl(`titleResearch/${ action }/user/list`),
            method: 'GET',
            headers: api.authHeaders(authToken),
        });
    },

    /**
     * @POST
     * Post a researched title to put it into a 'complete' status
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Title_Research_RWS_V1/completeTitles
     * @returns { AxiosPromise }
     */
    postCompletedTitle({ authToken, title }) {
        return axios({
            url: api.cmaUrl('titleResearch/title/complete'),
            method: 'POST',
            headers: api.authHeaders(authToken),
            data: JSON.stringify({
                titleResearchListDTO: [title],
            }),
        });
    },

    /**
     * @POST
     * Post a title { Object } with a productCode, and any of the following optional fields
     * {
     *      "productCode": "string", <-- only required field
     *      "author": "string",
     *      "description": "string",
     *      "researcherUserId": 0,
     *      "notes": "string",
     *      "listPrice": 0,
     *      "designators": [
     *          {
     *           "id": 0,
     *           "name": "string"
     *          }
     *      ],
     *      "primarySupplier": {
     *        "id": 0,
     *        "name": "string",
     *        "code": "string",
     *        "note": "string"
     *      }
     *      "masterSubscriptions": [
     *          {
     *            "id": 0,
     *            "createdAt": "string",
     *            "updatedAt": "string",
     *            "name": "string",
     *            "friendly": "string"
     *          }
     *    ]
     * }
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Title_Research_RWS_V1/modifyTitle
     * @returns { AxiosPromise }
     */
    postModifyTitle({ authToken, title }) {
        return axios({
            url: api.cmaUrl('titleResearch/title/modify'),
            method: 'POST',
            headers: api.authHeaders(authToken),
            data: JSON.stringify(title),
        });
    },

    /**
     * @GET
     * Get all Subscription Libraries available for a given title
     * Docs: https://apps04.dev.slingshotedu.tech/swagger/#!/Title_Research_RWS_V1/subscriptionLibrariesForIsbn
     */
    getLibrariesForTitle(authToken, isbn) {
        return axios({
            url: api.cmaUrl('titleResearch/subscription/subscriptionLibrariesForIsbn'),
            method: 'GET',
            headers: api.authHeaders(authToken),
            params: {
                isbn,
            },
        });
    },
};
