import axios from './config/axios';
import api from './config/api';

export default {
    /**
     * Search subscription entries.
     * Filters can be omitted with an empty json post body, giving you the entire list, or the list can be filtered by id or product code, but not both
     * @POST
     */
    search({ authToken, titleId, productCode }) {
        return axios({
            url: api.cmaUrl('titleResearch/subscription/search'),
            method: 'POST',
            headers: api.authHeaders(authToken),
            data: {
                titleId,
                productCode,
            },
        });
    },

    /**
     * Modify a subscription
     * @POST
     */
    modify({ authToken, subscriptionDetail }) {
        return axios({
            url: api.cmaUrl('titleResearch/subscription/modify'),
            method: 'POST',
            headers: api.authHeaders(authToken),
            data: {
                subscriptionDetail,
            },
        });
    },

    /**
     * Create a subscription
     * @POST
     */
    create({ authToken, subscriptionDetail }) {
        return axios({
            url: api.cmaUrl('titleResearch/subscription/create'),
            method: 'POST',
            headers: api.authHeaders(authToken),
            data: {
                subscriptionDetail,
            },
        });
    },

    /**
     * Fetch all subscriptions
     * Docs: https://apps04.dev.slingshotedu.tech/swagger/#!/Title_Research_RWS_V1/allSubscriptions
     * @GET
     */
    allSubscriptions(authToken) {
        return axios({
            url: api.cmaUrl('titleResearch/subscription/allSubscriptions'),
            method: 'GET',
            headers: api.authHeaders(authToken),
        } );
    },
};
