// Api
import AdoptionApi from '@/api/AdoptionApi';

// Utils
import FormatUtil from '@/utils/FormatUtil';
import ObjectUtil from '@/utils/ObjectUtil';

const actionTypes = {
    setSelectedMessagingStatusId: 'setSelectedMessagingStatusId',
    getMessagingStatusList: 'getMessagingStatusList',
    getAdoptionsByFilters: 'getAdoptionsByFilters',
    setCatalog: 'setCatalog',
    reset: 'reset',
};

const mutationTypes = {
    SET_RESULTS_LIST: 'SET_RESULTS_LIST',
    SET_RESULTS_IN_FLIGHT: 'SET_RESULTS_IN_FLIGHT',
    SET_ONLY_PROPOSED_TITLES: 'SET_ONLY_PROPOSED_TITLES',
    SET_FILTERS_ISBN: 'SET_FILTERS_ISBN',
    SET_FILTERS_MESSAGING_STATUS_LIST: 'SET_FILTERS_MESSAGING_STATUS_LIST',
    SET_FILTERS_MESSAGING_STATUS_IN_FLIGHT: 'SET_FILTERS_MESSAGING_STATUS_IN_FLIGHT',
    SET_FILTERS_MESSAGING_STATUS_SELECTED_ID: 'SET_FILTERS_MESSAGING_STATUS_SELECTED_ID',
    SET_FILTERS_AND_INCLUDE_EXCLUDE: 'SET_FILTERS_AND_INCLUDE_EXCLUDE',
    SET_FILTERS_OR_INCLUDE_EXCLUDE: 'SET_FILTERS_OR_INCLUDE_EXCLUDE',
    SET_CATALOG: 'SET_CATALOG',
};

export default {
    namespaced: true,
    state: {
        /** @type { Object } */
        filters: {
            /** @type { String } */
            isbn: '',

            /** @type { Boolean } */
            onlyProposedTitles: false,

            /** @type { Object } */
            messagingStatuses: {
                /** @type { Object } */
                list: {},
                /** @type { Boolean } */
                inFlight: false,
                /** @type { Number } */
                selectedId: 0,
            },

            /** @type { Object } */
            catalog: {},

            includeExclude: {
                and: {
                    options: [
                        {
                            id: 'notResearchedTitles',
                            name: 'Not Researched Titles',
                        },
                        {
                            id: 'hasGeneratableListing',
                            name: 'Generatable Listing',
                        },
                        {
                            id: 'hasNewListingInActiveOTBTerm',
                            name: 'Has New Listing in Active OTB Term',
                        },
                    ],
                    /** @type { Array } - of the above objects */
                    included: [],
                    /** @type { Array } - of the above objects */
                    excluded: [],
                },
                or: {
                    options: [
                        // MS = messaging status
                        {
                            id: 'msReviewingAdoption',
                            name: 'MS - Reviewing Adoption',
                        },
                        {
                            id: 'msSubmitterResponseRequired',
                            name: 'MS - Submitter Response Required',
                        },
                        {
                            id: 'msUpToDate',
                            name: 'MS - Information Up-to-Date',
                        },
                        {
                            id: 'proposedTitles',
                            name: 'Proposed Titles',
                        },
                        {
                            id: 'hasProblemListing',
                            name: 'Has Problem Listing',
                        },
                        {
                            id: 'hasPendingListing',
                            name: 'Has Pending Listing',
                        },
                    ],
                    /** @type { Array } - of the above objects */
                    included: [],
                    /** @type { Array } - of the above objects */
                    excluded: [],
                },
            },
        },

        /** @type { Object } */
        results: {
            /** @type { Object } */
            list: {},
            /** @type { Boolean } */
            inFlight: false,
        },
    },
    getters: {
        /** @returns { import('@/typedefs/adoption').adoption[] } */
        resultList: ( state ) => Object.values( state.results.list ),
        /** @returns { Number } */
        resultListCount: ( state, getters ) => getters.resultList.length,
        /** @returns { Boolean } */
        hasResults: ( state, getters ) => getters.resultListCount > 0,

        /** @returns { Array } */
        messagingStatusList: ( state ) => Object.values( state.filters.messagingStatuses.list ),
        /** @returns { Number } */
        messagingStatusCount: ( state, getters ) => getters.messagingStatusList.length,
        /** @returns { Boolean } */
        hasMessagingStatuses: ( state, getters ) => getters.messagingStatusCount > 0,
        /** @returns { Boolean } */
        hasSelectedMessagingStatusId: ( state ) => state.filters.messagingStatuses.selectedId !== 0,

        /** @returns { Boolean } */
        hasSelectedLocation: ( state ) => (
            Object.keys( state.filters.catalog ).includes( 'location' )
            && ObjectUtil.isNotEmpty( state.filters.catalog.location )
        ),
        /** @returns { Boolean } */
        hasSelectedDivision: ( state ) => (
            Object.keys( state.filters.catalog ).includes( 'division' )
            && ObjectUtil.isNotEmpty( state.filters.catalog.division )
        ),
        /** @returns { Boolean } */
        hasSelectedDepartmentCode: ( state ) => (
            Object.keys( state.filters.catalog ).includes( 'department' )
            && ObjectUtil.isNotEmpty( state.filters.catalog.division )
        ),
        /** @returns { Boolean } */
        hasSelectedCourseCode: ( state ) => (
            Object.keys( state.filters.catalog ).includes( 'course' )
            && ObjectUtil.isNotEmpty( state.filters.catalog.course )
        ),
    },
    actions: {
        [ actionTypes.reset ]( { commit } ) {
            commit( mutationTypes.SET_RESULTS_LIST, { list: [] } );
            commit( mutationTypes.SET_RESULTS_IN_FLIGHT, { inFlight: false } );
            commit( mutationTypes.SET_CATALOG, { catalog: [] } );
            commit( mutationTypes.SET_ONLY_PROPOSED_TITLES, { boolean: false } );
            commit( mutationTypes.SET_FILTERS_ISBN, { isbn: '' } );
            commit( mutationTypes.SET_FILTERS_MESSAGING_STATUS_LIST, { list: [] } );
            commit( mutationTypes.SET_FILTERS_MESSAGING_STATUS_IN_FLIGHT, { inFlight: false } );
            commit( mutationTypes.SET_FILTERS_MESSAGING_STATUS_SELECTED_ID, { id: 0 } );
            commit( mutationTypes.SET_FILTERS_AND_INCLUDE_EXCLUDE, { includedList: [], excludedList: [] } );
            commit( mutationTypes.SET_FILTERS_OR_INCLUDE_EXCLUDE, { includedList: [], excludedList: [] } );
        },

        [ actionTypes.setSelectedMessagingStatusId ]( context, { id } ) {
            context.commit( mutationTypes.SET_FILTERS_MESSAGING_STATUS_SELECTED_ID, {
                id,
            } );
        },

        [ actionTypes.setCatalog ]( context, { catalog } ) {
            context.commit( mutationTypes.SET_CATALOG, { catalog } );
        },

        async [ actionTypes.getMessagingStatusList ]( context ) {
            try {
                const { data } = await AdoptionApi.getMessagingStatusList( {
                    authToken: context.rootGetters[ 'User/authString' ],
                } );

                context.commit( mutationTypes.SET_FILTERS_MESSAGING_STATUS_LIST, {
                    list: ObjectUtil.normalize( data.adoptionMaterialMessagingStatusDTOList, 'id' ),
                } );
            } catch ( error ) {
                console.error( 'Error in actionTypes.getMessagingStatusList: ', error );
            } finally {
                context.commit( mutationTypes.SET_FILTERS_MESSAGING_STATUS_IN_FLIGHT, { inFlight: false } );
            }
        },

        async [ actionTypes.getAdoptionsByFilters ]( context ) {
            context.commit( mutationTypes.SET_RESULTS_IN_FLIGHT, { inFlight: true } );
            try {
                const getIncludeExcludeFilterValue = ( { id, includedList, excludedList } ) => {
                    if ( includedList.map( ( item ) => item.id ).includes( id ) ) return true;
                    if ( excludedList.map( ( item ) => item.id ).includes( id ) ) return false;
                    return null;
                };
                const includeExcludePayload = {};
                context.state.filters.includeExclude.and.options.forEach( ( option ) => {
                    includeExcludePayload[ option.id ] = getIncludeExcludeFilterValue( {
                        id: option.id,
                        includedList: context.state.filters.includeExclude.and.included,
                        excludedList: context.state.filters.includeExclude.and.excluded,
                    } );
                } );
                context.state.filters.includeExclude.or.options.forEach( ( option ) => {
                    includeExcludePayload[ option.id ] = getIncludeExcludeFilterValue( {
                        id: option.id,
                        includedList: context.state.filters.includeExclude.or.included,
                        excludedList: context.state.filters.includeExclude.or.excluded,
                    } );
                } );

                const { data } = await AdoptionApi.getAdoptionsByFilters( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    payload: {
                        productCodeFilter: context.state.filters.isbn || null,
                        ...includeExcludePayload,
                        // Include the 'catalogFilter' if we have selected a Location
                        ...context.getters.hasSelectedLocation
                            ? {
                                catalogFilter: {
                                    locationId: context.state.filters.catalog.location.id,
                                    ...context.getters.hasSelectedDivision
                                        ? { divisionId: context.state.filters.catalog.division.id }
                                        : {},
                                    ...context.getters.hasSelectedDepartmentCode
                                        ? { departmentCode: context.state.filters.catalog.department }
                                        : {},
                                    ...context.getters.hasSelectedCourseCode
                                        ? { courseCode: context.state.filters.catalog.course }
                                        : {},
                                },
                            }
                            : {},
                    },
                } );

                const mutatedResponse = data.adoptionMaterialItems.map( ( item ) => ( {
                    ...item,
                    locationFriendly: FormatUtil.locationNameFriendly( item.locationName ),
                    instructorFriendly: item.instructor || item.instructorId,
                    requiredFriendly: item.required ? 'Req' : 'Opt',
                    autoAdoptionFriendly: ( () => {
                        if ( item.autoAdoption ) {
                            if ( item.instructor || item.instructorId ) return 'Faculty';
                            return 'Course';
                        }
                        return 'No';
                    } )(),
                    typeFriendly: item.proposedTitle ? 'Proposed Title' : 'Adoption',
                } ) );

                context.commit( mutationTypes.SET_RESULTS_LIST, {
                    list: ObjectUtil.normalize( mutatedResponse, 'adoptionMaterialId' ),
                } );

                // No results found
                if ( mutatedResponse.length === 0 ) {
                    context.dispatch( 'setSuccessNotification', 'No results found.', { root: true } );
                }
            } catch ( error ) {
                console.error( 'Error in actionTypes.getAdoptionsByFilters: ', error );
                context.commit( mutationTypes.SET_RESULTS_LIST, { list: {} } );
            } finally {
                context.commit( mutationTypes.SET_RESULTS_IN_FLIGHT, { inFlight: false } );
            }
        },
    },
    mutations: {
        [ mutationTypes.SET_RESULTS_LIST ]( state, { list } ) {
            state.results.list = list;
        },
        [ mutationTypes.SET_RESULTS_IN_FLIGHT ]( state, { inFlight } ) {
            state.results.inFlight = inFlight;
        },
        [ mutationTypes.SET_ONLY_PROPOSED_TITLES ]( state, { boolean } ) {
            state.filters.onlyProposedTitles = boolean;
        },
        [ mutationTypes.SET_FILTERS_ISBN ]( state, { isbn } ) {
            state.filters.isbn = isbn;
        },
        [ mutationTypes.SET_FILTERS_MESSAGING_STATUS_LIST ]( state, { list } ) {
            state.filters.messagingStatuses.list = list;
        },
        [ mutationTypes.SET_FILTERS_MESSAGING_STATUS_IN_FLIGHT ]( state, { inFlight } ) {
            state.filters.messagingStatuses.inFlight = inFlight;
        },
        [ mutationTypes.SET_FILTERS_MESSAGING_STATUS_SELECTED_ID ]( state, { id } ) {
            state.filters.messagingStatuses.selectedId = id;
        },
        [ mutationTypes.SET_FILTERS_AND_INCLUDE_EXCLUDE ]( state, { includedList, excludedList } ) {
            state.filters.includeExclude.and.included = JSON.parse( JSON.stringify( includedList ) );
            state.filters.includeExclude.and.excluded = JSON.parse( JSON.stringify( excludedList ) );
        },
        [ mutationTypes.SET_FILTERS_OR_INCLUDE_EXCLUDE ]( state, { includedList, excludedList } ) {
            state.filters.includeExclude.or.included = JSON.parse( JSON.stringify( includedList ) );
            state.filters.includeExclude.or.excluded = JSON.parse( JSON.stringify( excludedList ) );
        },

        /**
         * @params state { Object }
         * @params catalog { Array } -> [ Location { Object }, Division { Object }, DepartmentCode { String }, CourseCode { String } ]
         */
        [ mutationTypes.SET_CATALOG ]( state, { catalog } ) {
            state.filters.catalog = catalog;
        },
    },
};
