// Api
import InvoiceApi from '@/api/InvoiceApi';

// Utils
import FormatUtil from '@/utils/FormatUtil';

// Sub-modules
import Detail from './Detail';

export const fullNameSpace = 'Invoice/';

export const mutationTypes = {
    SET_LIST: 'SET_LIST',
    SET_IN_FLIGHT: 'SET_IN_FLIGHT',
    SET_INVOICE_STATUS_LIST: 'SET_INVOICE_STATUS_LIST',
    SET_FILTER_ISBN: 'SET_FILTER_ISBN',
    SET_FILTER_SUPPLIER_DOCUMENT_NUMBER: 'SET_FILTER_SUPPLIER_DOCUMENT_NUMBER',
    SET_FILTER_PURCHASE_ORDER_NUMBER: 'SET_FILTER_PURCHASE_ORDER_NUMBER',
    SET_FILTER_DATE_START: 'SET_FILTER_DATE_START',
    SET_FILTER_DATE_END: 'SET_FILTER_DATE_END',
    SET_FILTER_PRIMARY_SUPPLIER: 'SET_FILTER_PRIMARY_SUPPLIER',
    SET_FILTER_STATUS_LIST: 'SET_FILTER_STATUS_LIST',
    SET_FILTER_LOCATION: 'SET_FILTER_LOCATION',
    SET_TABLE_FILTER: 'SET_TABLE_FILTER',
};

export const actionTypes = {
    reset: 'reset',
    setList: 'setList',
    setFilterISBN: 'setFilterISBN',
    setFilterSupplierDocumentNumber: 'setFilterSupplierDocumentNumber',
    setFilterPurchaseOrderNumber: 'setFilterPurchaseOrderNumber',
    setFilterDateStart: 'setFilterDateStart',
    setFilterDateEnd: 'setFilterDateEnd',
    setFilterPrimarySupplier: 'setFilterPrimarySupplier',
    setFilterStatusList: 'setFilterStatusList',
    setFilterLocation: 'setFilterLocation',
    setTableFilter: 'setTableFilter',
    getByFilters: 'getByFilters',
    getInvoiceStatusList: 'getInvoiceStatusList',
    initializeFilterStatusList: 'initializeFilterStatusList',
    create: 'create',
};

// TODO: This function should be moved into a util file
/** @returns { String } */
function getMessageFromError( error ) {
    return error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : error.message;
}

function insertIf( condition, object ) {
    return condition ? object : {};
}

export default {
    namespaced: true,
    modules: {
        Detail,
    },
    state: {
        /** @type { invoice[] } */
        list: [],

        /** @type { String[] } */
        invoiceStatusList: [],

        /** @type { Boolean } - used to tell status of this.list */
        inFlight: false,

        /** @type { String } */
        tableFilter: '',

        /** @type { Object } */
        filters: {
            /** @type { String } */
            isbn: '',
            /** @type { String } */
            supplierDocumentNumber: '',
            /** @type { String } */
            purchaseOrderNumber: '',
            /** @type { String } - 'YYYY-MM-DD' */
            dateStart: '',
            /** @type { String } - 'YYYY-MM-DD' */
            dateEnd: '',
            /** @type { import('@/typedefs/primarySupplier).primarySupplier } */
            primarySupplier: {},
            /** @type { import('@/typedefs/location').location } */
            location: {},
            /** @type { Object } */
            statusList: {},
        },
    },
    getters: {
        /** @returns { Number } */
        invoiceCount: ( state ) => state.list.length,

        /** @returns { Boolean } */
        hasInvoices: ( state, getters ) => (
            getters.invoiceCount > 0
        ),
        /** @returns { Boolean } */
        hasInvoiceStatusList: ( state ) => (
            state.invoiceStatusList.length > 0
        ),
        /** @returns { Boolean } */
        hasFilters: ( state ) => (
            !!state.filters.isbn
            || !!state.filters.supplierDocumentNumber
            || !!state.filters.purchaseOrderNumber
            || !!state.filters.dateStart
            || !!state.filters.dateEnd
            || ( Object.keys( state.filters.primarySupplier ).length > 0 )
            || ( Object.keys( state.filters.location ).length > 0 && !!state.filters.location.id )
            || Object.values( state.filters.statusList ).includes( true )
        ),
    },
    mutations: {
        [ mutationTypes.SET_LIST ]( state, { list } ) {
            state.list = list.map( ( item ) => ( {
                ...item,
                formattedDate: FormatUtil.epochToUTCString( item.invoiceDate ),
            } ) );
        },
        [ mutationTypes.SET_IN_FLIGHT ]( state, { boolean } ) {
            state.inFlight = boolean;
        },
        [ mutationTypes.SET_INVOICE_STATUS_LIST ]( state, { invoiceStatusList } ) {
            state.invoiceStatusList = invoiceStatusList;
        },
        [ mutationTypes.SET_FILTER_ISBN ]( state, { isbn } ) {
            state.filters.isbn = isbn;
        },
        [ mutationTypes.SET_FILTER_PURCHASE_ORDER_NUMBER ]( state, { purchaseOrderNumber } ) {
            state.filters.purchaseOrderNumber = purchaseOrderNumber;
        },
        [ mutationTypes.SET_FILTER_SUPPLIER_DOCUMENT_NUMBER ]( state, { supplierDocumentNumber } ) {
            state.filters.supplierDocumentNumber = supplierDocumentNumber;
        },
        [ mutationTypes.SET_FILTER_DATE_START ]( state, { dateStart } ) {
            state.filters.dateStart = dateStart;
        },
        [ mutationTypes.SET_FILTER_DATE_END ]( state, { dateEnd } ) {
            state.filters.dateEnd = dateEnd;
        },
        [ mutationTypes.SET_FILTER_PRIMARY_SUPPLIER ]( state, { primarySupplier } ) {
            state.filters.primarySupplier = primarySupplier;
        },
        [ mutationTypes.SET_FILTER_STATUS_LIST ]( state, { statusList } ) {
            state.filters.statusList = statusList;
        },
        [ mutationTypes.SET_FILTER_LOCATION ]( state, { location } ) {
            state.filters.location = location;
        },
        [ mutationTypes.SET_TABLE_FILTER ]( state, { tableFilter } ) {
            state.tableFilter = tableFilter;
        },
    },
    actions: {
        [ actionTypes.setFilterISBN ]( { commit }, { isbn } ) {
            commit( mutationTypes.SET_FILTER_ISBN, { isbn } );
        },
        [ actionTypes.setFilterSupplierDocumentNumber ]( { commit }, { supplierDocumentNumber } ) {
            commit( mutationTypes.SET_FILTER_SUPPLIER_DOCUMENT_NUMBER, { supplierDocumentNumber } );
        },
        [ actionTypes.setFilterPurchaseOrderNumber ]( { commit }, { purchaseOrderNumber } ) {
            commit( mutationTypes.SET_FILTER_PURCHASE_ORDER_NUMBER, { purchaseOrderNumber } );
        },
        [ actionTypes.setFilterDateStart ]( { commit }, { dateStart } ) {
            commit( mutationTypes.SET_FILTER_DATE_START, { dateStart } );
        },
        [ actionTypes.setFilterDateEnd ]( { commit }, { dateEnd } ) {
            commit( mutationTypes.SET_FILTER_DATE_END, { dateEnd } );
        },
        [ actionTypes.setFilterPrimarySupplier ]( { commit }, { primarySupplier } ) {
            commit( mutationTypes.SET_FILTER_PRIMARY_SUPPLIER, { primarySupplier } );
        },
        [ actionTypes.setFilterStatusList ]( { commit }, { statusList } ) {
            commit( mutationTypes.SET_FILTER_STATUS_LIST, { statusList } );
        },
        [ actionTypes.setFilterLocation ]( { commit }, { location } ) {
            commit( mutationTypes.SET_FILTER_LOCATION, { location } );
        },
        [ actionTypes.setTableFilter ]( { commit }, { tableFilter } ) {
            commit( mutationTypes.SET_TABLE_FILTER, { tableFilter } );
        },
        [ actionTypes.initializeFilterStatusList ]( context ) {
            const statusList = {};
            context.state.invoiceStatusList.forEach( ( status ) => {
                statusList[ status ] = false;
            } );
            context.commit( mutationTypes.SET_FILTER_STATUS_LIST, { statusList } );
        },

        [ actionTypes.reset ]( { commit, dispatch } ) {
            commit( mutationTypes.SET_LIST, { list: [] } );
            commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            commit( mutationTypes.SET_FILTER_ISBN, { isbn: '' } );
            commit( mutationTypes.SET_FILTER_PURCHASE_ORDER_NUMBER, { purchaseOrderNumber: '' } );
            commit( mutationTypes.SET_FILTER_SUPPLIER_DOCUMENT_NUMBER, { supplierDocumentNumber: '' } );
            commit( mutationTypes.SET_FILTER_DATE_START, { dateStart: '' } );
            commit( mutationTypes.SET_FILTER_DATE_END, { dateEnd: '' } );
            commit( mutationTypes.SET_FILTER_PRIMARY_SUPPLIER, { primarySupplier: {} } );
            commit( mutationTypes.SET_FILTER_LOCATION, { location: { id: 0 } } );
            commit( mutationTypes.SET_TABLE_FILTER, { tableFilter: '' } );
            dispatch( actionTypes.initializeFilterStatusList );
        },

        /**
         * Used to set the list when sorting the Invoice list
         */
        async [ actionTypes.setList ]( context, { list } ) {
            context.commit( mutationTypes.SET_LIST, { list } );
        },

        /**
         * Load in the invoice status list
         */
        async [ actionTypes.getInvoiceStatusList ]( context ) {
            if ( context.getters.hasInvoiceStatusList ) return;
            try {
                const { data } = await InvoiceApi.getInvoiceStatusList( {
                    authToken: context.rootGetters[ 'User/authString' ],
                } );
                context.commit( mutationTypes.SET_INVOICE_STATUS_LIST, {
                    invoiceStatusList: data.statuses,
                } );
                context.dispatch( actionTypes.initializeFilterStatusList );
            } catch ( error ) {
                const message = `Failed to get Invoice Status list - ${ getMessageFromError( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
            }
        },

        /**
         * Search for Invoices by filters
         */
        async [ actionTypes.getByFilters ]( context ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            await context.dispatch( 'clearNotifications', { root: true } );
            try {
                const statusesToSend = [];
                Object.keys( context.state.filters.statusList ).forEach( ( status ) => {
                    if ( context.state.filters.statusList[ status ] ) {
                        statusesToSend.push( status );
                    }
                } );
                const { data } = await InvoiceApi.postFiltersToGetInvoiceList( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    // Conditionally insert object attributes so we don't send blank information
                    payload: {
                        ...insertIf( context.state.filters.supplierDocumentNumber !== '', {
                            supplierDocumentNumber: context.state.filters.supplierDocumentNumber,
                        } ),
                        ...insertIf( context.state.filters.purchaseOrderNumber !== '', {
                            purchaseOrderNumber: context.state.filters.purchaseOrderNumber,
                        } ),
                        ...insertIf( context.state.filters.dateStart !== '', {
                            dateStart: context.state.filters.dateStart,
                        } ),
                        ...insertIf( context.state.filters.dateEnd !== '', {
                            dateEnd: context.state.filters.dateEnd,
                        } ),
                        ...insertIf( context.state.filters.isbn !== '', {
                            isbn: FormatUtil.sanitizeIsbn( context.state.filters.isbn ),
                        } ),
                        ...insertIf( context.state.filters.primarySupplier !== {}, {
                            supplierId: context.state.filters.primarySupplier.id,
                        } ),
                        ...insertIf( statusesToSend.length !== 0, {
                            statuses: statusesToSend,
                        } ),
                        ...insertIf( context.state.filters.location !== {} && context.state.filters.location.id !== 0, {
                            locationId: context.state.filters.location.id,
                        } ),
                        allLocations: context.state.filters.location === {} || context.state.filters.location.id === 0,
                    },
                } );
                context.commit( mutationTypes.SET_LIST, {
                    list: data.invoiceSearchDTOs,
                } );
            } catch ( error ) {
                const message = `Failed to find any Invoices matching the filter criteria - ${ getMessageFromError( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                context.commit( mutationTypes.SET_LIST, {
                    list: [],
                } );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },

        async [ actionTypes.create ]( context, { invoice } ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                const { data } = await InvoiceApi.createInvoice( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    payload: invoice,
                } );
                const invoiceId = data.id;
                const invoiceUrl = `#/invoice/${ invoiceId }`;
                const message = `Created Invoice <a href="${ invoiceUrl }" target="_blank">${ invoiceId }</a>`;
                context.dispatch( 'setSuccessNotification', message, { root: true } );
                return invoiceId;
            } catch ( error ) {
                const message = `Error occurred when creating this Invoice - ${ getMessageFromError( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                throw new Error( getMessageFromError( error ) );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },
    },
};
