/// ////////////////////////////////////////////
///   FORMAT UTIL IS MEANT FOR DISPLAY ONLY   //
/// ////////////////////////////////////////////
// Helper functions specific to this file

/**
 * @private
 * Pad a month or day with a leading zero if it is a single digit month
 * @param { Number } monthOrDay
 * @returns { String }
 */
function padMonthOrDayWithZero( monthOrDay ) {
    return monthOrDay.toString().length === 1
        ? `0${ monthOrDay.toString() }`
        : monthOrDay.toString();
}

// Exported object
export default {
    /**
     * @public
     * Remove any dashes present if isbn is a string
     * @param { String | Number } isbn
     * @returns { String }
     */
    sanitizeIsbn( isbn ) {
        return ( isbn && typeof isbn === 'string' )
            ? isbn.replaceAll( /-/g, '' ).trim()
            : isbn;
    },

    /**
     * @public
     * Location names in the db all look like: "Central Christian College - 30",
     * this method removes the trailing space, dash, and number
     * Eg: "Central Christian College - 30" to "Central Christian College"
     * @param { String } locationName
     * @returns { String }
     */
    locationNameToPrettyName( locationName ) {
        return ( locationName && typeof locationName === 'string' )
            ? locationName.replace( / -([^-]+)$/, '' ).trim()
            : locationName;
    },

    /**
     * @public
     * Returns a location name without 'Bookstore' on the end, if it is there
     * @param { String } locationName
     * @returns { String }
     */
    stripBookstoreFromLocationName( locationName ) {
        const nameWithNoNumber = this.locationNameToPrettyName( locationName );
        return nameWithNoNumber.includes( 'Bookstore' )
            ? nameWithNoNumber.split( 'Bookstore' )[ 0 ].trim()
            : nameWithNoNumber.trim();
    },

    /**
     * @public
     * Returns a location name without 'Bookstore' or 'Campus Store' on the end, if it is there
     * @param locationName { String }
     * @returns { String }
     */
    locationNameFriendly( locationName ) {
        return this.stripBookstoreFromLocationName( locationName ).includes( 'Campus Store' )
            ? this.stripBookstoreFromLocationName( locationName ).split( 'Campus Store' )[ 0 ].trim()
            : this.stripBookstoreFromLocationName( locationName ).trim();
    },

    /**
     * @public
     * Returns the initialization of a location
     * @param { String } locationName
     * @returns { String }
     */
    locationNameToInitials( locationName ) {
        const nameWithNoBookstore = this.stripBookstoreFromLocationName( locationName );
        // Regex only selects capital letters
        return nameWithNoBookstore.match( /[A-Z]/g ).join( '' );
    },

    /**
     * @public
     * Converts a unix epoch timestamp to a human readable date
     * Eg: 1517547600000 to 2018-02-02
     * Note: .getMonth returns a month value from 0 to 11, so we need to add 1 to it's result
     * @param { Number | Null } epochTimeStamp
     * @returns { String }
     */
    epochToDate( epochTimeStamp ) {
        return ( !!epochTimeStamp && typeof epochTimeStamp === 'number' )
            ? `${ new Date( epochTimeStamp ).getFullYear() }
              -${ padMonthOrDayWithZero( new Date( epochTimeStamp ).getMonth() + 1 ) }
              -${ padMonthOrDayWithZero( new Date( epochTimeStamp ).getDate() ) }`
                .replace( /\r?\n|\r/g, '' ) // remove line breaks
                .replace( /\s/g, '' ) // remove whitespace
            : '';
    },

    /**
     * @public
     * Convert a date string to a unix timestamp
     * Example Input: '2021-06-06'
     * Example Output: 1622937600000
     * @param { String } dateString
     * @returns { Number }
     */
    dateToEpoch( dateString ) {
        return new Date( dateString ).getTime();
    },

    /**
     * @public
     * Converts a unix epoch timestamp to simple human readable format
     * Example Input: 1517547600000
     * Example Output: String 02/02/2018
     * @param { Number } epochTimeStamp
     * @returns { String }
     */
    epochToSimpleString( epochTimeStamp ) {
        return this.dateTimeToSimpleString( this.epochToDateTime( epochTimeStamp ) );
    },

    /**
     * @public
     * Converts a unix epoch timestamp to a human readable date time
     * @param { Number | Null } epochTimeStamp
     * @returns { String }
     */
    epochToDateTime( epochTimeStamp ) {
        if ( epochTimeStamp && typeof epochTimeStamp === 'number' ) {
            const timeValue = new Date( epochTimeStamp );
            return `${ timeValue.toLocaleDateString() } ${ timeValue.toLocaleTimeString() }`; // used these methods bc they displayed prettier
        }
        return '';
    },

    /**
     * @public
     * Converts JavaScript Date string to simple human readable format
     * Example Input: String 2021-06-05 14:19:58.498
     * Example Output: String 06/05/2021
     * @param { String } dateString
     * @returns { String }
     */
    dateTimeToSimpleString( dateString ) {
        if ( dateString && typeof dateString === 'string' ) {
            const date = new Date( dateString );
            const options = {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                timeZone: 'UTC',
            };
            return date.toLocaleDateString( 'en-US', options );
        }
        return '';
    },

    /**
     * @public
     * Convert a unix timestamp to a UTC date string
     * Example Input: 1623110400000
     * Example Output: '06/08/2021' OR '2021-06-08'
     * @param { Number } unixTimestamp
     * @param { Object } options - { format: 'YYYY-MM-DD' || 'MM/DD/YYYY' }
     * @returns { String }
     */
    epochToUTCString( unixTimestamp, options = {} ) {
        const dateTime = new Date( unixTimestamp );
        const UTCMonth = dateTime.getUTCMonth() + 1;
        const UTCDay = dateTime.getUTCDate();
        const UTCYear = dateTime.getUTCFullYear();
        const defaultReturnValue = `${ UTCMonth }/${ UTCDay }/${ UTCYear }`;
        if ( options && options.format ) {
            if ( options.format === 'YYYY-MM-DD' ) {
                return `${ UTCYear }-${ padMonthOrDayWithZero( UTCMonth ) }-${ padMonthOrDayWithZero( UTCDay ) }`;
            }
            if ( options.format === 'MM/DD/YYYY' ) {
                return defaultReturnValue;
            }
        }
        return defaultReturnValue;
    },

    /**
     * @public
     * @param { String } yearMonthDay - example 2014-10-13
     * @returns { String }
     */
    yearMonthDayToSimpleString( yearMonthDay ) {
        if ( yearMonthDay && typeof yearMonthDay === 'string' ) {
            const values = yearMonthDay.split( '-' );
            return `${ values[ 1 ] }/${ values[ 2 ] }/${ values[ 0 ] }`;
        }
        return '';
    },

    /**
     * @public
     * Truncate text and append an ellipsis after a numerical characterLimit
     * @param { String } textToTruncate
     * @param { Number } characterLimit
     * @returns { String }
     */
    truncateText( textToTruncate, characterLimit ) {
        return ( typeof textToTruncate === 'string' ) && ( textToTruncate.length > 40 )
            ? `${ textToTruncate.substring( 0, characterLimit ) }...`
            : textToTruncate;
    },

    /**
     * @public
     * Parse the value to a floating point number and fix it to have 2 decimal places
     * @param { Number | String }value
     * @returns { String }
     */
    toCurrency( value ) {
        return parseFloat( value ).toFixed( 2 );
    },

    /**
     * @public
     * Parse a price to a floating point number with two decimal places
     * @see https://stackoverflow.com/a/29494612 for rounding numbers
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Arithmetic_Operators#Exponentiation_(**) for exponentaion op
     * @param { Number } number
     * @param { Number } decimalPlaces
     * @param { Number } base
     * @returns { Number }
     */
    roundNumber( number, decimalPlaces, base = 10 ) {
        if ( typeof number !== 'number' && typeof number !== 'string' ) {
            return number;
        }
        const factor = base ** decimalPlaces;
        return Math.round( parseFloat( number ) * factor ) / factor;
    },

    /**
     * @public
     * Append 'Days' if duration is not -1,
     * if it is -1, return 'Lifetime'
     * @param { Number } duration
     * @returns { String }
     */
    digitalDurationToFriendly( duration ) {
        if ( duration === -1 || duration === 0 ) {
            return duration === -1
                ? 'Lifetime'
                : 'Section Duration';
        }
        return `${ duration } Days`;
    },

    /**
     * @public
     * Capitalize a given string
     * Input -> 'digital' || 'capitalize me baby!'
     * Output -> 'Digital || 'Capitalize Me Baby!'
     * @returns { String }
     */
    capitalizeString( string ) {
        return typeof string === 'string'
            ? string.replace( /\b\w/g, ( letter ) => letter.toUpperCase() )
            : '';
    },
};
