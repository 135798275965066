import ObjectUtil from '@/utils/ObjectUtil';

/**
 * 100% Lifted from Buefy
 * @param { Object } obj
 * @param { String } path - an addressable object path
 */
function getValueByPath( obj, path ) {
    return path
        .split( '.' )
        .reduce( ( o, i ) => o[ i ], obj );
}

/**
 * Sort an array of objects by key without mutating original data.
 * @param { Object[] } array
 * @param { String } key
 * @param { Boolean } isAsc
 */
const stableSort = ( array, key, isAsc ) => {
    const sortedArray = array
        // map the original order into the array
        .map( ( item, index ) => ( { ...item, originalIndex: index } ) )
        // sort it all
        .sort( ( a, b ) => {
            // Get nested values from objects
            const newA = getValueByPath( a, key );
            const newB = getValueByPath( b, key );

            // sort boolean type
            if ( typeof newA === 'boolean' && typeof newB === 'boolean' ) {
                return -( newA - newB );
            }

            // keep order when both values are null
            if ( newA === null && newB === null ) {
                return 0;
            }
            // if one value is null, push it to the end
            if ( newA === null ) {
                return 1;
            }
            if ( newB === null ) {
                return -1;
            }

            // sort everything else
            try {
                // Attempt to sort by ascii value of string equivalent. Works for letters, numbers, and mixes of the two.
                return newA.toString().localeCompare( newB.toString() );
            } catch ( e ) {
                // If we can't compare the values for any reason, keep original list order
                return a.originalIndex - b.originalIndex;
            }
        } )
        // Remove our added index from the array of objects
        .map( ( { originalIndex, ...item } ) => item );
    return isAsc
        // Sorted Ascending
        ? sortedArray
        // Sorted Descending
        : sortedArray.reverse();
};

export default {
    /**
     * @public
     * Stringify the two arrays for equality comparison,
     * otherwise they'll never be equal unless they reference a COMMON array
     * @param firstArray { Array } - Array of strings or numbers
     * @param secondArray { Array } - Array of strings or numbers
     */
    isEqual( firstArray, secondArray ) {
        return JSON.stringify( firstArray.sort() ).toLowerCase() === JSON.stringify( secondArray.sort() ).toLowerCase();
    },

    clone( arrayToClone ) {
        return arrayToClone.slice();
    },

    deepClone( arrayToClone ) {
        if ( arrayToClone && typeof arrayToClone[ 0 ] === 'object' ) {
            return arrayToClone.map( ( item ) => ObjectUtil.deepClone( item ) );
        }

        return arrayToClone.slice();
    },

    /**
     * MUTATES HAYSTACK - removes needle from haystack if needle exists. Returns array of the items removed
     * -- https://stackoverflow.com/questions/5767325/how-do-i-remove-a-particular-element-from-an-array-in-javascript
     * @param { Array } haystack
     * @param { * } needle
     * @param { String } matchingAttribute - if present, look deeper for equality
     * @returns { Array }
     */
    remove( haystack, needle, matchingAttribute = null ) {
        let needleLocation;

        if ( matchingAttribute ) {
            needleLocation = this.findIndex( haystack, needle, matchingAttribute );
        } else {
            needleLocation = haystack.indexOf( needle );
        }

        // if we pass -1 to splice it will remove the last element of the array - yikes!
        if ( needleLocation === -1 ) {
            return []; // no elements removed
        }

        // Underlying technology relies on calling .splice() here to observe mutations to array data,
        // so read this before changing implementation here: https://vuejs.org/v2/guide/list.html#Array-Change-Detection
        return haystack.splice( needleLocation, 1 );
    },

    includes( haystack, needle, matchingAttribute ) {
        return this.findIndex( haystack, needle, matchingAttribute ) !== -1;
    },

    findIndex( haystack, needle, matchingAttribute ) {
        return haystack.findIndex( ( haystackItem ) => haystackItem[ matchingAttribute ] === needle[ matchingAttribute ] );
    },

    /**
     * Return only the unique elements in an array - doesn't mutate original.
     * @param mixedArray
     * @returns { Array }
     */
    unique( mixedArray ) {
        return Array.from(
            new Set( mixedArray ),
        );
    },

    /**
     * Return all items in the hayStack array that are found by the needleAttribute in the needleStack array
     * @param hayStack { Array } - Array of objects
     * @param needleStack { Array } - Array of strings to search by
     * @param needleAttribute { String } - attribute to search by, defaults to 'id'
     */
    whereIn( { hayStack = [], needleStack = [], needleAttribute = 'id' } ) {
        return hayStack.filter( ( item ) => needleStack.indexOf( item[ needleAttribute ] ) !== -1 );
    },

    /**
     * 100% Lifted from Buefy
     * Sort an array by key without mutating original data.
     * Call the user sort function if it was passed.
     */
    sortBy( array, key, fn, isAsc ) {
        let sorted = [];
        // Sorting without mutating original data
        if ( fn && typeof fn === 'function' ) {
            sorted = [ ...array ].sort( ( a, b ) => fn( a, b, isAsc ) );
        } else {
            sorted = stableSort( array, key, isAsc );
        }
        return sorted;
    },

    /**
     * Custom sorting for v-data-table
     * @param { Object[] } items
     * @param { String[] } sortBy
     * @param { Boolean[] } sortDesc
     * @returns { Object[] } items
     */
    sort( items, sortBy, sortDesc ) {
        if ( items.length === 0 ) return items;
        // Copy arrays so we're not mutating the originals
        const sortByReverse = [ ...sortBy ].reverse();
        const sortDescReverse = [ ...sortDesc ].reverse();
        let sortedItems = [ ...items ];
        // If we ever allow for multi-sorting, this should handle it by going backwards up the sort order & direction
        sortByReverse.forEach( ( sortByAttribute, index ) => {
            sortedItems = stableSort( sortedItems, sortByAttribute, !sortDescReverse[ index ] );
        } );
        return sortedItems;
    },
};
