<template>
    <v-navigation-drawer
        v-model="drawer"
        :permanent="drawer"
        app
        disableResizeWatcher
    >
        <v-container>
            <v-row class="nav-heading">
                <v-img
                    :src="require('@/assets/images/logo_hickory.png')"
                    class="nav-heading__logo"
                    maxHeight="32"
                    maxWidth="32"
                />
                <h1>Hickory</h1>
            </v-row>
            <v-row>
                <v-list class="nav-list">
                    <v-list-item-group>
                        <div
                            v-for="item in linkGroupList"
                            :key="item.title"
                            class="nav-list__list-item-wrapper"
                        >
                            <v-list-item
                                v-if="item.routeList.length === 1"
                                class="nav-list__nav-list-item"
                                :to="item.routeList[ 0 ]"
                                @click="drawer = false"
                            >
                                <v-list-item-content >
                                    <v-list-item-title>
                                        {{ item.title }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-group
                                v-else
                                class="nav-list__nav-list-group"
                                :value="linkGroupHasCurrentRoute(item)"
                            >
                                <template v-slot:activator>
                                    <v-list-item-title>
                                        {{ item.title }}
                                    </v-list-item-title>
                                </template>
                                <v-list-item-group
                                    :value="linkGroupHasCurrentRoute(item)"
                                >
                                    <v-list-item
                                        v-for="route in item.routeList"
                                        :key="route.name"
                                        :to="route"
                                        exactPath
                                        @click="drawer = false"
                                    >
                                        <v-list-item-content class="nav-list__nav-list-item--nested" >
                                            <v-list-item-title>
                                                {{ route.meta.title }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list-group>
                        </div>
                    </v-list-item-group>
                </v-list>
            </v-row>
        </v-container>
        <template #append>
            <v-row noGutters>
                <v-spacer/>
                <v-btn
                    :to="LogoutRoute"
                    class="logout-button"
                    color="primary"
                    width="200"
                    @click="drawer = false"
                >
                    {{ LogoutRoute.name }}
                </v-btn>
                <v-spacer/>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
// Vuex
import { mapState } from 'vuex';

// Routes
import LogoutRoute from '@/router/routes/Logout';
import TitleAssignmentRoute from '@/router/routes/TitleAssignment';
import MyResearchQueueRoute from '@/router/routes/TitleResearchQueue';
import CatalogListingSearchRoute from '@/router/routes/TitleResearchCatalogListings';
import PurchaseOrders from '@/router/routes/PurchaseOrders';
import AdoptionRoute from '@/router/routes/Adoption';
import TitleGroupRoute from '@/router/routes/TitleGroup';
import InvoiceReconciliationRoute from '@/router/routes/InvoiceReconciliation';
import CreditMemoReconciliationRoute from '@/router/routes/CreditMemoReconciliation';
import RRARoute from '@/router/routes/RRA';
import LookupRoute from '@/router/routes/Lookup';
import SubscriptionsRoute from '@/router/routes/Subscription';

// Feature Flags
// import featureFlags from '../../../featureFlags';

export default {
    name: 'TheNavDrawer',
    data() {
        return {
            linkGroupList: [
                {
                    title: 'Title Research',
                    routeList: [
                        TitleAssignmentRoute,
                        MyResearchQueueRoute,
                    ],
                },
                {
                    title: 'Catalog Listings',
                    routeList: [
                        CatalogListingSearchRoute,
                    ],
                },
                {
                    title: 'Subscriptions',
                    routeList: [
                        SubscriptionsRoute,
                    ],
                },
                {
                    title: 'Adoptions',
                    routeList: [
                        AdoptionRoute,
                    ],
                },
                {
                    title: 'Title Grouping',
                    routeList: [
                        TitleGroupRoute,
                    ],
                },
                {
                    title: 'Purchase Orders',
                    parent: PurchaseOrders,
                    routeList: PurchaseOrders.children,
                },
                {
                    title: 'Invoices',
                    routeList: [
                        InvoiceReconciliationRoute,
                    ],
                },
                {
                    title: 'Credit Memos',
                    routeList: [
                        CreditMemoReconciliationRoute,
                    ],
                },
                {
                    title: 'RRAs',
                    parent: RRARoute,
                    routeList: [
                        ...RRARoute.children.filter( ( child ) => child.meta.title !== 'Detail' ),
                    ],
                },
                {
                    title: 'Lookup Tool',
                    routeList: [
                        LookupRoute,
                    ],
                },
            ],
            LogoutRoute,
            selectedRouteKey: null,
        };
    },
    computed: {
        ...mapState( {
            navDrawer: ( state ) => state.Navigation.drawer,
        } ),
        drawer: {
            get() {
                return this.navDrawer;
            },
            set( val ) {
                this.$store.commit( 'Navigation/SET_DRAWER', { drawer: val } );
            },
        },
    },
    created() {
        // if ( featureFlags.rra ) {
        //     this.linkGroupList.push( {
        //         title: 'RRAs',
        //         routeList: [
        //             ...RRARoute.children.filter( ( child ) => child.meta.title !== 'Detail' ),
        //         ],
        //     } );
        // }
    },
    methods: {
        isCurrentRoute( route ) {
            return route.name === this.$route.name;
        },
        linkGroupHasCurrentRoute( linkGroup ) {
            return linkGroup.routeList.findIndex( ( route ) => this.isCurrentRoute( route ) ) !== -1
                || this.$route.path.includes( linkGroup?.parent?.path );
        },
    },
};
</script>

<style lang="scss" scoped>
    .nav-heading {
        height: 4rem;
        // TODO replace this with elevation when refactoring sidebar
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
        align-items: center;
        &__logo {
            margin: 0 .5rem 0 1.5rem;
        }
    }
    .nav-list {
        width: 100%;
        padding: 1.5rem 0;
        &__nav-list-item {
            padding: 0 1.5rem;
            &--nested {
                padding: 0 1.5rem
            }
        }
        &__nav-list-group {
            ::v-deep .v-list-group__header:first-of-type{
                padding: 0 1.5rem;
            }
        }
        &__list-item-wrapper {
            &:not(:last-child) {
                margin-bottom: .5rem;
            }
        }
    }
    .logout-button {
        margin-bottom: 1rem;
    }
</style>
