import getRouterView from '@/router/helpers';

export default {
    path: '/rra',
    meta: {
        requiresAuth: true,
        title: 'Search',
    },
    component: getRouterView(),
    children: [
        {
            path: '',
            name: 'RRASearch',
            meta: {
                title: 'Search',
            },
            component: () => import( /* webpackChunkName: "RRASearch" */ '@/views/RRA/Search' ),
        },
        {
            path: 'autogen',
            name: 'RRAAutogen',
            meta: {
                title: 'AutoGen',
            },
            component: () => import( /* webpackChunkName: "RRAAutoGen" */ '@/views/RRA/AutoGen' ),
        },
        {
            path: 'create',
            name: 'RRACreate',
            meta: {
                title: 'Create',
            },
            component: () => import( /* webpackChunkName: "RRACreateEdit" */ '@/views/RRA/Create' ),
        },
        {
            path: ':id',
            component: getRouterView(),
            meta: {
                title: 'Detail',
            },
            children: [
                {
                    path: '',
                    name: 'RRADetail',
                    meta: {
                        title: 'Detail',
                    },
                    component: () => import( /* webpackChunkName: "RRACreateEdit" */ '@/views/RRA/Detail' ),
                },
                {
                    path: ':ean/:condition?',
                    name: 'RRAEANDetail',
                    meta: {
                        title: 'EAN Detail',
                    },
                    component: () => import( /* webpackChunkName: "RRAEANDetail" */ '@/views/RRA/EANDetail' ),
                },
            ],
        },
    ],
};
