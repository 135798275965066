// PurchaseOrder Api mapper

// Utils
import FormatUtil from '@/utils/FormatUtil';

export default {
    openToBuyInboundFormat: ( openToBuyEntry ) => ( {
        /** @type { String } */
        divisionName: openToBuyEntry.divisionName,
        /** @type { String } */
        termName: openToBuyEntry.termName,
        /** @type { String } - "MM/DD/YYYY" */
        shelfDate: FormatUtil.dateTimeToSimpleString( openToBuyEntry.shelfDate ),
        /** @type { String } - "MM/DD/YYYY" */
        sourcingEndDate: FormatUtil.dateTimeToSimpleString( openToBuyEntry.sourcingEndDate ),
        /** @type { Number } */
        newOnly: openToBuyEntry.newOnly,
        /** @type { Number } */
        baseQtp: openToBuyEntry.baseQtp,
        /** @type { Number } */
        salesCount: openToBuyEntry.salesCount,
        /** @type { Number } */
        completedOrdersCount: openToBuyEntry.completedOrdersCount,
        /** @type { Number } */
        remainingQtp: openToBuyEntry.remainingQtp,
        /** @type { Number } */
        allOnHand: +openToBuyEntry.appliedNewOnHandInventoryCount
            + +openToBuyEntry.appliedUsedOnHandInventoryCount
            + +openToBuyEntry.surplusNewOnHandInventoryCount
            + +openToBuyEntry.surplusUsedOnHandInventoryCount,
        /** @type { Number } */
        allInTransit: +openToBuyEntry.appliedNewInTransitInventoryCount
            + +openToBuyEntry.appliedUsedInTransitInventoryCount
            + +openToBuyEntry.surplusNewInTransitInventoryCount
            + +openToBuyEntry.surplusUsedInTransitInventoryCount,
        /** @type { Number } */
        allOnOrder: +openToBuyEntry.appliedNewOnOrderInventoryCount
            + +openToBuyEntry.appliedUsedOnOrderInventoryCount
            + +openToBuyEntry.surplusNewOnOrderInventoryCount
            + +openToBuyEntry.surplusUsedOnOrderInventoryCount,
        /** @type { Number } */
        allIncomingRentals: +openToBuyEntry.appliedNewOutstandingRentalsInventoryCount
            + +openToBuyEntry.appliedUsedOutstandingRentalsInventoryCount
            + +openToBuyEntry.surplusNewOutstandingRentalsInventoryCount
            + +openToBuyEntry.surplusUsedOutstandingRentalsInventoryCount,
        /** @type { Number } */
        finalOtb: openToBuyEntry.otbWithSurplusInventory,
        /** @type { String } */
        titleDesignators: openToBuyEntry.titleDesignators,
        /** @type { String } */
        catalogListingDesignators: openToBuyEntry.catalogListingDesignators,
        /** @type { Boolean } */
        printOnlyDesignator: openToBuyEntry.printOnlyDesignator,
    } ),
};
