export default {
    /**
     * @public
     * Return an object that has been cloned at the surface level
     * BE WARNED: Nested arrays and objects will still reference the original objectToBeCloned
     * @param objectToBeCloned { Object }
     */
    shallowClone( objectToBeCloned ) {
        return { ...objectToBeCloned };
    },

    /**
     * @public
     * Stringify's the given object and then parses it to return a deeply cloned object
     * THIS ALSO WORKS FOR ARRAYS YO. ITS JAVASCRIPT. CRY TO YO MAMMA
     * @param objectToBeCloned { Object }
     */
    deepClone( objectToBeCloned ) {
        return JSON.parse( JSON.stringify( objectToBeCloned ) );
    },

    /**
     * @public
     * Check if the passed data is an object
     * @param data
     * @returns Boolean
     */
    isObject( data ) {
        return data !== null
            && data.constructor === Object;
    },

    /**
     * @public
     * Test if an object is empty
     * Docs: https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
     * @param objectToTest { Object }
     */
    isEmpty( objectToTest ) {
        return Object.keys( objectToTest ).length === 0
            && objectToTest.constructor === Object;
    },

    /**
     * @public
     * Test if an object is NOT empty
     * @param objectToTest { Object }
     */
    isNotEmpty( objectToTest ) {
        return !this.isEmpty( objectToTest );
    },

    /**
     * @public
     * Test if an object has more than one property defined
     * @param objectToTest { Object }
     */
    objectHasMoreThanOneProperty( objectToTest ) {
        return objectToTest !== null
            && objectToTest.constructor === Object
            && Object.keys( objectToTest ).length > 1;
    },

    /**
     * @public
     * Key an Object from a given Array by a given attribute
     * @param { Object[] } array - An array of Objects
     * @param { String | Object } key - A string, or an object containing an array of 'keys'
     * key as an object : {
     *     keys: [ { String }, { String } ],
     *     delimiter: '|',
     * }
     * Note: Make sure the values for your keys across your dataset are sufficiently unique
     * If you have fewer entries coming out of normalize() than you did going in, your dataset has multiple entries with the same key values
     * @returns { Object }
     */
    normalize( array, key ) {
        const tmpObj = {};
        array.forEach( ( item ) => {
            // Bail out if key is not provided
            if ( !key ) return item;
            // If we have a key that is a string, and that string is an attribute on the item
            if ( ( typeof key === 'string' ) && ( key in item ) ) {
                // Key by the given key
                tmpObj[ item[ key ] ] = item;
            } else if ( key.keys
                && key.keys.length
                && ( key.keys.length > 0 )
                && key.keys.every( ( currentKey ) => currentKey in item )
            ) {
                const delimiter = key.delimiter || '|';
                // Use reduce to concatenate the keys and the given delimiter
                tmpObj[ key.keys.reduce( ( accumulator, currentItem, index ) => (
                    index === 0
                        ? `${ accumulator }${ item[ currentItem ] }`
                        : `${ accumulator }${ delimiter }${ item[ currentItem ] }`
                ), '' ) ] = item;
            }
            // Return the item if the key is not a String or an Object
            return item;
        } );
        return tmpObj;
    },

    shallowFindChanges( reference, mutable ) {
        return Object.keys( mutable ).reduce( ( modifications, property ) => {
            if ( mutable[ property ] instanceof Object ) {
                if ( mutable[ property ]?.id !== reference[ property ]?.id ) {
                    // eslint-disable-next-line no-param-reassign
                    modifications[ property ] = mutable[ property ];
                }
            } else if ( mutable[ property ] !== reference[ property ] ) {
                // eslint-disable-next-line no-param-reassign
                modifications[ property ] = mutable[ property ];
            }
            return modifications;
        },
        {} );
    },
};
