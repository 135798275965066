import axios from './config/axios';
import api from './config/api';

export default {
    /**
     * Validate a user's credentials
     * @param { String } authString
     * @returns { Promise }
     */
    validateUserCredentials( { authString } ) {
        return axios( {
            url: api.cmaUrl( 'qtp/test' ),
            method: 'get',
            headers: api.authHeaders( authString ),
        } );
    },
};
