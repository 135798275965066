import AuthApi from '@/api/AuthApi';
import LocalAuthApi from '@/api/LocalAuthApi';

const mutationTypes = {
    LOGOUT: 'LOGOUT',
    SET_USER_CREDENTIALS: 'SET_USER_CREDENTIALS',
    SET_USER_CREDENTIALS_FROM_LOCAL_STORAGE: 'SET_USER_CREDENTIALS_FROM_LOCAL_STORAGE',
};

const actionTypes = {
    login: 'login',
    logout: 'logout',
    setUserCredentialsFromLocalStorage: 'setUserCredentialsFromLocalStorage',
    clearSessionData: 'clearSessionData',
};

function getAuthString( username, password ) {
    return `Basic ${ btoa( `${ username }:${ password }` ) }`;
}

export default {
    namespaced: true,
    state: {
        /** @type { String } */
        username: '',
        /** @type { String } */
        password: '',
    },
    getters: {
        /** @returns { Boolean } */
        isLoggedIn: ( state ) => ( state.username.length > 0 ) && ( state.password.length > 0 ),

        /** @returns { Boolean } */
        isLoggedOut: ( state, getters ) => !getters.isLoggedIn,

        /** @returns { String } */
        authString: ( state ) => getAuthString( state.username, state.password ),
    },
    actions: {
        [ actionTypes.clearSessionData ]( { dispatch } ) {
            dispatch( 'reset', {}, { root: true } );
            dispatch( 'Adoption/reset', {}, { root: true } );
            dispatch( 'CreditMemo/reset', {}, { root: true } );
            dispatch( 'CreditMemo/Detail/reset', {}, { root: true } );
            dispatch( 'CatalogListing/reset', {}, { root: true } );
            dispatch( 'GlobalResources/reset', {}, { root: true } );
            dispatch( 'Invoice/reset', {}, { root: true } );
            dispatch( 'Invoice/Detail/reset', {}, { root: true } );
            dispatch( 'PurchaseOrder/reset', {}, { root: true } );
            dispatch( 'RRA/AutoGen/reset', {}, { root: true } );
            dispatch( 'RRA/Detail/reset', {}, { root: true } );
            dispatch( 'RRA/Detail/EANDetail/reset', {}, { root: true } );
            dispatch( 'Title/Assignment/reset', {}, { root: true } );
            dispatch( 'Title/Queue/reset', {}, { root: true } );
            dispatch( 'TitleGroup/reset', {}, { root: true } );
        },
        async [ actionTypes.logout ]( { commit, dispatch } ) {
            await dispatch( actionTypes.clearSessionData );
            commit( mutationTypes.LOGOUT );
        },
        async [ actionTypes.login ]( context, { username, password } ) {
            try {
                await AuthApi.validateUserCredentials( {
                    authString: getAuthString( username, password ),
                } );
                context.commit( mutationTypes.SET_USER_CREDENTIALS, { username, password } );
            } catch ( error ) {
                throw Error( 'Invalid credentials. Try again.' );
            }
        },
        [ actionTypes.setUserCredentialsFromLocalStorage ]( context ) {
            context.commit( mutationTypes.SET_USER_CREDENTIALS_FROM_LOCAL_STORAGE );
        },
    },
    mutations: {
        [ mutationTypes.SET_USER_CREDENTIALS ]( state, { username, password } ) {
            state.username = username;
            state.password = password;
            LocalAuthApi.username.set( username );
            LocalAuthApi.password.set( password );
        },
        [ mutationTypes.SET_USER_CREDENTIALS_FROM_LOCAL_STORAGE ]( state ) {
            state.username = LocalAuthApi.username.get();
            state.password = LocalAuthApi.password.get();
        },
        [ mutationTypes.LOGOUT ]( state ) {
            state.username = '';
            state.password = '';
            LocalAuthApi.removeLocalCredentials();
        },
    },
};
