import axios from './config/axios';
import api from './config/api';

export default {
    /**
     * @POST
     * Search for invoices based on filters
     * Docs: http://apps01.dev.tol.tech/swagger/#!/Walnut_RWS_V1/advancedSearch
     * @returns { Promise }
     */
    postFiltersToGetRRAList( { authToken, payload } ) {
        return axios( {
            url: api.walnutUrl( 'rra/advancedSearch' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( {
                supplierId: payload.supplierId,
                rraNumber: payload.rraNumber,
                ean: payload.ean,
                transRefNum: payload.transRefNum,
                searchRraStartDate: payload.searchRraStartDate,
                searchRraEndDate: payload.searchRraEndDate,
                searchCreditMemoStartDate: payload.searchCreditMemoStartDate,
                searchCreditMemoEndDate: payload.searchCreditMemoEndDate,
                searchTransCreatedStartDate: payload.searchTransCreatedStartDate,
                searchTransCreatedEndDate: payload.searchTransCreatedEndDate,
                productTitle: payload.productTitle,
                creditMemoStatuses: payload.creditMemoStatuses,
                rraStatuses: payload.rraStatuses,
                creditMemoNumber: payload.creditMemoNumber,
            } ),
        } );
    },

    /**
     * @POST
     * Create a new credit memo
     * Api Docs: http://apps01.dev.tol.tech/swagger/#!/Walnut_RWS_V1/createCreditMemo
     * @returns { Promise }
     */
    create( { authToken, payload } ) {
        return axios( {
            url: api.walnutUrl( 'creditMemo/create' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( {
                /** @type { Number } */
                locationId: payload.locationId,
                /** @type { Number } */
                supplierId: payload.supplierId,
                /** @type { String } */
                rraNumber: payload.rraNumber,
                /** @type { String } */
                creditMemoNumber: payload.creditMemoNumber,
                /** @type { String } */
                date: payload.creditMemoDate,
                /** @type { Number } */
                total: payload.creditMemoTotal,
                /** @type { String } */
                cmNotes: payload.creditMemoNotes,
                /** @type { Number[] } */
                rraItemIds: payload.rraItemIds,
            } ),
        } );
    },

    getCreditMemosForRRA( { authToken, rraNumber } ) {
        return axios( {
            url: api.walnutUrl( `creditMemo/rra/${ rraNumber }` ),
            method: 'get',
            headers: api.authHeaders( authToken ),
        } );
    },

    getCreditMemoById( { authToken, creditMemoId } ) {
        return axios( {
            url: api.walnutUrl( `creditMemo/${ creditMemoId }` ),
            method: 'get',
            headers: api.authHeaders( authToken ),
        } );
    },

    updateCreditMemo( { authToken, creditMemo } ) {
        return axios( {
            url: api.walnutUrl( 'creditMemo/update' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( {
                creditMemoId: creditMemo.creditMemoId,
                date: creditMemo.date,
                notes: creditMemo.notes,
                total: creditMemo.total,
                documentStatus: creditMemo.documentStatus,
                rraItemIdsToAdd: creditMemo.rraItemIdsToAdd,
                creditMemoItemUpdateInboundDtos: creditMemo.creditMemoItemUpdateInboundDtos,
                adjustmentInboundDtos: creditMemo.adjustmentInboundDtos,
            } ),
        } );
    },

    deleteCreditMemo( { authToken, creditMemoId } ) {
        return axios( {
            url: api.walnutUrl( 'creditMemo/delete' ),
            method: 'delete',
            headers: api.authHeaders( authToken ),
            params: {
                creditMemoId,
            },
        } );
    },
};
