import getRouterView from '@/router/helpers';

export default {
    path: '/purchase-orders',
    meta: {
        requiresAuth: true,
        title: 'Search',
    },
    component: getRouterView(),
    children: [
        {
            path: '',
            name: 'PurchaseOrderSearch',
            props: ( route ) => ( { docNum: route.query.docNum } ),
            meta: {
                requiresAuth: true,
                title: 'Search',
            },
            component: () => import( /* webpackChunkName: "PurchaseOrderSearch" */ '@/views/PurchaseOrder/Search' ),
        },
        {
            path: 'create',
            name: 'PurchaseOrderCreate',
            meta: {
                requiresAuth: true,
                title: 'Create',
            },
            component: () => import( /* webpackChunkName: "PurchaseOrderCreate" */ '@/views/PurchaseOrder/Create' ),
        },
        {
            path: 'auto-gen',
            name: 'PurchaseOrderAutoGen',
            meta: {
                requiresAuth: true,
                title: 'AutoGen',
            },
            component: () => import( /* webpackChunkName: "PurchaseOrderAutoGen" */ '@/views/PurchaseOrder/AutoGen' ),
        },
        {
            path: 'multi-create',
            name: 'PurchaseOrderMultiCreate',
            meta: {
                requiresAuth: true,
                title: 'Multi-Create',
            },
            component: () => import( /* webpackChunkName: "PurchaseOrderMultiCreate" */ '@/views/PurchaseOrder/MultiCreate' ),
        },
    ],
};
