import axios from './config/axios';
import api from './config/api';

export default {
    /**
     * Search catalog pricing entries.
     * Filters can be omitted, giving you the entire list, or the list can be filtered by:
     *      catalogPricingTypeIdFilter
     *      catalogListingIdFilter
     *      titleIdFilter
     *      activeFilter
     *
     * The catalogPricingTypeIdFilter can be used with the catalogListingFilter or titleIdFilter,
     * but the catalogListingFilter and titleIdFilter cannot be used together.
     * The activeFilter set to true will only return the active records else false or blank will return all
     */
    search( {
        authToken, catalogPricingTypeIdFilter, catalogListingIdFilter, titleIdFilter, activeFilter,
    } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/catalogPricing/search' ),
            method: 'get',
            headers: api.authHeaders( authToken ),
            params: {
                catalogPricingTypeIdFilter,
                catalogListingIdFilter,
                titleIdFilter,
                activeFilter,
            },
        } );
    },

    /**
     * Create a catalog pricing entry
     */
    create( { authToken, catalogPricingEntry } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/catalogPricing/create' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: {
                ...catalogPricingEntry,
            },
        } );
    },

    /**
     * Modify a catalog pricing entry
     */
    modify( { authToken, catalogPricingEntry } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/catalogPricing/modify' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: {
                ...catalogPricingEntry,
            },
        } );
    },

    getFulfillmentTypes( { authToken } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/fulfillmentTypes/list' ),
            method: 'get',
            headers: api.authHeaders( authToken ),
        } );
    },
};
