// Api
import CreditMemoApi from '@/api/CreditMemoApi';

// Utils
import FormatUtil from '@/utils/FormatUtil';
import StoreUtil from '@/utils/StoreUtil';
import ObjectUtil from '@/utils/ObjectUtil';
import DateUtil from '@/utils/DateUtil';

// Constants
import CREDIT_MEMO_STATUS_LIST from '@/constants/creditMemo/creditMemoStatusList';
import RRA_STATUS_LIST from '@/constants/creditMemo/rraStatusList';

// Sub-modules
import Detail from './Detail';

export const fullNameSpace = 'CreditMemo/';

export const mutationTypes = {
    SET_FILTER_PRIMARY_SUPPLIER: 'SET_FILTER_PRIMARY_SUPPLIER',
    SET_FILTER_RRA_DATE_START: 'SET_FILTER_RRA_DATE_START',
    SET_FILTER_RRA_DATE_END: 'SET_FILTER_RRA_DATE_END',
    SET_FILTER_TITLE: 'SET_FILTER_TITLE',
    SET_FILTER_CREDIT_MEMO_DATE_START: 'SET_FILTER_CREDIT_MEMO_DATE_START',
    SET_FILTER_CREDIT_MEMO_DATE_END: 'SET_FILTER_CREDIT_MEMO_DATE_END',
    SET_FILTER_ISBN: 'SET_FILTER_ISBN',
    SET_FILTER_TRANSACTION_NUMBER: 'SET_FILTER_TRANSACTION_NUMBER',
    SET_FILTER_RRA_NUMBER: 'SET_FILTER_RRA_NUMBER',
    SET_FILTER_TRANSACTION_DATE_START: 'SET_FILTER_TRANSACTION_DATE_START',
    SET_FILTER_TRANSACTION_DATE_END: 'SET_FILTER_TRANSACTION_DATE_END',
    SET_IN_FLIGHT: 'SET_IN_FLIGHT',
    SET_LIST: 'SET_LIST',
    SET_FILTER_RRA_STATUS_LIST: 'SET_FILTER_RRA_STATUS_LIST',
    SET_FILTER_CREDIT_MEMO_STATUS_LIST: 'SET_FILTER_CREDIT_MEMO_STATUS_LIST',
    SET_FILTER_CREDIT_MEMO_NUMBER: 'SET_FILTER_CREDIT_MEMO_NUMBER',
    SET_TABLE_FILTER: 'SET_TABLE_FILTER',
    SET_CREDIT_MEMO_LIST: 'SET_CREDIT_MEMO_LIST',
    SET_SELECTED_TRANSACTION: 'SET_SELECTED_TRANSACTION',
    SET_INITIALIZED: 'SET_INITIALIZED',
};

export const actionTypes = {
    setFilterPrimarySupplier: 'setFilterPrimarySupplier',
    setFilterRRADateStart: 'setFilterRRADateStart',
    setFilterRRADateEnd: 'setFilterRRADateEnd',
    setFilterTitle: 'setFilterTitle',
    setFilterCreditMemoDateStart: 'setFilterCreditMemoDateStart',
    setFilterCreditMemoDateEnd: 'setFilterCreditMemoDateEnd',
    setFilterIsbn: 'setFilterIsbn',
    setFilterTransactionNumber: 'setFilterTransactionNumber',
    setFilterCreditMemoNumber: 'setFilterCreditMemoNumber',
    setFilterRRANumber: 'setFilterRRANumber',
    setFilterTransactionDateStart: 'setFilterTransactionDateStart',
    setFilterTransactionDateEnd: 'setFilterTransactionDateEnd',
    setInFlight: 'setInFlight',
    setList: 'setList',
    reset: 'reset',
    getByFilters: 'getByFilters',
    setFilterRRAStatusList: 'setFilterRRAStatusList',
    setFilterCreditMemoStatusList: 'setFilterCreditMemoStatusList',
    initializeFilterStatusList: 'initializeFilterStatusList',
    create: 'create',
    getRRAs: 'getRRAs',
    getCreditMemosForRRA: 'getCreditMemosForRRA',
    setCreditMemoList: 'setCreditMemoList',
    clearCreditMemoList: 'clearCreditMemoList',
};

function insertIf( condition, object ) {
    return condition ? object : {};
}

export default {
    namespaced: true,
    modules: {
        Detail,
    },
    state: {
        /** @type { Boolean } - used to tell status of this.list */
        inFlight: false,
        /** @type { import('@/typedefs/transaction').transaction[] } */
        list: [],

        initialized: true,

        /** @type { import('@/typedefs/transaction').transaction } */
        selectedTransaction: {},
        /** @type { Object[] } */
        creditMemoList: [],

        /** @type { String[] } */
        creditMemoStatusList: Object.keys( CREDIT_MEMO_STATUS_LIST ),
        /** @type { String[] } */
        rraStatusList: Object.keys( RRA_STATUS_LIST ),
        /** @type { Object } */
        filters: {
            /** @type { import('@/typedefs/primarySupplier').primarySupplier } */
            primarySupplier: {},
            /** @type { String } */
            rraDateStart: '',
            /** @type { String } */
            rraDateEnd: '',
            /** @type { String } */
            transactionDateStart: FormatUtil.epochToDate( DateUtil.addMonths( new Date(), -6 ).getTime() ),
            /** @type { String } */
            transactionDateEnd: FormatUtil.epochToDate( new Date().getTime() ),
            /** @type { String } */
            title: '',
            /** @type { String } */
            creditMemoDateStart: '',
            /** @type { String } */
            creditMemoDateEnd: '',
            /** @type { String } */
            isbn: '',
            /** @type { String } */
            transactionNumber: '',
            /** @type { String } */
            creditMemoNumber: '',
            /** @type { String } */
            rraNumber: '',
            /** @type { Object } */
            rraStatusList: StoreUtil.status.initializeList( RRA_STATUS_LIST ),
            /** @type { Object } */
            creditMemoStatusList: StoreUtil.status.initializeList( CREDIT_MEMO_STATUS_LIST ),
        },
        /** @type { String } */
        tableFilter: '',
    },
    getters: {
        /** @returns { Boolean } */
        hasSelectedTransaction: ( state ) => ObjectUtil.isNotEmpty( state.selectedTransaction ),
    },
    mutations: {
        [ mutationTypes.SET_LIST ]( state, { list } ) {
            state.list = list;
        },
        [ mutationTypes.SET_CREDIT_MEMO_LIST ]( state, { creditMemoList } ) {
            state.creditMemoList = creditMemoList;
        },
        [ mutationTypes.SET_FILTER_PRIMARY_SUPPLIER ]( state, { primarySupplier } ) {
            state.filters.primarySupplier = primarySupplier;
        },
        [ mutationTypes.SET_FILTER_RRA_DATE_START ]( state, { rraDateStart } ) {
            state.filters.rraDateStart = rraDateStart;
        },
        [ mutationTypes.SET_FILTER_RRA_DATE_END ]( state, { rraDateEnd } ) {
            state.filters.rraDateEnd = rraDateEnd;
        },
        [ mutationTypes.SET_FILTER_TITLE ]( state, { title } ) {
            state.filters.title = title;
        },
        [ mutationTypes.SET_FILTER_CREDIT_MEMO_DATE_START ]( state, { creditMemoDateStart } ) {
            state.filters.creditMemoDateStart = creditMemoDateStart;
        },
        [ mutationTypes.SET_FILTER_CREDIT_MEMO_DATE_END ]( state, { creditMemoDateEnd } ) {
            state.filters.creditMemoDateEnd = creditMemoDateEnd;
        },
        [ mutationTypes.SET_FILTER_ISBN ]( state, { isbn } ) {
            state.filters.isbn = isbn;
        },
        [ mutationTypes.SET_FILTER_TRANSACTION_NUMBER ]( state, { transactionNumber } ) {
            state.filters.transactionNumber = transactionNumber;
        },
        [ mutationTypes.SET_FILTER_RRA_NUMBER ]( state, { rraNumber } ) {
            state.filters.rraNumber = rraNumber;
        },
        [ mutationTypes.SET_IN_FLIGHT ]( state, { boolean } ) {
            state.inFlight = boolean;
        },
        [ mutationTypes.SET_FILTER_RRA_STATUS_LIST ]( state, { rraStatusList } ) {
            state.filters.rraStatusList = rraStatusList;
        },
        [ mutationTypes.SET_FILTER_CREDIT_MEMO_STATUS_LIST ]( state, { creditMemoStatusList } ) {
            state.filters.creditMemoStatusList = creditMemoStatusList;
        },
        [ mutationTypes.SET_FILTER_CREDIT_MEMO_NUMBER ]( state, { creditMemoNumber } ) {
            state.filters.creditMemoNumber = creditMemoNumber;
        },
        [ mutationTypes.SET_TABLE_FILTER ]( state, { tableFilter } ) {
            state.tableFilter = tableFilter;
        },
        [ mutationTypes.SET_SELECTED_TRANSACTION ]( state, { selectedTransaction } ) {
            state.selectedTransaction = selectedTransaction;
        },
        [ mutationTypes.SET_INITIALIZED ]( state, { initialized } ) {
            state.initialized = initialized;
        },
        [ mutationTypes.SET_FILTER_TRANSACTION_DATE_START ]( state, { transactionDateStart } ) {
            state.filters.transactionDateStart = transactionDateStart;
        },
        [ mutationTypes.SET_FILTER_TRANSACTION_DATE_END ]( state, { transactionDateEnd } ) {
            state.filters.transactionDateEnd = transactionDateEnd;
        },
    },
    actions: {
        [ actionTypes.reset ]( { commit, dispatch } ) {
            commit( mutationTypes.SET_FILTER_PRIMARY_SUPPLIER, { primarySupplier: {} } );
            commit( mutationTypes.SET_FILTER_RRA_DATE_START, { rraDateStart: '' } );
            commit( mutationTypes.SET_FILTER_RRA_DATE_END, { rraDateEnd: '' } );
            commit( mutationTypes.SET_FILTER_TITLE, { title: '' } );
            commit( mutationTypes.SET_FILTER_CREDIT_MEMO_DATE_START, { creditMemoDateStart: '' } );
            commit( mutationTypes.SET_FILTER_CREDIT_MEMO_DATE_END, { creditMemoDateEnd: '' } );
            commit( mutationTypes.SET_FILTER_CREDIT_MEMO_NUMBER, { creditMemoNumber: '' } );
            commit( mutationTypes.SET_FILTER_ISBN, { isbn: '' } );
            commit( mutationTypes.SET_FILTER_TRANSACTION_NUMBER, { transactionNumber: '' } );
            commit( mutationTypes.SET_FILTER_RRA_NUMBER, { rraNumber: '' } );
            commit( mutationTypes.SET_FILTER_TRANSACTION_DATE_START, {
                transactionDateStart: FormatUtil.epochToDate( DateUtil.addMonths( new Date(), -6 ).getTime() ),
            } );
            commit( mutationTypes.SET_FILTER_TRANSACTION_DATE_END, {
                transactionDateEnd: FormatUtil.epochToDate( new Date().getTime() ),
            } );
            commit( mutationTypes.SET_TABLE_FILTER, { tableFilter: '' } );

            commit( mutationTypes.SET_IN_FLIGHT, { inFlight: false } );
            commit( mutationTypes.SET_LIST, { list: [] } );
            commit( mutationTypes.SET_SELECTED_TRANSACTION, { selectedTransaction: {} } );
            commit( mutationTypes.SET_INITIALIZED, { initialized: true } );
            dispatch( actionTypes.initializeFilterStatusList );
        },
        async [ actionTypes.initializeFilterStatusList ]( context ) {
            const creditMemoStatusList = {};
            const rraStatusList = {};

            context.state.creditMemoStatusList.forEach( ( status ) => {
                creditMemoStatusList[ status ] = false;
            } );
            context.state.rraStatusList.forEach( ( status ) => {
                rraStatusList[ status ] = false;
            } );

            await context.dispatch( actionTypes.setFilterRRAStatusList, { rraStatusList } );
            await context.dispatch( actionTypes.setFilterCreditMemoStatusList, { creditMemoStatusList } );
        },
        [ actionTypes.setList ]( { commit }, { list } ) {
            commit( mutationTypes.SET_LIST, { list } );
        },
        [ actionTypes.setCreditMemoList ]( { commit }, { creditMemoList } ) {
            commit( mutationTypes.SET_CREDIT_MEMO_LIST, { creditMemoList } );
        },
        [ actionTypes.clearCreditMemoList ]( { commit } ) {
            commit( mutationTypes.SET_CREDIT_MEMO_LIST, { creditMemoList: [] } );
        },
        [ actionTypes.setInFlight ]( { commit }, { boolean } ) {
            commit( mutationTypes.SET_INITIALIZED, { initialized: false } );
            commit( mutationTypes.SET_IN_FLIGHT, { boolean } );
        },
        [ actionTypes.setFilterPrimarySupplier ]( { commit }, { primarySupplier } ) {
            commit( mutationTypes.SET_FILTER_PRIMARY_SUPPLIER, { primarySupplier } );
        },
        [ actionTypes.setFilterTitle ]( { commit }, { title } ) {
            commit( mutationTypes.SET_FILTER_TITLE, { title: title ?? '' } );
        },
        [ actionTypes.setFilterIsbn ]( { commit }, { isbn } ) {
            commit( mutationTypes.SET_FILTER_ISBN, { isbn } );
        },
        [ actionTypes.setFilterTransactionNumber ]( { commit }, { transactionNumber } ) {
            commit( mutationTypes.SET_FILTER_TRANSACTION_NUMBER, { transactionNumber: transactionNumber ?? '' } );
        },
        [ actionTypes.setFilterRRADateStart ]( { commit }, { rraDateStart } ) {
            commit( mutationTypes.SET_FILTER_RRA_DATE_START, { rraDateStart } );
        },
        [ actionTypes.setFilterRRADateEnd ]( { commit }, { rraDateEnd } ) {
            commit( mutationTypes.SET_FILTER_RRA_DATE_END, { rraDateEnd } );
        },
        [ actionTypes.setFilterRRANumber ]( { commit }, { rraNumber } ) {
            commit( mutationTypes.SET_FILTER_RRA_NUMBER, { rraNumber: rraNumber ?? '' } );
        },
        [ actionTypes.setFilterRRAStatusList ]( { commit }, { rraStatusList } ) {
            commit( mutationTypes.SET_FILTER_RRA_STATUS_LIST, { rraStatusList } );
        },
        [ actionTypes.setFilterCreditMemoDateStart ]( { commit }, { creditMemoDateStart } ) {
            commit( mutationTypes.SET_FILTER_CREDIT_MEMO_DATE_START, { creditMemoDateStart } );
        },
        [ actionTypes.setFilterCreditMemoDateEnd ]( { commit }, { creditMemoDateEnd } ) {
            commit( mutationTypes.SET_FILTER_CREDIT_MEMO_DATE_END, { creditMemoDateEnd } );
        },
        [ actionTypes.setFilterCreditMemoStatusList ]( { commit }, { creditMemoStatusList } ) {
            commit( mutationTypes.SET_FILTER_CREDIT_MEMO_STATUS_LIST, { creditMemoStatusList } );
        },
        [ actionTypes.setFilterCreditMemoNumber ]( { commit }, { creditMemoNumber } ) {
            commit( mutationTypes.SET_FILTER_CREDIT_MEMO_NUMBER, { creditMemoNumber: creditMemoNumber ?? '' } );
        },
        [ actionTypes.setFilterTransactionDateStart ]( { commit }, { transactionDateStart } ) {
            commit( mutationTypes.SET_FILTER_TRANSACTION_DATE_START, { transactionDateStart } );
        },
        [ actionTypes.setFilterTransactionDateEnd ]( { commit }, { transactionDateEnd } ) {
            commit( mutationTypes.SET_FILTER_TRANSACTION_DATE_END, { transactionDateEnd } );
        },

        /**
         * Get an array of RRA's from the rra advanced search endpoint
         * @param { Object } context
         * @param { Object } payload
         */
        async [ actionTypes.getRRAs ]( context, payload ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                const { data } = await CreditMemoApi.postFiltersToGetRRAList( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    payload,
                } );
                return data.rraAdvSearchDtos;
            } catch ( error ) {
                throw new Error( StoreUtil.error.getMessage( error ) );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },

        async [ actionTypes.getCreditMemosForRRA ]( context, { rraNumber } ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            await context.dispatch( 'clearNotifications', { root: true } );
            try {
                const { data } = await CreditMemoApi.getCreditMemosForRRA( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    rraNumber,
                } );
                context.dispatch( actionTypes.setCreditMemoList, {
                    creditMemoList: data.creditMemoDtos,
                } );
            } catch ( error ) {
                const message = `Error trying to find credit memos for RRA ${ rraNumber } - ${ StoreUtil.error.getMessage( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                throw new Error( error );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },

        /**
         * Search for RRAs by filters
         */
        async [ actionTypes.getByFilters ]( context ) {
            await context.dispatch( 'clearNotifications', { root: true } );
            try {
                const rraStatusesToSend = StoreUtil.status.getListFromStatusObject( context.state.filters.rraStatusList );
                const creditMemoStatusesToSend = StoreUtil.status.getListFromStatusObject( context.state.filters.creditMemoStatusList );

                const rraLines = await context.dispatch( actionTypes.getRRAs, {
                    // Conditionally insert object attributes so we don't send blank information
                    ...insertIf( context.state.filters.primarySupplier !== null
                        && 'id' in context.state.filters.primarySupplier, {
                        supplierId: context.state.filters.primarySupplier.id,
                    } ),
                    ...insertIf( !!context.state.filters.rraNumber, {
                        rraNumber: context.state.filters.rraNumber,
                    } ),
                    ...insertIf( !!context.state.filters.isbn, {
                        ean: FormatUtil.sanitizeIsbn( context.state.filters.isbn ),
                    } ),
                    ...insertIf( !!context.state.filters.rraDateStart, {
                        searchRraStartDate: context.state.filters.rraDateStart,
                    } ),
                    ...insertIf( !!context.state.filters.rraDateEnd, {
                        searchRraEndDate: context.state.filters.rraDateEnd,
                    } ),
                    ...insertIf( !!context.state.filters.title, {
                        productTitle: context.state.filters.title,
                    } ),
                    ...insertIf( !!context.state.filters.creditMemoDateStart, {
                        searchCreditMemoStartDate: context.state.filters.creditMemoDateStart,
                    } ),
                    ...insertIf( !!context.state.filters.creditMemoDateEnd, {
                        searchCreditMemoEndDate: context.state.filters.creditMemoDateEnd,
                    } ),
                    ...insertIf( !!context.state.filters.transactionNumber, {
                        transRefNum: context.state.filters.transactionNumber,
                    } ),
                    ...insertIf( !!rraStatusesToSend && rraStatusesToSend.length !== 0, {
                        rraStatuses: rraStatusesToSend,
                    } ),
                    ...insertIf( !!creditMemoStatusesToSend && creditMemoStatusesToSend.length !== 0, {
                        creditMemoStatuses: creditMemoStatusesToSend,
                    } ),
                    ...insertIf( context.state.filters.creditMemoNumber.length !== 0, {
                        creditMemoNumber: context.state.filters.creditMemoNumber,
                    } ),
                    ...insertIf( !!context.state.filters.transactionDateStart, {
                        searchTransCreatedStartDate: context.state.filters.transactionDateStart,
                    } ),
                    ...insertIf( !!context.state.filters.transactionDateEnd, {
                        searchTransCreatedEndDate: context.state.filters.transactionDateEnd,
                    } ),
                } );
                context.commit( mutationTypes.SET_LIST, { list: rraLines } );
            } catch ( error ) {
                const message = `Failed to find any RRAs matching the filter criteria - ${ StoreUtil.error.getMessage( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                context.commit( mutationTypes.SET_LIST, {
                    list: [],
                } );
            }
        },

        /**
         * Create a Credit Memo
         */
        async [ actionTypes.create ]( context, { creditMemo } ) {
            try {
                const { data } = await CreditMemoApi.create( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    payload: creditMemo,
                } );
                const { creditMemoId } = data.creditMemoDtos[ 0 ];
                const creditMemoUrl = `#/credit-memo/${ creditMemoId }`;
                const message = `Created Credit Memo <a href="${ creditMemoUrl }" target="_blank">${ creditMemoId }</a>`;
                context.dispatch( 'setSuccessNotification', message, { root: true } );
                return creditMemoId;
            } catch ( error ) {
                const message = `Error occurred when creating this Credit Memo - ${ StoreUtil.error.getMessage( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                throw new Error( StoreUtil.error.getMessage( error ) );
            }
        },
    },
};
