import LookupApi from '@/api/LookupApi';

export default {
    namespaced: true,

    state: {
        results: [], // returned with axios info
        errorStr: '',
        loading: false,
        time: 0,
    },

    mutations: {
        clearResults: ( state ) => {
            state.results = [];
        },
        setResults: ( state, results ) => {
            state.results = results;
        },
        startLoading: ( state ) => {
            state.loading = true;
            state.errorStr = '';
            state.time = new Date().getTime();
        },
        stopLoading: ( state ) => {
            const endTime = new Date().getTime();
            state.time = parseFloat( ( endTime - state.time ) / 1000 ).toFixed( 2 );
            state.loading = false;
        },
        addErrorStr: ( state, str ) => {
            state.errorStr += str.book;
            state.errorStr += '\n';
        },
    },

    actions: {
        searchHandler( { dispatch }, queryString ) {
            dispatch( 'clearNotifications', { root: true } );
            const isValid = dispatch( 'validateIsbns', queryString );
            if ( !isValid ) return;

            const currentIsbnList = queryString
                .split( /[\s,]+/ )
                .map( ( isbn ) => isbn.trim() );
            dispatch( 'fetchIsbnPrices', currentIsbnList );
        },

        validateIsbns( { dispatch }, queryString ) {
            if ( queryString.length === 0 ) {
                dispatch( 'setErrorNotification', 'Please Enter an ISBN', { root: true } );
                return false;
            }

            const pattern = /\b(978|979)?(?:[0-Z]){10}\b/gm; // regex maybe needed to be updated
            const isbnsArray = queryString.match( pattern ); // to get input string clean into array

            if ( !isbnsArray ) {
                dispatch( 'setErrorNotification', 'No valid ISBNs found in input.', { root: true } );
                return false;
            }

            return true;
        },

        fetchIsbnPrices( {
            dispatch,
            commit,
            rootGetters,
        }, isbns ) {
            commit( 'startLoading' );
            const authToken = rootGetters[ 'User/authString' ];

            LookupApi.getIsbnPricing( authToken, isbns ).then( ( { data } ) => {
                commit( 'setResults', data );
            } ).catch( ( error ) => {
                dispatch( 'setErrorNotification', error.response.data.message, { root: true } );
            } ).finally( () => {
                commit( 'stopLoading' );
            } );
        },
    },
};
