// Api
import RRAApi from '@/api/RRAApi';

// Utils
import StoreUtil from '@/utils/StoreUtil';
import FormUtil from '@/utils/FormUtil';

// Constants
import STATUS_LIST from '@/constants/rras/statusList';

export const mutationTypes = {
    SET_FILTER_SUPPLIER: 'SET_FILTER_SUPPLIER',
    SET_FILTER_NUMBER: 'SET_FILTER_NUMBER',
    SET_FILTER_CREATE_START_DATE: 'SET_FILTER_CREATE_START_DATE',
    SET_FILTER_CREATE_END_DATE: 'SET_FILTER_CREATE_END_DATE',
    SET_FILTER_STATUSES: 'SET_FILTER_STATUSES',
    SET_FILTER_EAN: 'SET_FILTER_EAN',
    SET_FILTER_LOCATION: 'SET_FILTER_LOCATION',
    SET_FILTER_NOTES: 'SET_FILTER_NOTES',

    SET_TABLE_FILTER: 'SET_TABLE_FILTER',
    SET_TABLE_SELECTED: 'SET_TABLE_SELECTED',
    SET_RRA_LIST: 'SET_RRA_LIST',
    SET_RRA_TABLE_LIST: 'SET_RRA_TABLE_LIST',
    SET_TABLE_SORT_BY: 'SET_TABLE_SORT_BY',
    SET_TABLE_SORT_DESC: 'SET_TABLE_SORT_DESC',
    SET_IN_FLIGHT: 'SET_IN_FLIGHT',
    UPDATE_ITEM_IN_LIST: 'UPDATE_ITEM_IN_LIST',
};
export const actionTypes = {
    searchRRAs: 'searchRRAs',
    getPicklists: 'getPicklists',
    getPrintReports: 'getPrintReports',
    setFilterSupplier: 'setFilterSupplier',
    setFilterNumber: 'setFilterNumber',
    setFilterCreateStartDate: 'setFilterCreateStartDate',
    setFilterCreateEndDate: 'setFilterCreateEndDate',
    setFilterStatuses: 'setFilterStatuses',
    setFilterEan: 'setFilterEan',
    setFilterLocation: 'setFilterLocation',
    setFilterNotes: 'setFilterNotes',
    clearFilters: 'clearFilters',

    setTableFilter: 'setTableFilter',
    setTableSelected: 'setTableSelected',
    setTableSortBy: 'setTableSortBy',
    setTableSortDesc: 'setTableSortDesc',
    updateItemInList: 'updateItemInList',
};

export const getterTypes = {
    getStatusList: 'getStatusList',
    includesId: 'includesId',
    indexOfId: 'indexOfId',
    includesTableId: 'includesTableId',
    indexOfTableId: 'indexOfTableId',
    hasFilters: 'hasFilters',
};

export default {
    namespaced: true,
    state: {
        inFlight: false,
        rraList: [],

        table: {
            filter: '',
            rraList: [],
            selected: [],
            /** @type { String[] } */
            sortBy: [ 'createdDate' ],
            /** @type { Boolean[] } */
            sortDesc: [ false ],
        },

        rraStatuses: Object.entries( STATUS_LIST ).map( ( status ) => ( {
            key: status[ 0 ],
            value: status[ 1 ],
        } ) ),

        filters: {
            number: '',
            createStartDate: '',
            createEndDate: '',
            statuses: StoreUtil.status.initializeList( STATUS_LIST ),
            ean: '',
            notes: '',
            location: {},
            supplier: {},
        },
    },
    getters: {
        [ getterTypes.getStatusList ]: ( state ) => (
            StoreUtil.status.getListFromStatusObject( state.filters.statuses, STATUS_LIST )
        ),
        /** @returns { Function } -> { Number } */
        [ getterTypes.indexOfId ]: ( state ) => ( rraId ) => (
            state.rraList.findIndex( ( item ) => item.id === rraId )
        ),
        /** @returns { Function } -> { Boolean } */
        [ getterTypes.includesId ]: ( state ) => ( rraId ) => (
            state.rraList
                .map( ( item ) => item.id )
                .includes( rraId )
        ),
        /** @returns { Function } -> { Number } */
        [ getterTypes.indexOfTableId ]: ( state ) => ( rraId ) => (
            state.table.rraList.findIndex( ( item ) => item.id === rraId )
        ),
        /** @returns { Function } -> { Boolean } */
        [ getterTypes.includesTableId ]: ( state ) => ( rraId ) => (
            state.table.rraList
                .map( ( item ) => item.id )
                .includes( rraId )
        ),
        /** @returns { Boolean } */
        [ getterTypes.hasFilters ]: ( state ) => (
            state.filters.number !== ''
            || state.filters.createStartDate !== ''
            || state.filters.createEndDate !== ''
            || state.filters.ean !== ''
            || state.filters.notes !== ''
            || Object.keys( state.filters.location ).length !== 0
            || Object.keys( state.filters.supplier ).length !== 0
            || Object.values( state.filters.statuses ).every( ( status ) => status !== false )
        ),
    },
    actions: {
        async [ actionTypes.searchRRAs ]( context ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { inFlight: true } );
            try {
                // Clear RRA Lists
                context.commit( mutationTypes.SET_RRA_LIST, { rraList: [] } );
                context.commit( mutationTypes.SET_TABLE_SELECTED, { selected: [] } );

                // These variables are defined prior to the api call to ensure
                // that the user won't accidentally change the filtering logic by clicking statuses mid search
                const shipped = context.state.filters.statuses.Shipped;
                const complete = context.state.filters.statuses.Complete;

                const payload = {
                    ...FormUtil.insertIf( context.state.filters.number !== '', {
                        number: context.state.filters.number,
                    } ),
                    ...FormUtil.insertIf( context.state.filters.createStartDate !== '', {
                        createStartDate: context.state.filters.createStartDate,
                    } ),
                    ...FormUtil.insertIf( context.state.filters.createEndDate !== '', {
                        createEndDate: context.state.filters.createEndDate,
                    } ),
                    ...FormUtil.insertIf( context.state.filters.supplier?.id !== '', {
                        supplierId: context.state.filters.supplier?.id,
                    } ),
                    ...FormUtil.insertIf( context.state.filters.location?.id !== '', {
                        locationId: context.state.filters.location?.id,
                    } ),
                    ...FormUtil.insertIf( !!context.getters.getStatusList?.length, {
                        statuses: context.getters.getStatusList,
                    } ),
                    ...FormUtil.insertIf( context.state.filters.ean !== '', {
                        ean: context.state.filters.ean,
                    } ),
                    ...FormUtil.insertIf( context.state.filters.notes !== '', {
                        notes: context.state.filters.notes,
                    } ),
                };
                const { data } = await RRAApi.getListFromFilters( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    ...payload,
                } );
                let { rraList } = data;
                // If shipped and complete are both true or false, return all results
                // Otherwise return the results with the expected shipping status
                rraList = rraList.filter( ( rra ) => ( shipped === complete ) || rra.isTransactionComplete === shipped );

                context.commit( mutationTypes.SET_RRA_LIST, { rraList } );
            } catch ( e ) {
                throw new Error( e );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { inFlight: false } );
            }
        },
        async [ actionTypes.getPicklists ]( context, { rras } ) {
            try {
                const results = await RRAApi.getPicklistsFromRRAs( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    rras,
                } );
                return results.data.rraReportResultList;
            } catch ( e ) {
                context.dispatch( 'setErrorNotification', `Unable to get picklists: ${ StoreUtil.error.getMessage( e ) }`, { root: true } );
                return [];
            }
        },
        async [ actionTypes.getPrintReports ]( context, { rras } ) {
            try {
                const results = await RRAApi.getPrintReportsFromRRAs( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    rras,
                } );
                return results.data.rraReportResultList;
            } catch ( e ) {
                context.dispatch( 'setErrorNotification', `Unable to get print reports: ${ StoreUtil.error.getMessage( e ) }`, { root: true } );
                return [];
            }
        },
        [ actionTypes.updateItemInList ]( context, { item } ) {
            if ( context.getters.includesId( item.id ) ) {
                context.commit( mutationTypes.UPDATE_ITEM_IN_LIST, {
                    index: context.getters.indexOfId( item.id ),
                    item,
                } );
            }
        },
        [ actionTypes.clearFilters ]( context ) {
            context.dispatch( actionTypes.setFilterSupplier, { supplier: {} } );
            context.dispatch( actionTypes.setFilterCreateStartDate, { createStartDate: '' } );
            context.dispatch( actionTypes.setFilterCreateEndDate, { createEndDate: '' } );
            context.dispatch( actionTypes.setFilterStatuses, {
                statuses: StoreUtil.status.initializeList( STATUS_LIST ),
            } );
            context.dispatch( actionTypes.setFilterNumber, { number: '' } );
            context.dispatch( actionTypes.setFilterEan, { ean: '' } );
            context.dispatch( actionTypes.setFilterLocation, { location: {} } );
            context.dispatch( actionTypes.setFilterNotes, { notes: '' } );
        },

        [ actionTypes.setFilterSupplier ]( context, { supplier } ) {
            context.commit( mutationTypes.SET_FILTER_SUPPLIER, { supplier } );
        },
        [ actionTypes.setFilterCreateStartDate ]( context, { createStartDate } ) {
            context.commit( mutationTypes.SET_FILTER_CREATE_START_DATE, { createStartDate } );
        },
        [ actionTypes.setFilterCreateEndDate ]( context, { createEndDate } ) {
            context.commit( mutationTypes.SET_FILTER_CREATE_END_DATE, { createEndDate } );
        },
        [ actionTypes.setFilterStatuses ]( context, { statuses } ) {
            context.commit( mutationTypes.SET_FILTER_STATUSES, { statuses } );
        },
        [ actionTypes.setFilterNumber ]( context, { number } ) {
            context.commit( mutationTypes.SET_FILTER_NUMBER, { number } );
        },
        [ actionTypes.setFilterEan ]( context, { ean } ) {
            context.commit( mutationTypes.SET_FILTER_EAN, { ean } );
        },
        [ actionTypes.setFilterLocation ]( context, { location } ) {
            context.commit( mutationTypes.SET_FILTER_LOCATION, { location } );
        },
        [ actionTypes.setFilterNotes ]( context, { notes } ) {
            context.commit( mutationTypes.SET_FILTER_NOTES, { notes } );
        },

        [ actionTypes.setTableFilter ]( context, { filter } ) {
            context.commit( mutationTypes.SET_TABLE_FILTER, { filter } );
        },
        [ actionTypes.setTableSelected ]( context, { selected } ) {
            context.commit( mutationTypes.SET_TABLE_SELECTED, { selected } );
        },
        [ actionTypes.setTableSortBy ]( context, { sortBy } ) {
            context.commit( mutationTypes.SET_TABLE_SORT_BY, { sortBy } );
        },
        [ actionTypes.setTableSortDesc ]( context, { sortDesc } ) {
            context.commit( mutationTypes.SET_TABLE_SORT_DESC, { sortDesc } );
        },
    },
    mutations: {
        [ mutationTypes.SET_IN_FLIGHT ]( state, { inFlight } ) {
            state.inFlight = inFlight;
        },
        [ mutationTypes.SET_RRA_LIST ]( state, { rraList } ) {
            state.rraList = rraList;
        },
        [ mutationTypes.UPDATE_ITEM_IN_LIST ]( state, { index, item } ) {
            state.rraList[ index ] = item;
        },

        [ mutationTypes.SET_FILTER_STATUSES ]( state, { statuses } ) {
            state.filters.statuses = statuses;
        },
        [ mutationTypes.SET_FILTER_SUPPLIER ]( state, { supplier } ) {
            state.filters.supplier = supplier;
        },
        [ mutationTypes.SET_FILTER_CREATE_START_DATE ]( state, { createStartDate } ) {
            state.filters.createStartDate = createStartDate;
        },
        [ mutationTypes.SET_FILTER_CREATE_END_DATE ]( state, { createEndDate } ) {
            state.filters.createEndDate = createEndDate;
        },
        [ mutationTypes.SET_FILTER_NUMBER ]( state, { number } ) {
            state.filters.number = number;
        },
        [ mutationTypes.SET_FILTER_EAN ]( state, { ean } ) {
            state.filters.ean = ean;
        },
        [ mutationTypes.SET_FILTER_LOCATION ]( state, { location } ) {
            state.filters.location = location;
        },
        [ mutationTypes.SET_FILTER_NOTES ]( state, { notes } ) {
            state.filters.notes = notes;
        },

        [ mutationTypes.SET_TABLE_FILTER ]( state, { filter } ) {
            state.table.filter = filter;
        },
        [ mutationTypes.SET_TABLE_SELECTED ]( state, { selected } ) {
            state.table.selected = selected;
        },
        [ mutationTypes.SET_TABLE_SORT_BY ]( state, { sortBy } ) {
            state.table.sortBy = sortBy;
        },
        [ mutationTypes.SET_TABLE_SORT_DESC ]( state, { sortDesc } ) {
            state.table.sortDesc = sortDesc;
        },
        [ mutationTypes.SET_RRA_TABLE_LIST ]( state, { rraList } ) {
            state.table.rraList = rraList;
        },
    },
};
