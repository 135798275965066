import getRouterView from '@/router/helpers';

export default {
    path: '/adoptions',
    meta: {
        title: 'Search',
        requiresAuth: true,
    },
    component: getRouterView(), // create an anonymous component so we can have proper route nesting, like Butler Central
    children: [
        {
            path: '',
            name: 'AdoptionSearch',
            component: () => import( /* webpackChunkName: "AdoptionSearch" */ '@/views/Adoptions/AdoptionSearch' ),
        },
        {
            path: 'id',
            component: getRouterView(),
            children: [
                {
                    path: '',
                    redirect: '/adoptions',
                },
                {
                    path: ':id',
                    name: 'AdoptionDetail',
                    component: () => import( /* webpackChunkName: "AdoptionDetail" */ '@/views/Adoptions/AdoptionDetail' ),
                },
            ],
        },
    ],
};
