import Vue from 'vue';

// Api
import TitleApi from '@/api/TitleApi';

export default {
    namespaced: true,
    state: {
        /** @type { Array } */
        list: [],
        /** @type { Boolean } */
        inFlight: false,

        table: {
            /** @type { String[] } */
            sortBy: ['shelfDate'],
            /** @type { Boolean[] } */
            sortDesc: [false],
        },

        titleModifications: {},
    },
    getters: {
        /** @returns { Number } */
        titleCount: (state) => state.list.length,
        /** @returns { Boolean } */
        hasTitles: (state, getters) => getters.titleCount > 0,
        /** @returns { Boolean } */
        hasTitleModifications: (state) => (
            Object.keys(state.titleModifications).includes('productCode')
            && Object.keys(state.titleModifications).length > 1
        ),
    },
    mutations: {
        SET_LIST(state, { list }) {
            state.list = list;
        },
        SET_IN_FLIGHT(state, { boolean }) {
            state.inFlight = boolean;
        },
        UPDATE_TITLE_IN_LIST(state, { titleModifications }) {
            const index = state.list.findIndex((title) => title.productCode === titleModifications.productCode);
            if (index !== -1) {
                Vue.set(
                    state.list,
                    index,
                    {
                        ...state.list[index],
                        ...titleModifications,
                    },
                );
            }
        },
        SET_TABLE_SORT_BY(state, { sortBy }) {
            state.table.sortBy = sortBy;
        },
        SET_TABLE_SORT_DESC(state, { sortDesc }) {
            state.table.sortDesc = sortDesc;
        },
        SET_TITLE_MODIFICATIONS(state, titleModifications) {
            Vue.set(state, 'titleModifications', titleModifications);
        },
    },
    actions: {
        reset({ commit }) {
            commit('SET_IN_FLIGHT', { boolean: false });
            commit('SET_LIST', { list: [] });
            commit('SET_TABLE_SORT_BY', { sortBy: ['sectionStartDate'] });
            commit('SET_TABLE_SORT_DESC', { sortDesc: [false] });
        },

        initializeTitleModifications({ commit }, { productCode }) {
            commit('SET_TITLE_MODIFICATIONS', { productCode });
        },
        updateTitleModifications({ commit, state }, { key, value }) {
            commit('SET_TITLE_MODIFICATIONS', { ...state.titleModifications, [key]: value });
        },
        removeTitleModifications({ commit, state }, { key }) {
            if (key in state.titleModifications) {
                const { [key]: removed, ...rest } = state.titleModifications;
                commit('SET_TITLE_MODIFICATIONS', rest);
            }
        },

        async modifyQueueTitle({ commit, dispatch }, { title }) {
            await dispatch('Title/modifyTitle', { title }, { root: true });
            commit('UPDATE_TITLE_IN_LIST', { titleModifications: title });
        },

        /**
         * Get the list of titles for this user to research
         */
        async getList({
            commit, dispatch, rootGetters, rootState,
        }) {
            commit('SET_IN_FLIGHT', { boolean: true });
            try {
                const { data } = await TitleApi.getTitlesToResearch({
                    authToken: rootGetters['User/authString'],
                    username: rootState.User.username,
                });

                commit('SET_LIST', {
                    list: data?.titleResearchListDTOList ?? [],
                });

                if (data?.titleResearchListDTOList?.length === 0) {
                    await dispatch('setSuccessNotification', 'No titles assigned to research', { root: true });
                }
            } catch (error) {
                await dispatch(
                    'setErrorNotification',
                    `Failed to get this user's list of titles to research - ${error.response.data.message}`,
                    { root: true },
                );
            } finally {
                commit('SET_IN_FLIGHT', { boolean: false });
            }
        },

        /**
         * Mark a title as 'Done'
         */
        async completeTitle({dispatch, rootGetters}, { title }) {
            try {
                await TitleApi.postCompletedTitle({
                    authToken: rootGetters['User/authString'],
                    title,
                });

                await dispatch('setSuccessNotification', 'Saved title and marked as done', { root: true });
            } catch (error) {
                const message = `Failed to complete the researched title - ${error?.response?.data?.message ?? error?.message}`;
                await dispatch('setErrorNotification', message, { root: true });
            }
        },
    },
};
