import axios from './config/axios';
import api from './config/api';

export default {
    /**
     * @GET
     * Get the list of possible invoice statuses
     * Docs: http://apps01.dev.tol.tech/swagger/#!/Walnut_RWS_V1/getInvoiceStatuses
     * @returns { Promise }
     */
    getInvoiceStatusList( { authToken } ) {
        return axios( {
            url: api.walnutUrl( 'invoice/getInvoiceStatuses' ),
            method: 'get',
            headers: api.authHeaders( authToken ),
        } );
    },
    /**
     * @POST
     * Create a new Invoice
     * Docs: http://apps01.dev.tol.tech/swagger/#!/Walnut_RWS_V1/createInvoice
     * @returns { Promise }
     */
    createInvoice( { authToken, payload } ) {
        return axios( {
            url: api.walnutUrl( 'invoice/createInvoice' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( {
                /** @type String */
                supplierDocumentNumber: payload.supplierDocumentNumber,
                /** @type String */
                invoiceCreateDate: payload.invoiceCreateDate,
                /** @type Number */
                total: payload.total,
                /** @type String */
                notes: payload.notes,
                /** @type String */
                poNumber: payload.purchaseOrderNumber,
                /** @type Number */
                supplierId: payload.supplierId,
                /** @type Number */
                locationId: payload.locationId,
                /** @type Array */
                poItemIds: payload.poItemIds,
            } ),
        } );
    },

    /**
     * @POST
     * Search for invoices based on filters
     * Docs: http://apps01.dev.tol.tech/swagger/#!/Walnut_RWS_V1/advancedSearch
     * @returns { Promise }
     */
    postFiltersToGetInvoiceList( { authToken, payload } ) {
        return axios( {
            url: api.walnutUrl( 'invoice/search' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: JSON.stringify( {
                /** @type String */
                supplierId: payload.supplierId,
                /** @type String */
                supplierDocumentNumber: payload.supplierDocumentNumber,
                /** @type String */
                internalDocumentNumber: payload.internalDocumentNumber,
                /** @type String */
                poNumber: payload.purchaseOrderNumber,
                /** @type String */
                dateStart: payload.dateStart,
                /** @type String */
                dateEnd: payload.dateEnd,
                /** @type Object */
                statuses: payload.statuses,
                /** @type String */
                isbn: payload.isbn,
                /** @type String */
                locationId: payload.locationId,
                /** @type String */
                allLocations: payload.allLocations,
            } ),
        } );
    },

    /**
     * @GET
     * Get detailed information for a given invoiceId
     * Docs: http://apps01.dev.tol.tech/swagger/#!/Walnut_RWS_V1/getInvoiceById
     * @param { String } authToken
     * @param { Number } invoiceId
     * @returns { Promise }
     */
    getInvoiceDetails( { authToken, invoiceId } ) {
        return axios( {
            url: api.walnutUrl( 'invoice/getInvoice' ),
            method: 'get',
            headers: api.authHeaders( authToken ),
            params: {
                invoiceId,
            },
        } );
    },

    /**
     * @PUT
     * Update a given invoice
     * @param { String } authToken
     * @param { Object } invoice
     */
    updateInvoiceDetail( { authToken, invoice } ) {
        return axios( {
            url: api.walnutUrl( 'invoice/updateInvoice' ),
            method: 'put',
            headers: api.authHeaders( authToken ),
            data: invoice,
        } );
    },

    /**
     * @POST
     * Delete a given invoice
     * @param { String } authToken
     * @param { Number } invoiceId
     */
    deleteInvoiceDetail( { authToken, invoiceId } ) {
        return axios( {
            url: api.walnutUrl( 'invoice/delete' ),
            method: 'delete',
            headers: api.authHeaders( authToken ),
            params: {
                invoiceId,
            },
        } );
    },

    /**
     * @GET
     * Get Invoices by supplierId and supplierDocumentNumber
     * @param { String } authToken
     * @param { Number } supplierId
     * @param { String } supplierDocumentNumber
     */
    getInvoicesBySupplierAndDocumentNumber( {
        authToken, supplierId, supplierDocumentNumber, locationId,
    } ) {
        return axios( {
            url: api.walnutUrl( 'invoice/getInvoicesBySupplierAndDocumentNumber' ),
            method: 'get',
            headers: api.authHeaders( authToken ),
            params: {
                supplierId,
                supplierDocumentNumber,
                locationId,
            },
        } );
    },

    /**
     * @GET
     * Get associated purchase orders and their line items by invoiceId
     * @param { String } authToken
     * @param { Number } invoiceId
     */
    getAssociatedPurchaseOrdersByInvoiceId( { authToken, invoiceId } ) {
        return axios( {
            url: api.walnutUrl( 'invoice/getAssociatedPOsByInvoiceId' ),
            method: 'get',
            headers: api.authHeaders( authToken ),
            params: {
                invoiceId,
            },
        } );
    },

    /**
     * @PUT
     * Complete Associated Purchase Orders that have been selected for completion
     * @param { String } authToken
     * @param { String[] }
     */
    completeAssociatedPurchaseOrders( { authToken, invoiceId, poNumbers } ) {
        return axios( {
            url: api.walnutUrl( 'invoice/completeAssociatedPurchaseOrders' ),
            method: 'put',
            headers: api.authHeaders( authToken ),
            data: {
                invoiceId,
                poNumbers,
            },
        } );
    },
};
