export default {
    /**
     * @param { LocationDto } locationDto
     * @returns { LocationApi }
     */
    getLocationFromLocationDto( locationDto ) {
        if ( !locationDto ) {
            return null;
        }
        return {
            id: locationDto.id,
            name: locationDto.name,
        };
    },
    /**
     * @param { LocationApi } location
     * @returns { LocationDto }
     */
    getLocationDtoFromLocation( location ) {
        if ( !location ) {
            return null;
        }
        return {
            id: location.id,
            name: location.name,
        };
    },
};
