import SubscriptionApi from '@/api/SubscriptionApi';

export default {
    namespaced: true,
    state: {
        /**
         * @type {Array<{
         *      name: string,
         *      friendly: string,
         *      transferable: boolean,
         *      numberOfItemsLimit: number,
         *      costAmount: number,
         *      costType: string | null,
         *      digitalVendorId: number,
         *      digitalVendorName: string,
         *      vendorSku: string,
         *      active: boolean,
         *      productCode: string,
         *      subscriptionLibraryDto: Array<{
         *          name: string,
         *          friendly: string,
         *          digitalVendorId: number,
         *          digitalVendorName: string,
         *          active: boolean,
         *          manuallyManaged: boolean,
         *      }>,
         *      vendorManaged: boolean,
         *  }>}
         */
        subscriptions: [],
    },
    actions: {
        async fetchSubscriptions({ rootGetters, commit }) {
            try {
                const subscriptions = (await SubscriptionApi.allSubscriptions(rootGetters['User/authString'])).data;
                commit('SET_SUBSCRIPTIONS', subscriptions);
            } catch (error) {
                console.error('Error fetching subscriptions:', error);
            }
        },
    },
    mutations: {
        SET_SUBSCRIPTIONS(state, subscriptions) {
            state.subscriptions = subscriptions;
        },
    },
};
