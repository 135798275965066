import Vue from 'vue';
import App from './App';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@/plugins/component-library';
import '@/plugins/VCurrencyField';
import '@/plugins/date-picker';
import '@mdi/font/css/materialdesignicons.css';

import './sass/main.scss';
import '@/assets/sass/custom.scss';

Vue.config.productionTip = false;

new Vue( {
    router,
    store,
    vuetify,
    render: ( h ) => h( App ),
} ).$mount( '#app' );
