<template>
    <div id="app">
      <v-app>
        <TheNavDrawer/>
        <v-main>
            <router-view/>
        </v-main>
      </v-app>
    </div>
</template>

<script>
import TheNavDrawer from './views/common/TheNavDrawer';

export default {
    name: 'app',
    components: {
        TheNavDrawer,
    },
};
</script>

<style lang="scss">
    @import "@/assets/sass/app.scss";
    @import "@/assets/sass/sidebar.scss";

    /*
    * GENERAL
    */
    *{
        box-sizing: border-box;
    }
    html{
        font-size: 16px;
    }
    html,
    body,
    #app,
    .custom-container{
        min-height: 100vh;
        padding: 0;
        margin: 0;
    }

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    /*
    * GLOBAL LAYOUT CLASSES
    */
    .custom-container {
        max-width: 100vw;
        display: grid;
        grid-template-rows: 70px auto;
        grid-template-areas:
            "sidebar header"
            "sidebar main";
        &.sidebar-full {
            grid-template-columns: $sidebar-width-full 1fr;

            .page-heading-bar {
                width: 100%;
            }
        }

        &.sidebar-thin {
            grid-template-columns: $sidebar-width-thin 1fr;

            .page-heading-bar {
                width: 100%;
            }
        }
    }

    .page-heading-bar, .custom-container > .main {
        padding-left: 15px;
    }

    .custom-container > .main {
        overflow-x: auto;
        padding-top: 25px;
        padding-right: 25px;
        padding-left: 25px;
    }

    /*
    * GLOBAL BUTTON STYLING
    */
    .cancel-button {
        border: none;
        border-radius: 50%;
        float: right;
        background-color: $vue-cancel-button-bg;
        color: $vue-cancel-button-fg;
        height: 25px;
        width: 25px;
        transform: none;
    }
    .cancel-button:hover,
    .cancel-button:focus{
        cursor: pointer;
        box-shadow: 5px 5px 25px 0px $vue-button-shadow;
        outline: none;
    }

    .button {
        background-color: $vue-button-bg;
        color: $vue-button-fg;
        padding: 10px 20px;
        border-radius: 8px;
        font-size: 20px;
        font-weight: bold;
        box-shadow: 5px 5px 25px 0px $vue-button-shadow;
        margin-right: 25px;
        border: none;
        transition: all .2s ease-in-out;
        cursor: pointer;
    }
    .button:hover,
    .button:focus{
        cursor: pointer;
        outline: none;
        box-shadow: none;
    }
    .button:disabled {
        background-color: $vue-button-disabled-bg;
        color: $vue-button-disabled-fg;
        cursor: default;
    }
    .button:disabled:hover {
        transform: none;
        cursor: default;
    }
    .previous-button {
        background-color: $vue-button-previous-bg;
        color: $vue-button-previous-fg;
        height: 56px;
        width: 190px;
        border-radius: 8px;
        font-size: 20px;
        margin-right: 25px;
        border: none;
        cursor: pointer;
        transition: none;
        box-shadow: none;
    }
    .previous-button:disabled {
        background-color: $vue-button-previous-disabled-bg;
        color: $vue-button-previous-disabled-fg;
    }
    .previous-button:hover,
    .previous-button:disabled:hover {
        transform: none;
    }

    .reassign-button {
        background-color:transparent;
        color: $vue-button-reassign-fg;
        box-shadow: none;
        font-style: italic;
        border: none;
        font-size: 16px;
    }
    .reassign-button:disabled {
        background-color:transparent;
        color: $vue-button-reassign-disabled-fg;
        box-shadow: none;
        font-style: italic;
    }

    /*
   * GLOBAL CHECKBOX
   */

    /*
    * Search Result List
    */

    .search-results-container {
        text-align: left;
        border-bottom: 1px solid $vue-container-shadow;
        font-size: 14px;
        color: $vue-container-fg;
    }

    .search-results-heading {
        display: flex;
        color: $vue-container-heading-fg;
        border-bottom: .5px solid $vue-container-heading-border;
        padding-top: 20px;
        padding-bottom: 10px;
        text-align: left;
        font-size: 12px;
        z-index: 1;
    }

    .search-result-heading-flex-0 {
        flex: 0;
    }

    .search-result-heading-flex {
        flex: 1;
    }

    .search-result-heading-flex-2 {
        flex: 2;
    }

    .search-results-body {
        display: flex;
        flex-direction: row;
        margin:  -18px 0 0 50px;
        padding-bottom: 10px;
        justify-content: space-between;
        align-items: center;
        color: $vue-container-body-fg;
        padding-top: 10px;
    }

    /*
    * RANDOM S**T THAT SHOULD PROB BE DELETED OR PUSHED DOWN?
    */

    .detail-field-heading {
        color: $vue-container-heading-fg;
    }
    .column-title-heading {
        color: $vue-container-col-heading-fg;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: .5px;
        margin: 0 0 10px 0;
    }

    .column-title-text-detail{
        color: $vue-container-col-detail-fg;
        margin: 0;
        letter-spacing: .5px;
    }

    .detail-box {
        border: solid 1px $vue-container-detail-box-border;
        border-radius: 5px;
    }

    .box-shadow {
        /*box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.1);*/
        /* material-shadow-1 */
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }

    .page-info-top {
        padding-bottom: 5px;
        text-align: left;
    }

    .scrollable-title-list-container {
        overflow: auto;
        height: calc(100vh - 335px);
        padding-bottom: 40px;
    }

    .result-count-bottom-border {
        border-bottom: 1px solid $vue-detail-result-border;
    }

    .designator-column-list {
        display: flex;
        flex-direction: column
    }

    .warning-input {
        border: 2px solid $vue-warning;
    }

    .required-input {
        border: 2px solid $vue-required;
    }

    .last-viewed-item {
        border-left: 5px solid $vue-last-view-border;
    }

    .is-selected {
        background-color: $vue-selected-bg;
    }

    /* Vuetify Overrides */
    // Ensures that the text in input fields and selects have space on the left for vuetify
    // Negative margin keeps the vuetify icon placement correct
    #app .v-text-field.v-input--dense:not(.v-text-field--outlined) input,
    #app .v-select.v-input--dense .v-select__selection--comma {
        padding-left: 0.5rem;
        margin-right: -1rem;
    }

    // Overrides v-autocomplete highlighting
    #app .theme--light.v-list-item .v-list-item__mask {
        color: rgba(0,0,0,0.81);
        background: $bright-yellow;
    }

    // Common Sidebar Stylings
    //TODO: Refactor TheSidebar so that these stylings can be put inside that file
    #app .sidebar-container {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        margin: 0 1.25rem;
        text-align: left;

        .v-input .v-input__control {
            border-radius: 4px;
        }
        .tooltip__icon {
            color: white;
        }

        &__section {
            padding-top: 1rem;
            padding-bottom: 1rem;
            &:not(:last-child) {
                border-bottom: solid 1px white;
            }
        }

        &__header {
            font-size: 18px;
            h3 {
                font-size: 18px;
            }
        }
    }
</style>
