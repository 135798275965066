import axios from './config/axios';
import api from './config/api';

export default {
    /**
     * @POST
     */
    addTitleToGroup( { authToken, isbn, titleGroupId } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/titleGroups/addTitleToGroup' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            params: {
                isbn,
                titleGroupId,
            },
        } );
    },

    /**
     * @POST
     */
    dissolveEntireTitleGroup( { authToken, titleGroupId } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/titleGroups/dissolveEntireTitleGroup' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            params: {
                titleGroupId,
            },
        } );
    },

    /**
     * @GET
     */
    getRelatedTitlesInGroup( { authToken, isbn } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/titleGroups/getRelatedTitlesInGroup' ),
            method: 'get',
            headers: api.authHeaders( authToken ),
            params: {
                isbn,
            },
        } );
    },

    /**
     * @GET
     * Get the Status Options for a Title Group
     * Docs: http://apps03.qa.tol.tech/swagger/#!/Title_Research_RWS_V1/getGroupStatusOptions
     * @returns { AxiosPromise }
     */
    getGroupStatusOptions( { authToken } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/titleGroups/getGroupStatusOptions' ),
            method: 'get',
            headers: api.authHeaders( authToken ),
        } );
    },

    /**
     * @POST
     */
    removeFromTitleGroup( { authToken, titleIdList } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/titleGroups/removeFromTitleGroup' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            // Shoot across an array, not an object with the attribute 'titleIdList' with an array
            data: titleIdList,
        } );
    },

    /**
     * @POST
     */
    removeManualFlagFromTitleInGroup( { authToken, titleId } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/titleGroups/removeManualFlagFromTitleInGroup' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            params: {
                titleId,
            },
        } );
    },
    createTitleGroup( { authToken, titleId } ) {
        return axios( {
            url: api.baseUrl( 'v1/titleGroupingForProductCode' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            params: {
                productCode: titleId,
            },
        } );
    },
    updateGroupStatus( { authToken, titleId, statusName } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/title-research-info/saveTitleGroupingCheck' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            params: {
                titleId,
                groupingStatusName: statusName,
            },
        } );
    },
    getTitleGroupStatusByTitleId( { authToken, titleId } ) {
        return axios( {
            url: api.cmaUrl( 'titleResearch/titleGroups/getTitleGroupingStatus' ),
            method: 'get',
            headers: api.authHeaders( authToken ),
            params: {
                titleId,
            },
        } );
    },
};
