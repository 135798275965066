export default {
    path: '/login',
    name: 'Login',
    meta: {
        title: 'Login',
        requiresAuth: false,
    },
    props: ( route ) => ( { next: route.query.next } ),
    component: () => import( /* webpackChunkName: "Login" */ '@/views/Login' ),
};
