// Api
import CatalogPricingApi from '@/api/CatalogPricingApi';
import TitleApi from '@/api/TitleApi';

// Utils
import ObjectUtil from '@/utils/ObjectUtil';
import StoreUtil from '@/utils/StoreUtil';

export default {
    namespaced: true,
    state: {
        /** @type { String[] } */
        assignmentStatusList: [],

        /** @type { String[] } */
        catalogListingStatusList: [],

        /** @type { location[] } */
        locationList: [],

        /** @type { Object } - { inclusiveAccessFulfillmentType[] } normalized by 'name' */
        inclusiveAccessFulfillmentTypes: {},

        /** @type { primarySupplier[] } */
        primarySupplierList: [],

        /** @type { titleDesignator[] } */
        titleDesignatorList: [],

        /** @type { subscriptionLibrary[] } */
        slingshotLibraries: [],
        /** @type { subscriptionLibrary[] } */
        vendorLibraries: [],
    },
    getters: {
        /** @returns { Number } */
        assignmentStatusListCount: (state) => state.assignmentStatusList.length,
        /** @returns { Boolean } */
        hasAssignmentStatusList: (state, getters) => getters.assignmentStatusListCount.length > 0,

        /** @returns { Number } */
        catalogListingStatusListCount: (state) => state.catalogListingStatusList.length,
        /** @returns { Boolean } */
        hasCatalogListingStatusList: (state, getters) => getters.catalogListingStatusListCount > 0,

        /** @returns { Number } */
        locationListCount: (state) => state.locationList.length,
        /** @returns { Boolean } */
        hasLocationList: (state, getters) => getters.locationListCount > 0,

        /** @returns { inclusiveAccessFulfillmentType[] } */
        fulfillmentTypeList: (state) => Object.values(state.inclusiveAccessFulfillmentTypes),
        /** @returns { Boolean } */
        hasFulfillmentTypes: (state, getters) => getters.fulfillmentTypeList.length > 0,

        /** @returns { Number } */
        primarySupplierListCount: (state) => state.primarySupplierList.length,
        /** @returns { Boolean } */
        hasPrimarySupplierList: (state, getters) => getters.primarySupplierListCount > 0,

        /** @returns { Number } */
        titleDesignatorListCount: (state) => state.titleDesignatorList.length,
        /** @returns { Boolean } */
        hasTitleDesignatorList: (state, getters) => getters.titleDesignatorListCount > 0,
        /** @returns { Boolean } */
        hasNoTitleDesignators: (state, getters) => !getters.hasTitleDesignatorList,

        catalogListingDesignators: (state) => state.titleDesignatorList.filter((item) => item.catalogListingEnabled),
    },
    mutations: {
        SET_FILTERS(state, filters) {
            state.assignmentStatusList = filters.assignmentStatusList;
            state.catalogListingStatusList = filters.catalogListingStatusList;
            state.locationList = filters.locationList;
            state.primarySupplierList = filters.primarySupplierList;
            state.titleDesignatorList = filters.titleDesignatorList;
            state.slingshotLibraries = filters.slingshotLibraries;
            state.vendorLibraries = filters.vendorLibraries;
        },
        SET_PRIMARY_SUPPLIER_LIST(state, { primarySupplierList }) {
            state.primarySupplierList = primarySupplierList;
        },
        SET_INCLUSIVE_ACCESS_FULFILLMENT_TYPES(state, { fulfillmentTypes }) {
            state.inclusiveAccessFulfillmentTypes = fulfillmentTypes;
        },
    },
    actions: {
        reset({ commit }) {
            commit('SET_FILTERS', {
                assignmentStatusList: [],
                catalogListingStatusList: [],
                locationList: [],
                primarySupplierList: [],
                titleDesignatorList: [],
            });
            commit('SET_PRIMARY_SUPPLIER_LIST', { primarySupplierList: [] });
            commit('SET_INCLUSIVE_ACCESS_FULFILLMENT_TYPES', { fulfillmentTypes: {} });
        },
        async getFilters({ rootGetters, commit, dispatch }) {
            try {
                const { data } = await TitleApi.getFilterOptions({
                    authToken: rootGetters[ 'User/authString' ],
                });

                commit('SET_FILTERS', {
                    assignmentStatusList: data.assignmentStatuses,
                    catalogListingStatusList: data.catalogListingStatuses,
                    locationList: data.locations.filter((location) => !location.name.includes('Remote')),
                    primarySupplierList: data.primarySuppliers,
                    titleDesignatorList: data.titleDesignators,
                    slingshotLibraries: data.subscriptionLibraryDtos?.length ? data.subscriptionLibraryDtos.filter((library) => library.manuallyManaged) : [],
                    vendorLibraries: data.subscriptionLibraryDtos?.length ? data.subscriptionLibraryDtos.filter((library) => !library.manuallyManaged) : [],
                });
            } catch (error) {
                const message = `Failed to get Filter Options - ${StoreUtil.error.getMessage(error)}`;
                dispatch('setErrorNotification', message, { root: true });
            }
        },

        async getInclusiveAccessFulfillmentTypes({ rootGetters, commit }) {
            try {
                const { data } = await CatalogPricingApi.getFulfillmentTypes({
                    authToken: rootGetters[ 'User/authString' ],
                });
                commit('SET_INCLUSIVE_ACCESS_FULFILLMENT_TYPES', {
                    fulfillmentTypes: ObjectUtil.normalize(data.fulfillmentTypes, 'name'),
                });
            } catch (error) {
                console.error('Error in actionTypes.getInclusiveAccessFulfillmentTypes: ', error);
            }
        },

        async init({ dispatch, getters }) {
            await dispatch('getFilters');
            // Don't await - just fire!
            if (!getters.hasFulfillmentTypes) dispatch('getInclusiveAccessFulfillmentTypes');
            dispatch('Invoice/getInvoiceStatusList', {}, { root: true });
        },
    },
};
