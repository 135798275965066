import Vue from 'vue';

// Utils
import FormUtil from '@/utils/FormUtil';
import FormatUtil from '@/utils/FormatUtil';
import ObjectUtil from '@/utils/ObjectUtil';

const actionTypes = {
    purchaseOrderModifyPostHook: 'purchaseOrderModifyPostHook',
    prepareCurrentPurchaseOrderLineItems: 'prepareCurrentPurchaseOrderLineItems',
    removeMovedLineItemsFromCurrentPurchaseOrder: 'removeMovedLineItemsFromCurrentPurchaseOrder',
    setCurrentPurchaseOrder: 'setCurrentPurchaseOrder',
    updateCurrentPurchaseOrderLineItemProperty: 'updateCurrentPurchaseOrderLineItemProperty',
    toggleLineItemsBackordered: 'toggleLineItemsBackordered',
};

const mutationTypes = {
    SET_CURRENT_PURCHASE_ORDER: 'SET_CURRENT_PURCHASE_ORDER',
    SET_CURRENT_PURCHASE_ORDER_CONFIRMATION_ID: 'SET_CURRENT_PURCHASE_ORDER_CONFIRMATION_ID',
    SET_CURRENT_PURCHASE_ORDER_DESCRIPTION: 'SET_CURRENT_PURCHASE_ORDER_DESCRIPTION',
    SET_CURRENT_PURCHASE_ORDER_EXPECTED_RECEIVE_DATE: 'SET_CURRENT_PURCHASE_ORDER_EXPECTED_RECEIVE_DATE',
    SET_CURRENT_PURCHASE_ORDER_FEES: 'SET_CURRENT_PURCHASE_ORDER_FEES',
    SET_CURRENT_PURCHASE_ORDER_SHIPPING_COST: 'SET_CURRENT_PURCHASE_ORDER_SHIPPING_COST',
    SET_CURRENT_PURCHASE_ORDER_SUBTOTAL: 'SET_CURRENT_PURCHASE_ORDER_SUBTOTAL',
    SET_CURRENT_PURCHASE_ORDER_STATUS: 'SET_CURRENT_PURCHASE_ORDER_STATUS',
    SET_CURRENT_PURCHASE_ORDER_SUPPLIER: 'SET_CURRENT_PURCHASE_ORDER_SUPPLIER',
    SET_CURRENT_PURCHASE_ORDER_TRACKING_NUMBERS: 'SET_CURRENT_PURCHASE_ORDER_TRACKING_NUMBERS',
    SET_CURRENT_PURCHASE_ORDER_TRANSMIT_DATE: 'SET_CURRENT_PURCHASE_ORDER_TRANSMIT_DATE',
    SET_CURRENT_PURCHASE_ORDER_USE_CREDIT_CARD: 'SET_CURRENT_PURCHASE_ORDER_USE_CREDIT_CARD',
    SET_CURRENT_PURCHASE_ORDER_CREDIT_CARD_LAST_FOUR: 'SET_CURRENT_PURCHASE_ORDER_CREDIT_CARD_LAST_FOUR',
    SET_CURRENT_PURCHASE_ORDER_CREDIT_CARD_NOTE: 'SET_CURRENT_PURCHASE_ORDER_CREDIT_CARD_NOTE',

    UPDATE_CURRENT_PURCHASE_ORDER_LINE_ITEM_PROPERTY: 'UPDATE_CURRENT_PURCHASE_ORDER_LINE_ITEM_PROPERTY',

    PREPARE_CURRENT_PURCHASE_ORDER_LINE_ITEMS: 'PREPARE_CURRENT_PURCHASE_ORDER_LINE_ITEMS',

    ADD_CURRENT_PURCHASE_ORDER_LINE_ITEM: 'ADD_CURRENT_PURCHASE_ORDER_LINE_ITEM',
    REMOVE_CURRENT_PURCHASE_ORDER_LINE_ITEM: 'REMOVE_CURRENT_PURCHASE_ORDER_LINE_ITEM',
    CLEAR_CURRENT_PURCHASE_ORDER: 'CLEAR_CURRENT_PURCHASE_ORDER',
    CURRENT_PURCHASE_ORDER_INITIALIZE_CREDIT_CARD_DTO: 'CURRENT_PURCHASE_ORDER_INITIALIZE_CREDIT_CARD_DTO',

    /**
     * Purchase Order endpoint action related mutations
     */
    PURCHASE_ORDER_MODIFY_POST_HOOK: 'PURCHASE_ORDER_MODIFY_POST_HOOK',
    REMOVE_MOVED_LINE_ITEMS_FROM_CURRENT_PURCHASE_ORDER: 'REMOVE_MOVED_LINE_ITEMS_FROM_CURRENT_PURCHASE_ORDER',
};

/**
 * Recursively find modifications between a reference and possibly modified value
 * Returns the modified value if there is a difference, otherwise return null
 * @param { purchaseOrder } reference
 * @param { Partial<purchaseOrder> } modified
 * @returns { Partial<purchaseOrder>[] | Partial<purchaseOrder> | null }
 */
function modifications( reference, modified ) {
    if ( ( reference instanceof Array ) && ( modified instanceof Array ) ) {
        // stringify & parse, so we don't inadvertently sort 'reference' or 'modified' arrays outside of this function
        const modifiedSorted = JSON.parse( JSON.stringify( modified ) )
            .sort();
        const changes = JSON.parse( JSON.stringify( reference ) )
            .sort()
            .map( ( item, index ) => modifications( item, modifiedSorted[ index ] ) )
            .filter( ( item ) => item !== null );
        return changes.length === 0
            ? null
            : changes;
    }
    if ( ( reference instanceof Object ) && ( modified instanceof Object ) ) {
        const referenceKeys = Object.keys( reference );
        const changes = {};
        referenceKeys.forEach( ( key ) => {
            const value = modifications( reference[ key ], modified[ key ] );
            if ( value !== null ) {
                changes[ key ] = value;
            }
        } );
        return Object.keys( changes ).length === 0
            ? null
            : changes;
    }
    return reference === modified
        ? null
        : modified;
}

/**
 * Param objects must have all the same keys, and their values must all be primitives
 * @returns { Object || Null }
 */
function modifiedObject( referenceObject, mutableObject ) {
    const keys = Object.keys( referenceObject );
    let isDifferent = false;
    keys.forEach( ( key ) => {
        if ( referenceObject[ key ] !== mutableObject[ key ] ) {
            isDifferent = true;
        }
    } );
    return isDifferent
        ? mutableObject
        : null;
}

export default {
    namespaced: true,
    state: {
        /** @type { purchaseOrder } */
        currentPurchaseOrder: {},
    },
    getters: {
        /** @returns { Boolean } */
        hasCurrentPurchaseOrder: ( state ) => ObjectUtil.isNotEmpty( state.currentPurchaseOrder ),
        /** @returns { Boolean } */
        currentPurchaseOrderHasItems: ( state, getters, rootState, rootGetters ) => (
            getters.hasCurrentPurchaseOrder
            && rootGetters[ 'PurchaseOrder/hasItems' ]( state.currentPurchaseOrder )
        ),
        /** @returns { Boolean } */
        currentPurchaseOrderHasNoItems: ( state, getters, rootState, rootGetters ) => (
            getters.hasCurrentPurchaseOrder
            && rootGetters[ 'PurchaseOrder/hasNoItems' ]( state.currentPurchaseOrder )
        ),
        /** @returns { Boolean } */
        currentPurchaseOrderItemsAreValid: ( state, getters ) => {
            if ( !getters.hasCurrentPurchaseOrder ) {
                return true;
            }
            return (
                getters.currentPurchaseOrderHasItems
                && state.currentPurchaseOrder.purchaseOrderItemDTOList
                    .filter( ( lineItem ) => ( lineItem.productCode === '' ) || ( lineItem.requestedPrice === '' ) )
                    .length === 0
            ) || getters.currentPurchaseOrderHasNoItems;
        },
        /** @returns { import('@/typedefs/purchaseOrderItem').purchaseOrderItem[] } */
        modifiablePurchaseOrderLineItemList: ( state, getters ) => (
            getters.currentPurchaseOrderHasItems
                ? state.currentPurchaseOrder.purchaseOrderItemDTOList.filter( ( item ) => item.toBeDeleted === false )
                : []
        ),
        /** @returns { Boolean } */
        allLineItemsBackordered: ( state ) => (
            state.currentPurchaseOrder.purchaseOrderItemDTOList
                .filter( ( item ) => item.toBeDeleted === false )
                .every( ( item ) => item.backordered === true )
        ),
        /** @returns { Boolean } */
        someLineItemsBackordered: ( state ) => (
            state.currentPurchaseOrder.purchaseOrderItemDTOList
                .filter( ( item ) => item.toBeDeleted === false )
                .some( ( item ) => item.backordered === true )
        ),
        /** @returns { Function } => { Boolean } */
        purchaseOrderLineItemsAreAddressable: () => ( purchaseOrder ) => (
            ( 'number' in purchaseOrder ) && ( 'purchaseOrderItemDTOList' in purchaseOrder )
        ),
        /** @returns { Function } => { Number } */
        getCurrentPurchaseOrderLineItemIndex: ( state, getters ) => ( frontendKey ) => (
            getters.purchaseOrderLineItemsAreAddressable( state.currentPurchaseOrder )
                ? state.currentPurchaseOrder.purchaseOrderItemDTOList.findIndex( ( lineItem ) => lineItem.frontendKey === frontendKey )
                : -1
        ),
        /** @returns { Boolean } */
        currentPurchaseOrderHasDuplicateItems: ( state, getters, rootState, rootGetters ) => (
            getters.hasCurrentPurchaseOrder
            && rootGetters[ 'PurchaseOrder/hasConflictingItems' ]( state.currentPurchaseOrder )
        ),

        /** @returns { String } */
        currentPurchaseOrderSubtotal: ( state, getters ) => {
            if ( getters.currentPurchaseOrderHasNoItems ) {
                return FormatUtil.toCurrency( 0 );
            }
            const subTotal = state.currentPurchaseOrder.purchaseOrderItemDTOList
                .map( ( lineItem ) => lineItem.requestedPrice * lineItem.requestedQuantity )
                .reduce( ( accumulator, currentValue ) => accumulator + currentValue );
            return FormatUtil.toCurrency( subTotal );
        },
        /** @returns { String } */
        currentPurchaseOrderTotal: ( state, getters ) => {
            // Treat null values as 0
            const total = parseFloat( getters.currentPurchaseOrderSubtotal )
                + ( state.currentPurchaseOrder.fees === null ? 0 : state.currentPurchaseOrder.fees )
                + ( state.currentPurchaseOrder.shippingCost === null ? 0 : state.currentPurchaseOrder.shippingCost );
            return FormatUtil.toCurrency( total );
        },

        /** @returns { import('@/typedefs/purchaseOrder').purchaseOrder } */
        referencePurchaseOrder: ( state, getters, rootState, rootGetters ) => (
            rootGetters[ 'PurchaseOrder/hasPurchaseOrders' ]
            && getters.hasCurrentPurchaseOrder
            && rootState.PurchaseOrder.list
                .map( ( purchaseOrder ) => purchaseOrder.number )
                .includes( state.currentPurchaseOrder.number )
                ? rootState.PurchaseOrder.list.find( ( purchaseOrder ) => purchaseOrder.number === state.currentPurchaseOrder.number )
                : null
        ),

        /**
         * Create a uniform array of purchaseOrderItemDTOList items.
         * Sort the line item objects by id within the array so
         * we can compare 'apples to apples' in modifications
           */
        getPurchaseOrderLineItemMap: ( state, getters ) => ( purchaseOrder ) => (
            getters.purchaseOrderLineItemsAreAddressable( purchaseOrder )
                ? [ ...purchaseOrder.purchaseOrderItemDTOList ]
                    .map( ( item ) => ( {
                        id: item.id || '',
                        backordered: item?.backordered ?? false,
                        condition: item.condition,
                        productCode: item.productCode,
                        titleDescription: item.titleDescription || '',
                        requestedQuantity: +item.requestedQuantity || 0,
                        requestedPrice: item.requestedPrice || 0,
                        expectedReceiveDate: item.expectedReceiveDate || '',
                        note: item.note || '',
                        toBeDeleted: item?.toBeDeleted ?? false,
                        frontendIsNewLine: item?.frontendIsNewLine ?? false,
                    } ) )
                    .sort( ( a, b ) => ( a.id < b.id ? 1 : -1 ) )
                : []
        ),

        modifiedPurchaseOrderLineItems: ( state, getters ) => {
            if ( !getters.purchaseOrderLineItemsAreAddressable( state.currentPurchaseOrder )
                || !getters.purchaseOrderLineItemsAreAddressable( getters.referencePurchaseOrder ) ) {
                return [];
            }
            const currentPurchaseOrderLineItems = getters.getPurchaseOrderLineItemMap( state.currentPurchaseOrder );
            const referencePurchaseOrderLineItems = getters.getPurchaseOrderLineItemMap( getters.referencePurchaseOrder );
            const modifiedItems = [];
            currentPurchaseOrderLineItems.forEach( ( item, index ) => {
                if ( item.frontendIsNewLine ) {
                    modifiedItems.push( item );
                } else {
                    const changes = modifiedObject( referencePurchaseOrderLineItems[ index ], item );
                    if ( changes ) {
                        modifiedItems.push( changes );
                    }
                }
            } );
            return modifiedItems;
        },
        modifiedNewLineItems: ( state, getters ) => (
            getters.modifiedPurchaseOrderLineItems.filter( ( item ) => item.frontendIsNewLine )
        ),

        /** @returns { Partial<purchaseOrder> || null } */
        modifications: ( state, getters ) => {
            if ( !getters.hasCurrentPurchaseOrder || !getters.referencePurchaseOrder ) {
                return null;
            }
            const purchaseOrderModifications = modifications( getters.referencePurchaseOrder, state.currentPurchaseOrder );
            // We only want purchaseOrderItemDTOList values from getters.modifiedPurchaseOrderLineItems, discard anything found by modifications()
            const { purchaseOrderItemDTOList, ...purchaseOrderModificationsWithoutLineItems } = purchaseOrderModifications || {};
            return Object.keys( purchaseOrderModificationsWithoutLineItems ).length > 0 || getters.modifiedPurchaseOrderLineItems.length > 0
                ? {
                    ...purchaseOrderModificationsWithoutLineItems,
                    ...getters.modifiedPurchaseOrderLineItems.length > 0
                        ? {
                            purchaseOrderItemDTOList: getters.modifiedPurchaseOrderLineItems,
                        }
                        : {},
                }
                : null;
        },
        /** @returns { Boolean } */
        hasModifications: ( state, getters ) => getters.modifications !== null,

        modificationsWithoutNewLines: ( state, getters ) => {
            if ( !getters.hasModifications ) {
                return null;
            }
            return 'purchaseOrderItemDTOList' in getters.modifications
                ? {
                    ...getters.modifications,
                    purchaseOrderItemDTOList: getters.modifications.purchaseOrderItemDTOList.filter( ( item ) => !item.frontendIsNewLine ),
                }
                : getters.modifications;
        },

        /** @returns { Boolean } */
        hasCurrentPurchaseOrderStatusChanged: ( state, getters ) => (
            getters.hasModifications
            && ( 'status' in getters.modifications )
        ),
        /** @returns { Boolean } */
        hasPurchaseOrderItemNoteChanged: ( state, getters ) => (
            getters.hasModifications
            && ( 'purchaseOrderItemDTOList' in getters.modifications )
            && getters.modifications.purchaseOrderItemDTOList.some( ( item ) => 'note' in item )
        ),
        readOnlyStatusChanges: ( state, getters ) => (
            [
                getters.hasCurrentPurchaseOrderStatusChanged,
                // getters.hasPurchaseOrderItemNoteChanged,
            ]
        ),
        /** @returns { Boolean } */
        hasOnlyReadOnlyStatusChanges: ( state, getters ) => (
            getters.hasModifications
            && getters.readOnlyStatusChanges.some( ( bool ) => bool )
            && Object.keys( getters.modifications ).length === getters.readOnlyStatusChanges.filter( ( bool ) => bool ).length
        ),
        /** @returns { Boolean } */
        referencePurchaseOrderInReadOnlyStatus: ( state, getters ) => (
            getters.hasCurrentPurchaseOrder
            && getters.referencePurchaseOrder
            && [ 'OPEN', 'CLOSED', 'COMPLETE', 'CANCELED' ].includes( getters.referencePurchaseOrder.status )
        ),
        /** @returns { Boolean } */
        mustPromptToAllowReadOnlyStatusChanges: ( state, getters ) => (
            getters.referencePurchaseOrderInReadOnlyStatus
            && getters.hasModifications
            && !getters.hasOnlyReadOnlyStatusChanges
        ),

        /** @returns { Boolean } */
        isPurchaseOrderStatusComplete: ( state, getters ) => (
            getters.referencePurchaseOrder
            && getters.referencePurchaseOrder.status === 'COMPLETE'
        ),

        /** @returns { Boolean } */
        currentPurchaseOrderCannotHaveLineItemsAdded: ( state, getters ) => (
            getters.hasCurrentPurchaseOrder
            && [ 'RECONCILE', 'RECEIVED', 'SUBMITTED' ].includes( state.currentPurchaseOrder.status )
        ),
        /** @returns { Array } */
        promptableLineItemChanges: ( state, getters ) => {
            const promptableChanges = [];
            const purchaseOrderItemList = getters.currentPurchaseOrderCannotHaveLineItemsAdded
                && getters.hasModifications
                && 'purchaseOrderItemDTOList' in getters.modificationsWithoutNewLines
                ? getters.modificationsWithoutNewLines.purchaseOrderItemDTOList
                : [];
            if ( purchaseOrderItemList.length === 0 ) {
                return [];
            }
            purchaseOrderItemList
                .filter( ( item ) => ( item.toBeDeleted === false ) )
                .forEach( ( item ) => {
                    const referencePurchaseOrderLineItem = getters.referencePurchaseOrder.purchaseOrderItemDTOList
                        .find( ( purchaseOrder ) => purchaseOrder.id === item.id );
                    if ( item.requestedPrice !== referencePurchaseOrderLineItem?.requestedPrice ) {
                        promptableChanges.push( {
                            condition: item.condition,
                            productCode: item.productCode,
                            type: 'Price',
                            originalValue: referencePurchaseOrderLineItem
                                ? referencePurchaseOrderLineItem.requestedPrice
                                : 'None',
                            newValue: item.requestedPrice,
                        } );
                    }
                    if ( item.requestedQuantity !== referencePurchaseOrderLineItem?.requestedQuantity ) {
                        promptableChanges.push( {
                            condition: item.condition,
                            productCode: item.productCode,
                            type: 'Quantity',
                            originalValue: referencePurchaseOrderLineItem
                                ? referencePurchaseOrderLineItem.requestedQuantity
                                : 'None',
                            newValue: item.requestedQuantity,
                        } );
                    }
                } );
            return promptableChanges;
        },
        /** @returns { Boolean } */
        mustPromptToAllowLineItemChangesToBeSaved: ( state, getters ) => (
            getters.currentPurchaseOrderCannotHaveLineItemsAdded
            && getters.promptableLineItemChanges.length > 0
        ),
        /** @returns { Boolean } */
        mustPromptToShowLineItemsCannotBeModified: ( state, getters ) => (
            getters.currentPurchaseOrderCannotHaveLineItemsAdded
            && getters.modifiedNewLineItems.length > 0
        ),
        /** @returns { Boolean } */
        mustPromptWithModalBeforeSave: ( state, getters ) => (
            getters.mustPromptToAllowReadOnlyStatusChanges
            || getters.mustPromptToAllowLineItemChangesToBeSaved
            || getters.mustPromptToShowLineItemsCannotBeModified
        ),
    },
    actions: {
        [ actionTypes.purchaseOrderModifyPostHook ]( context, { purchaseOrder } ) {
            context.commit( mutationTypes.PURCHASE_ORDER_MODIFY_POST_HOOK, { purchaseOrder } );
        },
        [ actionTypes.prepareCurrentPurchaseOrderLineItems ]( context ) {
            context.commit( mutationTypes.PREPARE_CURRENT_PURCHASE_ORDER_LINE_ITEMS );
        },
        [ actionTypes.setCurrentPurchaseOrder ]( context, { purchaseOrder } ) {
            context.commit( mutationTypes.SET_CURRENT_PURCHASE_ORDER, {
                purchaseOrder: context.rootGetters[ 'PurchaseOrder/sanitizePurchaseOrder' ]( purchaseOrder ),
            } );
        },
        async [ actionTypes.removeMovedLineItemsFromCurrentPurchaseOrder ]( context, { itemIDList } ) {
            context.commit( mutationTypes.REMOVE_MOVED_LINE_ITEMS_FROM_CURRENT_PURCHASE_ORDER, { itemIDList } );
        },
        [ actionTypes.updateCurrentPurchaseOrderLineItemProperty ]( context, { frontendKey, property, value } ) {
            context.commit( mutationTypes.UPDATE_CURRENT_PURCHASE_ORDER_LINE_ITEM_PROPERTY, {
                lineItemIndex: context.getters.getCurrentPurchaseOrderLineItemIndex( frontendKey ),
                property,
                value,
            } );
        },
        [ actionTypes.toggleLineItemsBackordered ]( context ) {
            // Grab the value before it changes in the below foreach
            const allItemsBackordered = context.getters.allLineItemsBackordered;
            context.state.currentPurchaseOrder.purchaseOrderItemDTOList.forEach( ( item ) => {
                context.commit( mutationTypes.UPDATE_CURRENT_PURCHASE_ORDER_LINE_ITEM_PROPERTY, {
                    lineItemIndex: context.getters.getCurrentPurchaseOrderLineItemIndex( item.frontendKey ),
                    property: 'backordered',
                    value: !allItemsBackordered,
                } );
            } );
        },
    },
    mutations: {
        /**
         * currentPurchaseOrder
         */
        [ mutationTypes.CLEAR_CURRENT_PURCHASE_ORDER ]( state ) {
            state.currentPurchaseOrder = {};
        },
        [ mutationTypes.SET_CURRENT_PURCHASE_ORDER ]( state, { purchaseOrder } ) {
            state.currentPurchaseOrder = ObjectUtil.deepClone( purchaseOrder );
        },
        [ mutationTypes.SET_CURRENT_PURCHASE_ORDER_CONFIRMATION_ID ]( state, { confirmationId } ) {
            state.currentPurchaseOrder.confirmationId = confirmationId;
        },
        [ mutationTypes.SET_CURRENT_PURCHASE_ORDER_DESCRIPTION ]( state, { description } ) {
            state.currentPurchaseOrder.description = description;
        },
        [ mutationTypes.SET_CURRENT_PURCHASE_ORDER_EXPECTED_RECEIVE_DATE ]( state, { expectedReceiveDate } ) {
            state.currentPurchaseOrder.expectedReceiveDate = expectedReceiveDate;
        },
        [ mutationTypes.SET_CURRENT_PURCHASE_ORDER_FEES ]( state, { fees } ) {
            state.currentPurchaseOrder.fees = fees;
            state.currentPurchaseOrder.purchaseOrderCreditCardDTO.fees = fees;
        },
        [ mutationTypes.SET_CURRENT_PURCHASE_ORDER_SHIPPING_COST ]( state, { shippingCost } ) {
            state.currentPurchaseOrder.shippingCost = shippingCost;
            state.currentPurchaseOrder.purchaseOrderCreditCardDTO.shipping = shippingCost;
        },
        [ mutationTypes.SET_CURRENT_PURCHASE_ORDER_SUBTOTAL ]( state, { originalSubtotal } ) {
            state.currentPurchaseOrder.purchaseOrderCreditCardDTO.originalSubtotal = originalSubtotal;
        },
        [ mutationTypes.CURRENT_PURCHASE_ORDER_INITIALIZE_CREDIT_CARD_DTO ]( state, { originalSubtotal } ) {
            state.currentPurchaseOrder.purchaseOrderCreditCardDTO.fees = state.currentPurchaseOrder.fees;
            state.currentPurchaseOrder.purchaseOrderCreditCardDTO.shipping = state.currentPurchaseOrder.shippingCost;
            state.currentPurchaseOrder.purchaseOrderCreditCardDTO.originalSubtotal = originalSubtotal;
        },
        [ mutationTypes.SET_CURRENT_PURCHASE_ORDER_STATUS ]( state, { status } ) {
            state.currentPurchaseOrder.status = status;
        },
        [ mutationTypes.SET_CURRENT_PURCHASE_ORDER_SUPPLIER ]( state, { supplier } ) {
            state.currentPurchaseOrder.supplier = supplier;
        },
        [ mutationTypes.SET_CURRENT_PURCHASE_ORDER_TRACKING_NUMBERS ]( state, { trackingNumbers } ) {
            state.currentPurchaseOrder.trackingNumbers = trackingNumbers;
        },
        [ mutationTypes.SET_CURRENT_PURCHASE_ORDER_TRANSMIT_DATE ]( state, { transmitDate } ) {
            state.currentPurchaseOrder.transmitDate = transmitDate;
        },
        [ mutationTypes.SET_CURRENT_PURCHASE_ORDER_USE_CREDIT_CARD ]( state, { useCreditCard } ) {
            state.currentPurchaseOrder.useCreditCard = useCreditCard;
        },
        [ mutationTypes.SET_CURRENT_PURCHASE_ORDER_CREDIT_CARD_LAST_FOUR ]( state, { lastFour } ) {
            Vue.set(
                state.currentPurchaseOrder.purchaseOrderCreditCardDTO,
                'lastFour',
                lastFour,
            );
        },
        [ mutationTypes.SET_CURRENT_PURCHASE_ORDER_CREDIT_CARD_NOTE ]( state, { note } ) {
            Vue.set(
                state.currentPurchaseOrder.purchaseOrderCreditCardDTO,
                'note',
                note,
            );
        },

        [ mutationTypes.UPDATE_CURRENT_PURCHASE_ORDER_LINE_ITEM_PROPERTY ]( state, { lineItemIndex, property, value } ) {
            Vue.set(
                state.currentPurchaseOrder.purchaseOrderItemDTOList[ lineItemIndex ],
                property,
                value,
            );
        },

        [ mutationTypes.PREPARE_CURRENT_PURCHASE_ORDER_LINE_ITEMS ]( state ) {
            // Add frontendKey and frontendIsNewLine to the existing purchaseOrderItemDTOList for tracking
            state.currentPurchaseOrder.purchaseOrderItemDTOList = state.currentPurchaseOrder.purchaseOrderItemDTOList
                .map( ( lineItem ) => ( {
                    backordered: lineItem?.backordered ?? false,
                    condition: lineItem.condition,
                    expectedReceiveDate: lineItem.expectedReceiveDate,
                    id: lineItem.id,
                    note: lineItem.note,
                    productCode: lineItem.productCode,
                    requestedPrice: lineItem.requestedPrice,
                    requestedQuantity: lineItem.requestedQuantity,
                    titleDescription: lineItem.titleDescription,
                    toBeDeleted: false,
                    frontendKey: FormUtil.generateUidForPurchaseOrderLineItem( lineItem ),
                    frontendIsNewLine: false,
                } ) );
        },

        [ mutationTypes.ADD_CURRENT_PURCHASE_ORDER_LINE_ITEM ]( state, payload ) {
            // Add a new line item to the start of the currentPurchaseOrder's line item list
            Vue.set(
                state.currentPurchaseOrder,
                'purchaseOrderItemDTOList',
                [
                    {
                        backordered: payload?.backordered ?? false,
                        condition: payload ? payload.condition : '',
                        expectedReceiveDate: payload ? payload.expectedReceiveDate : '',
                        id: null,
                        note: payload ? payload.note : null,
                        productCode: payload ? payload.productCode : '',
                        requestedPrice: payload ? payload.requestedPrice : 0,
                        requestedQuantity: payload ? payload.requestedQuantity : 0,
                        titleDescription: payload ? payload.titleDescription : '',
                        toBeDeleted: false,
                        frontendKey: FormUtil.generateUidForPurchaseOrderLineItem( {
                            id: state.currentPurchaseOrder.purchaseOrderItemDTOList.length,
                        } ),
                        frontendIsNewLine: true,
                    },
                    ...state.currentPurchaseOrder.purchaseOrderItemDTOList,
                ],
            );
        },
        [ mutationTypes.REMOVE_CURRENT_PURCHASE_ORDER_LINE_ITEM ]( state, { lineItemIndex } ) {
            if ( state.currentPurchaseOrder.purchaseOrderItemDTOList[ lineItemIndex ].id === null ) {
                // If this is a line that has been added by the frontend, and the user wants to remove it,
                // remove it by index in the currentPurchaseOrder's line item list
                Vue.delete(
                    state.currentPurchaseOrder.purchaseOrderItemDTOList,
                    lineItemIndex,
                );
            } else {
                // Set the toBeDeleted attribute to true for the given lineItem
                Vue.set(
                    state.currentPurchaseOrder.purchaseOrderItemDTOList[ lineItemIndex ],
                    'toBeDeleted',
                    true,
                );
            }
        },

        [ mutationTypes.PURCHASE_ORDER_MODIFY_POST_HOOK ]( state, { purchaseOrder } ) {
            if ( purchaseOrder.number === state.currentPurchaseOrder.number ) {
                // Update the currentPurchaseOrder with a clone of the purchaseOrder.
                // We're cloning here so that the purchaseOrder object in our purchaseOrderList
                // AND the currentPurchaseOrder don't refer to the SAME object.
                // If we don't clone, later mutations to currentPurchaseOrder will mutate
                // BOTH the purchaseOrder object in the purchaseOrderList AND the currentPurchaseOrder
                state.currentPurchaseOrder = ObjectUtil.deepClone( purchaseOrder );
            }
        },

        [ mutationTypes.REMOVE_MOVED_LINE_ITEMS_FROM_CURRENT_PURCHASE_ORDER ]( state, { itemIDList } ) {
            // Remove the currentPurchaseOrder line items we moved to another purchase order
            itemIDList.forEach( ( itemId ) => {
                Vue.delete(
                    state.currentPurchaseOrder.purchaseOrderItemDTOList,
                    state.currentPurchaseOrder.purchaseOrderItemDTOList.findIndex( ( item ) => item.id === itemId ),
                );
            } );
        },
    },
};
