export default {
    path: '/title-research-queue/:productCode?',
    name: 'TitleResearchQueue',
    props: true,
    meta: {
        requiresAuth: true,
        title: 'My Research Queue',
    },
    component: () => import( /* webpackChunkName: "TitleResearchQueue" */ '@/views/TitleResearch/Queue' ),
};
