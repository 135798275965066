// The order of these attributes matter, it determines the order
// in which they are displayed on the Purchase Order Search Page
// NOTE: This list is actually being returned from the endpoint '/app-rest/cma/v1/titleResearch/filter/options'
// We should consider using the endpoint to get the list of statuses or remove the HTTP request to reduce the number of requests
export default {
    New: 'NEW',
    Problem: 'PROBLEM',
    Override: 'OVERRIDE',
    Cengage_Unltd: 'CENGAGE_UNLTD',
    Pending: 'PENDING',
    Complete: 'COMPLETE',
    Continuation: 'CONTINUATION',
    Approval_Required: 'APPROVAL_REQUIRED',
};

export const STATUS_LIST_COLUMNS = {
    New: 0,
    Problem: 0,
    Override: 0,
    Cengage_Unltd: 0,

    Pending: 1,
    Complete: 1,
    Continuation: 1,
    Approval_Required: 1,
};
