// Use a render function to get around making a lot of wrapper components
// that only contain <router-view />
// https://github.com/vuejs/vue-router/issues/1507#issuecomment-345490711
export default function getRouterView() {
    return {
        render( createElement ) {
            return createElement( 'router-view' );
        },
    };
}
