import Vue from 'vue';
import Vuex from 'vuex';

import ObjectUtil from '@/utils/ObjectUtil';

import modules from './modules';

Vue.use( Vuex );

// TODO: Stop passing objects to actions and mutations if payload is a single value

export default new Vuex.Store( {
    // https://vuex.vuejs.org/en/strict.html
    strict: process.env.NODE_ENV !== 'production',
    modules,
    state: {
        /** @type {Array.<{ message: string, type: 'success' | 'error' }>} */
        notifications: [],
        /** Used for Title Detail & Catalog Listing Detail pages */
        /** @type { title } */
        currentTitle: {},
        /** Used for Catalog Listing Detail */
        /** @type { catalogListing } */
        currentCatalogListing: {},
    },
    getters: {
        /** @returns { Boolean } */
        hasCurrentTitle: ( state ) => ObjectUtil.isNotEmpty( state.currentTitle ),
        /** @returns { Boolean } */
        hasCurrentCatalogListing: ( state ) => ObjectUtil.isNotEmpty( state.currentCatalogListing ),
        /** @returns { Object } */
        currentCatalogListingModifications: ( state ) => ObjectUtil.shallowFindChanges(
            state.CatalogListing.list
                .find( ( title ) => title?.catalogListing?.id === state.currentCatalogListing.id )
                ?.catalogListing,
            state.currentCatalogListing,
        ),
        /** @returns { Boolean } */
        hasCurrentCatalogListingModifications: ( state, getters ) => ObjectUtil.isNotEmpty(
            getters.currentCatalogListingModifications,
        ),
        currentCatalogListingDesignators: ( state ) => state.currentCatalogListing.catalogListingDesignatorDtos,
    },
    actions: {
        reset( { commit } ) {
            commit( 'CLEAR_ALL_NOTIFICATIONS' );
            commit( 'CLEAR_CURRENT_TITLE' );
            commit( 'CLEAR_CURRENT_LISTING' );
        },

        setSuccessNotification( { commit, state }, message, timing = 5000 ) {
            commit( 'SET_SUCCESS_NOTIFICATION', message );
            setTimeout( () => {
                const index = state.notifications.findIndex( ( { message: msg } ) => msg === message );
                commit( 'CLEAR_NOTIFICATION', index );
            }, timing );
        },
        setErrorNotification( { commit, state }, message, timing = 5000 ) {
            commit( 'SET_ERROR_NOTIFICATION', message );
            setTimeout( () => {
                const index = state.notifications.findIndex( ( { message: msg } ) => msg === message );
                commit( 'CLEAR_NOTIFICATION', index );
            }, timing );
        },
        removeNotification( { commit }, index ) {
            commit( 'CLEAR_NOTIFICATION', index );
        },
        clearNotifications( { commit } ) {
            commit( 'CLEAR_ALL_NOTIFICATIONS' );
        },

        setCurrentTitle( context, { title } ) {
            context.commit( 'SET_CURRENT_TITLE', { title } );
        },
        updateCurrentTitle( context, { key, value } ) {
            context.commit( 'UPDATE_CURRENT_TITLE', { key, value } );
        },
        setCurrentCatalogListing( context, { catalogListing } ) {
            context.commit( 'SET_CURRENT_LISTING', { catalogListing } );
        },
        updateCurrentCatalogListing( { commit, state }, { key, value } ) {
            function canNotRemovePrintOnlyDesignator() {
                // First Check: Return false if the key is not catalogListingDesignatorDtos
                if ( key !== 'catalogListingDesignatorDtos' ) return false;

                // Second Check: Return false if the value passed includes the print only designator
                const hasPrintOnlyDesignatorInList = value.some( ( { name } ) => name === 'PRINT_ONLY' );
                if ( hasPrintOnlyDesignatorInList ) return false;

                // Third Check: Return false if the current title does not have print only designator manually applied
                const titles = state.CatalogListing.Detail.additionalCatalogListings.list;
                const currentTitle = titles.find( ( { catalogListing } ) => catalogListing.id === state.currentCatalogListing.id );
                if ( !currentTitle.catalogListing.printOnlyRequired ) return false;

                // Fourth Check: Return false if the current listing is in a ready to buy status otherwise return true
                const readyToBuyList = [ 'COMPLETE', 'OVERRIDE', 'CONTINUATION', 'CENGAGE_UNLTD', 'APPROVAL_REQUIRED' ];
                return !readyToBuyList.includes( state.currentCatalogListing.catalogListingStatus );
            }

            if ( canNotRemovePrintOnlyDesignator() ) {
                const message = `
                    This designator was set by an adoption question and cannot be edited here.
                    To change this designator, move this listing to an editable status, then open
                    Adopt and edit the adoption question.
                `;
                commit( 'SET_ERROR_NOTIFICATION', message );
                return;
            }

            /**
             * NOTE: This may handling way to much.
             * The key determines what is being updated. However, there is no easy way to determine
             * what all the keys are and where this is being used. This is a potential source of bugs.
             * Example: Jira Ticket: AC-376
             */
            commit( 'UPDATE_CURRENT_CATALOG_LISTING', { key, value } );
        },
    },
    mutations: {
        /** Notifications */
        SET_SUCCESS_NOTIFICATION( state, message ) {
            state.notifications.push( { message, type: 'success' } );
        },
        SET_ERROR_NOTIFICATION( state, message ) {
            state.notifications.push( { message, type: 'error' } );
        },
        CLEAR_NOTIFICATION( state, index ) {
            state.notifications.splice( index, 1 );
        },
        CLEAR_ALL_NOTIFICATIONS( state ) {
            state.notifications = [];
        },

        /** currentTitle */
        SET_CURRENT_TITLE( state, { title } ) {
            const transientTitle = ObjectUtil.deepClone( title );
            if ( transientTitle.catalogListing !== undefined ) {
                delete transientTitle.catalogListing;
            }
            state.currentTitle = transientTitle;
        },
        CLEAR_CURRENT_TITLE( state ) {
            state.currentTitle = {};
        },
        UPDATE_CURRENT_TITLE( state, { key, value } ) {
            state.currentTitle[ key ] = value;
        },

        /** currentCatalogListing */
        SET_CURRENT_LISTING( state, { catalogListing } ) {
            state.currentCatalogListing = ObjectUtil.deepClone( catalogListing );
        },
        CLEAR_CURRENT_LISTING( state ) {
            state.currentCatalogListing = {};
        },
        UPDATE_CURRENT_CATALOG_LISTING( state, { key, value } ) {
            state.currentCatalogListing[ key ] = value;
        },
    },
} );
