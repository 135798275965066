import ObjectUtil from './ObjectUtil';

// ERROR UTILS //
/** @returns { String } */
const getMessage = ( error ) => (
    error.response?.data?.message
    ?? error.message
    ?? error
);

const INTERNAL_CODES = {
    ENTITY_NOT_FOUND: 'ENTITY_NOT_FOUND',
};
const getInternalCode = ( error ) => (
    error.response?.data?.tolCode
    ?? error.tolCode
    ?? error.code
    ?? error
);

// STATUS UTILS //
/**
 * @param statusList Object
 * @param statusConst Object
 * @returns { String[] }
 */
function getListFromStatusObject( statusList, statusConst ) {
    const statusArray = [];
    if ( ObjectUtil.isObject( statusList ) ) {
        Object.keys( statusList ).forEach( ( status ) => {
            if ( statusList[ status ] ) {
                if ( !statusConst || !statusConst[ status ] ) {
                    statusArray.push( status.toUpperCase() );
                } else {
                    statusArray.push( statusConst[ status ] );
                }
            }
        } );
    }
    return statusArray;
}

/**
 *
 * @param { Object } statusConst
 * @returns
 *
 * Returns an object with all the status names as properties
 * Values of properties are all initialized to false
 *
 * E.g.
 *  statusConst = {
 *      New: "NEW",
 *      Pending: "PENDING",
 *      Completed: "COMPLETE"
 *  }
 *
 *  returns:
 *  {
 *      New: false,
 *      Pending: false,
 *      Completed: false
 *  }
 */
function initializeList( statusConst ) {
    return Object.fromEntries(
        Object.entries( statusConst )
            .map( ( status ) => [
                status[ 0 ],
                false,
            ] ),
    );
}

/**
 *
 * @param { Object } statusConst
 * @param { String } status
 * @returns
 *
 * Returns the friendly name for a status
 *
 * E.g.
 *  statusConst = {
 *      New: "NEW",
 *      Pending: "PENDING",
 *      Completed: "COMPLETE"
 *  }
 * status = "COMPLETE"
 *
 *  returns: "Completed"
 */
function getFriendlyName( statusConst, status ) {
    const keyValue = Object.entries( statusConst ).find( ( statusData ) => statusData[ 1 ].toUpperCase() === status.toUpperCase() );
    if ( !keyValue ) {
        throw new Error( 'Invalid status: ', status );
    }
    return keyValue[ 0 ];
}

export default {
    error: {
        getMessage,
        entityNotFound: ( error ) => getInternalCode( error ) === INTERNAL_CODES.ENTITY_NOT_FOUND,
    },
    status: {
        getListFromStatusObject,
        initializeList,
        getFriendlyName,
    },
};
