// Transformations
import rra from '@/api/mappers/rra';
import jasperReport from '@/api/mappers/jasperReport';

import axios from './config/axios';
import api from './config/api';

export default {
    /**
     * @POST
     * Get RRAs from Filters
     * Docs: http://apps01.dev.tol.tech/swagger/#!/Walnut_RWS_V1/rraReturnsSearch
     * @returns { Promise<RRA[] | Error> }
     */
    async getListFromFilters( { authToken, ...payload } ) {
        const response = await axios( {
            url: api.walnutUrl( 'rra/search' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: {
                ...rra.getRRASearchDtoFromRRASearch( payload ),
            },
        } );
        response.data.rraList = response.data.rraReturnsSearchResultDtos.map( rra.getRRAFromRRADto );
        return response;
    },

    /**
     * @POST
     * Create an RRA from store values
     * Docs: http://apps01.dev.tol.tech/swagger/#!/Walnut_RWS_V1/createRRA
     */
    async createRRA( { authToken, ...payload } ) {
        const createRRAInboundDto = rra.getRRACreateInboundDtofromRRACreate( payload );

        const response = await axios( {
            url: api.walnutUrl( 'rra/create' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: {
                ...createRRAInboundDto,
            },
        } );
        response.data.rraOutbound = rra.getRRADetailFromRRACreateOutboundDto( response.data );
        return response;
    },

    /**
     * @GET
     * Get an RRA by Id
     * Docs: http://apps01.dev.tol.tech/swagger/#!/Walnut_RWS_V1/getRRAById
     */
    async getRRA( { authToken, id } ) {
        const response = await axios( {
            url: api.walnutUrl( `rra/${ id }` ),
            method: 'get',
            headers: api.authHeaders( authToken ),
        } );
        response.data.rraDetail = rra.getRRADetailFromRRADetailDto( response.data );
        return response;
    },

    /**
     * @PUT
     * Update an RRA
     * @param { String } authToken
     * @param { RRADetail } outboundRRA
     * Docs: https://apps01.dev.tol.tech/swagger/#!/Walnut_RWS_V1/updateRRA
     */
    async updateRRA( { authToken, outboundRRA } ) {
        const response = await axios( {
            url: api.walnutUrl( 'rra/update' ),
            method: 'put',
            headers: api.authHeaders( authToken ),
            data: {
                ...rra.getRRADetailDtoFromRRADetail( outboundRRA ),
            },
        } );
        response.data.rraDetail = rra.getRRADetailFromRRADetailDto( response.data );
        return response;
    },

    /**
     * @POST
     * Get Jasper Report Picklist Links from RRAS
     * Docs: http://apps01.dev.tol.tech/swagger/#!/Walnut_RWS_V1/rraPickListReport
     * @returns { Promise<JasperReport[] | Error> }
     */
    async getPicklistsFromRRAs( { authToken, ...payload } ) {
        // Map API Dto
        const jasperReportDtos = jasperReport.getJasperReportSearchDtosFromRRAs( payload.rras );

        const response = await axios( {
            url: api.walnutUrl( 'rra/pickListReport' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: jasperReportDtos,
        } );
        response.data.rraReportResultList = jasperReport.getJasperReportsFromJasperReportDtos( response.data.rraReportResultsDtos );
        return response;
    },

    /**
     * @POST
     * Get Jasper Report Print Links from RRAS
     * Docs: http://apps01.dev.tol.tech/swagger/#!/Walnut_RWS_V1/requestForReturnAuthReport
     * @returns { Promise<JasperReport[] | Error> }
     */
    async getPrintReportsFromRRAs( { authToken, ...payload } ) {
        // Map API Dto
        const jasperReportDtos = jasperReport.getJasperReportSearchDtosFromRRAs( payload.rras );

        const response = await axios( {
            url: api.walnutUrl( 'rra/requestForReturnAuthReport' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: jasperReportDtos,
        } );
        response.data.rraReportResultList = jasperReport.getJasperReportsFromJasperReportDtos( response.data.rraReportResultsDtos );
        return response;
    },

    /**
     * @POST
     * Kick off the RRA AutoGen process with a given set of parameters
     */
    async autogen( { authToken, payload } ) {
        return axios( {
            url: api.walnutUrl( 'rra/autogen' ),
            method: 'post',
            headers: api.authHeaders( authToken ),
            data: {
                ...payload,
            },
        } );
    },

    /**
     * @GET
     * Get RRA Line Items to export to a CSV by RRA Number
     * @param { String } authToken
     * @param { Number } rraId
     */
    async getExportableLines( { authToken, rraId } ) {
        const response = await axios( {
            url: api.walnutUrl( `rra/itemExport/${ rraId }` ),
            method: 'get',
            headers: api.authHeaders( authToken ),
        } );
        return {
            ...response,
            data: {
                ...response.data,
                rraItemExportItemDTOList: response.data.rraItemExportItemDTOList.map( rra.getExportableLineItemsFromDto ),
            },
        };
    },

    /**
     * @GET
     * Get RRA EAN Details
     * @param { String } authToken
     * @param { String } isbn
     * @param { String } condition
     * @param { Number } rraId
     */
    async getEanDetails( {
        authToken,
        isbn,
        condition,
        rraId,
    } ) {
        const response = await axios( {
            url: api.walnutUrl( `rra/eanDetails/${ rraId }/${ isbn }/${ condition }` ),
            method: 'get',
            headers: api.authHeaders( authToken ),
        } );
        return {
            ...response,
            data: rra.getRRAEanDetailFromRRAEanDetailInboundDto( response.data ),
        };
    },

    /**
     * @POST
     * Update RRA EAN Details
     * @param { String } authToken
     * @param { Object } eanDetails
     */
    async updateEanDetails( { authToken, eanDetails } ) {
        const response = await axios( {
            url: api.walnutUrl( 'rra/eanDetails/update' ),
            method: 'put',
            headers: api.authHeaders( authToken ),
            data: {
                ...rra.getRRAEanDetailDtoFromOutboundEanDetails( eanDetails ),
            },
        } );
        return {
            ...response,
            data: rra.getRRAEanDetailFromRRAEanDetailInboundDto( response.data ),
        };
    },
};
