// Api
import RRAApi from '@/api/RRAApi';
import TitleApi from '@/api/TitleApi';

// Utils
import StoreUtil from '@/utils/StoreUtil';

const actionTypes = {
    init: 'init',
    reset: 'reset',

    preview: 'preview',
    generate: 'generate',

    getSupplierList: 'getSupplierList',
};
const mutationTypes = {
    SET_IN_FLIGHT: 'SET_IN_FLIGHT',

    SET_SUPPLIERS_LIST: 'SET_SUPPLIERS_LIST',
    SET_SUPPLIERS_IN_FLIGHT: 'SET_SUPPLIERS_IN_FLIGHT',
};

export default {
    namespaced: true,
    state: {
        /** @type { Boolean } */
        inFlight: false,

        suppliers: {
            list: [],
            inFlight: false,
        },
    },
    getters: {
        /** @returns { Boolean } */
        hasSupplierList: ( state ) => state.suppliers.list.length > 0,
    },
    actions: {
        async [ actionTypes.getSupplierList ]( context ) {
            if ( context.getters.hasSupplierList ) return;
            context.commit( mutationTypes.SET_SUPPLIERS_IN_FLIGHT, { boolean: true } );
            try {
                const { data } = await TitleApi.getMasterSupplierList( {
                    authToken: context.rootGetters[ 'User/authString' ],
                } );
                context.commit( mutationTypes.SET_SUPPLIERS_LIST, { list: data.suppliers } );
            } catch ( error ) {
                throw new Error( error.message );
            } finally {
                context.commit( mutationTypes.SET_SUPPLIERS_IN_FLIGHT, { boolean: false } );
            }
        },
        async [ actionTypes.preview ]( context, payload ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                await RRAApi.autogen( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    payload: {
                        ...payload,
                        previewOnly: true,
                    },
                } );
                context.dispatch( 'setSuccessNotification', 'Generating RRA AutoGen preview. Check your email!', { root: true } );
            } catch ( error ) {
                const message = `Error occurred when attempting to preview AutoGen RRAs: ${ StoreUtil.error.getMessage( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },
        async [ actionTypes.generate ]( context, payload ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                await RRAApi.autogen( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    payload: {
                        ...payload,
                        previewOnly: false,
                    },
                } );
                context.dispatch( 'setSuccessNotification', 'Began the RRA AutoGen process.', { root: true } );
            } catch ( error ) {
                const message = `Error occurred when attempting to AutoGen RRAs: ${ StoreUtil.error.getMessage( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },
        [ actionTypes.reset ]( context ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            context.commit( mutationTypes.SET_SUPPLIERS_LIST, { list: [] } );
            context.commit( mutationTypes.SET_SUPPLIERS_IN_FLIGHT, { boolean: false } );
        },
        async [ actionTypes.init ]( context ) {
            await context.dispatch( actionTypes.getSupplierList );
        },
    },
    mutations: {
        [ mutationTypes.SET_IN_FLIGHT ]( state, { boolean } ) {
            state.inFlight = boolean;
        },
        [ mutationTypes.SET_SUPPLIERS_LIST ]( state, { list } ) {
            state.suppliers.list = list;
        },
        [ mutationTypes.SET_SUPPLIERS_IN_FLIGHT ]( state, { boolean } ) {
            state.suppliers.inFlight = boolean;
        },
    },
};
