export default {
    path: '/catalog-listings/:catalogListingId?',
    name: 'TitleResearchCatalogListings',
    meta: {
        title: 'Search',
        requiresAuth: true,
    },
    props: ( route ) => ( {
        adoptionMaterialId: route.query.adoptionMaterialId,
        productCode: route.query.productCode,
        catalogListingId: parseInt( route.params.catalogListingId, 10 ),
    } ),
    component: () => import( /* webpackChunkName: "TitleResearchCatalogListings" */ '@/views/TitleResearch/CatalogListings' ),
};
